import { Injectable } from '@angular/core';
import { Preferences } from '../interface/settings.interfaces';
import { PreferencesModel } from '../../../models/preferences.model';
import {ApiService} from '../../../services/api.service';
import {Preference} from '../../../interfaces/general/preference.interface';
import { Response } from 'src/app/interfaces/responses-general.interface';
import {PreferencesSessionStorage} from '../../../utils/local-storage-manager/session-storage-preferences.utils';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private api: ApiService
  ) { }

  //TODO: REVIEW THIS FUNCTIONS
  public updatePreferences(values: {string:any}, isUpdateCurrency : boolean) {
    let params :  {string:any} = values;
    return new Promise((resolve, reject) => {
      this.preference(params).then(_ => {
        if (isUpdateCurrency) {
          this.updateCurrency(params).then(_ => {
            resolve(true);
          }, rejectCurrency => {
            reject(rejectCurrency);
          });
        } else {
          resolve(true);
        }
      }, rejectPreferences => {
        reject(rejectPreferences);
      });
    });
  }

  public async preference(params : any) {
    return new Promise((resolve, reject) => {
      this.api.update(params).subscribe((response: Response<Preference[]>) => {
        if (response.status == 1) {
          const data = response.data[0];
          PreferencesModel.shared.dateFormat = data.format_date;
          PreferencesModel.shared.decimalFormat = data.decimal_format;
          PreferencesModel.shared.decimalNumber = data.decimal_number;
          PreferencesModel.shared.firstWeekDay = data.first_weekday;
          PreferencesModel.shared.fkCurrency = data.fk_currency;
          PreferencesModel.shared.fkUser = data.fk_user;
          PreferencesModel.shared.floatingButton = data.floating_button;
          PreferencesModel.shared.pkPreference = data.pk_preference;
          PreferencesModel.shared.showIsoCode = data.show_iso_code;
          PreferencesModel.shared.showSymbol = data.show_symbol;
          PreferencesModel.shared.symbolSide = data.symbol_side;
          PreferencesModel.shared.theme = data.theme;
          PreferencesModel.shared.timeFormat = data.format_time;
          PreferencesModel.shared.transfers = data.transfers == 1;
          this.updateLocalStorage(data);
          resolve(true);
        } else {
          reject();
        }
      });
    })
  }

  public async updateCurrency(values : any) {
    let params = {
      get : "update_currency",
      pk_preference : values['pk_preference'],
      fk_currency : values['fk_currency'],
      fk_subscription: this.api.userData?.subscription_id
    }
    return new Promise((resolve, reject) => {
      this.api.update(params).subscribe((response) => {
        if (response.status === "1") {
          const data : any = response.data;
          const preference : Preferences = data['user_preferences'];
          PreferencesModel.shared.fkCurrency = +preference.fk_currency;
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  private updateLocalStorage(preferences: Preference) {
    const storage = new PreferencesSessionStorage();
    storage.savePreferences(preferences);
  }
}
