import {UserCard} from '../../interfaces/general/user.card.interface';
import {CardControl} from '../../components/cards/interfaces/CardsControl.interface';

export class UserCardModel {
  pkUserCard: number;
  shown: number;
  orderCard: number;
  fkCard: number;
  fkPreference: number;
  fkUser: number;
  serverDate: string;

  card: CardControl;

  constructor(json: UserCard, cardControl: CardControl) {
    this.pkUserCard = json.pk_user_card;
    this.shown = json.shown;
    this.orderCard = json.order_card;
    this.fkCard = json.fk_card;
    this.fkPreference = json.fk_preference;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
    this.card = cardControl;
  }
}
