import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {FilterService} from '../../../../services/filter.service';
import {MovementModel} from '../../../../models/general/movement.model';
import {hexToCSSFilter} from 'hex-to-css-filter';
import {AccountModel} from '../../../../models/general/account.model';

@Component({
  selector: 'app-last-ten-movements',
  templateUrl: './last-ten-movements.component.html',
  styleUrls: ['./last-ten-movements.component.css'],
  providers: [ DatePipe ]

})
export class LastTenMovementsComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;
  noData = false;
  loading: boolean = true;
  preferences = PreferencesModel.shared;
  movements: MovementModel[] = [];

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.getMovements(this.filters.accounts);
    this.setFilterMovements();
    this.loading = false;
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  changePage() {
    this.route.navigate(['Movements']);
  }

  public getFilter(colorHex: string) {
    const filter = hexToCSSFilter(colorHex);
    return filter.filter;
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getMovements(accounts: AccountModel[]) {
    const movements = this.filters.movementsAll.sort((a, b) => {
      if(moment(a.date).isAfter(b.date)) return -1;
      if(moment(b.date).isAfter(a.date)) return 1;
      return 0;
    });
    this.movements = movements.filter(row => accounts.includes(row.account!)).slice(0, 10);
    this.noData = (this.movements.length == 0);
  }

  private setFilterMovements() {
    this.movements.forEach(row => {
      if(row.colorHex == '#000000') {
        row.colorHex = '#1396C9';
      }
      row.filter = this.getFilter(row.colorHex);
    })
  }
}
