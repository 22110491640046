<div *ngIf="loading">
    <app-loading-view></app-loading-view>
</div>

<div *ngIf="!loading">
    <div class="container mt-5">
        <div class="row">
            <div
                *ngIf="showSaveButton"
                class="col-2 offset-10 mb-2 animate__animated animate__fadeIn"
                >
                <button
                    class="btn btn-block col-10 clear-outline bg-main-purple white-text"
                    (click)="savePreferences()"
                    >{{ "buttons.button_save" | translate }}
                </button>
            </div>
            <div class="row">
                <div class="col">
                    <app-card-currency-format
                    (emitter)="setCurrencySettings($event)"
                    (currencySelected)="setCurrency($event)"
                    >
                    </app-card-currency-format>
                </div>
                <div class="col">
                    <div class="pb-3">
                        <!-- DATE TIME -->
                        <app-card-date-format
                        (emitter)="setDateTimeSettings($event)"
                        >
                        </app-card-date-format>
                    </div>
                    <div class="pb-3">
                        <!-- LANGUAGE -->
                        <app-card-language
                        (emitter)="setLanguague($event)"
                        >
                        </app-card-language>
                    </div>
                    <div class="pb-3">
                        <!-- TRANSFERS SETTINGS -->
                        <app-card-transfers
                        (emitter)="setShowTransfers($event)"
                        >
                        </app-card-transfers>
                    </div>
                </div>
                <div class="col">
                    <div class="pb-3">
                        <!-- APLICATION THEME -->
                        <app-card-theme
                        (emitter)="setTheme($event)"
                        >
                        </app-card-theme>
                    </div>
                    <div class="pb-3">
                        <!-- CONTACT -->
                        <app-card-contact></app-card-contact>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>