import {Component, OnInit} from '@angular/core';
import {PreferencesModel} from 'src/app/models/preferences.model';
import {PeriodType} from '../../../common-components/selector-period/interfaces/period.interface';
import {LocalStorageManager} from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {PreferencesSessionStorage} from 'src/app/utils/local-storage-manager/session-storage-preferences.utils';
import {FilterService} from '../../../../services/filter.service';
import {ChartPie} from '../../../../interfaces/general/reports.interfaces';
import {MovementModel} from '../../../../models/general/movement.model';
import moment from 'moment';
import {AccountModel} from '../../../../models/general/account.model';
import {CurrencyModel} from '../../../../models/general/currency.model';

@Component({
  selector: 'app-monthly-balance-previous-balance',
  templateUrl: './monthly-balance-previous-balance.component.html',
  styleUrls: ['./monthly-balance-previous-balance.component.css']
})
export class MonthlyBalancePreviousBalanceComponent implements OnInit {
  showTransfers: boolean = new PreferencesSessionStorage().getHome();
  isDarkmode = LocalStorageManager.isDarkmode;

  preferences = PreferencesModel.shared;
  totalIncomes: number = 0;
  totalExpenses: number = 0;
  totalBalance: number = 0;
  previousBalance: number = 0;
  titleReport: string = '';
  chartData: ChartPie[] = [];
  currency?: CurrencyModel;
  customColors = [
    { name: 'Incomes', value: '#0EB59499' },
    { name: 'Expenses', value: '#D1359499' },
    { name: 'Total', value: '#42424299' }
  ];

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.currency = this.filters.currency;
    this.getBalances();
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public changePage() {
    this.route.navigate(['Reports-by-date/reports']).then();
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getBalances() {
    this.previousBalance = this.filters.balancePrevious;
    this.totalIncomes = this.filters.balanceIncomes;
    this.totalExpenses = this.filters.balanceExpenses;
    this.totalBalance = (this.previousBalance + this.totalIncomes) - this.totalExpenses;
    this.titleReport = this.getTitle();
    this.generateChartData();
  }

  private getTitle(): string {
    switch(this.filters.period) {
      case PeriodType.daily: return "text_cardviews.card_balance_previous_by_day";
      case PeriodType.weekly: return "text_cardviews.card_balance_previous_weekly";
      case PeriodType.fortnightly: return "text_cardviews.card_balance_previous_biweekly";
      case PeriodType.monthly: return "text_cardviews.card_balance_previous_monthly";
      case PeriodType.annually: return "text_cardviews.card_balance_previous_yearly";
      case PeriodType.byDates: return "text_cardviews.card_balance_previous_date_range";
    }
  }

  private generateChartData() {
    this.chartData = [
      {name: 'Incomes', value: this.totalIncomes},
      {name: 'Expenses', value: this.totalExpenses},
      {name: 'Total', value: this.totalBalance},
    ]
  }
}
