import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesModel } from '../../../models/preferences.model';

@Component({
  selector: 'app-bottom-bar-add-button',
  templateUrl: './bottom-bar-add-button.component.html',
  styleUrls: ['./bottom-bar-add-button.component.css']
})
export class BottomBarAddButtonComponent implements OnInit {

  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() color: string = '#A75DE8';
  @Input() textColor: string | null = null;
  @Input() modalId: string = '';
  @Input() showIsoCode: boolean = false;

  @Output() onClick = new EventEmitter();

  isDarkmode = LocalStorageManager.isDarkmode;

  preference = PreferencesModel.shared;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
  }

  buttonPressed() {
    this.onClick.emit();
  }
}
