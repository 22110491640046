import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MovementModel} from '../models/general/movement.model';
import {PeriodType} from '../components/common-components/selector-period/interfaces/period.interface';
import {AccountModel} from '../models/general/account.model';
import {DebtModel} from '../models/general/debt.model';
import {BudgetModel} from '../models/general/budget.model';
import {PreferencesModel} from '../models/preferences.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private debtsSubject = new BehaviorSubject<DebtModel[]>([]);
  private accountBalanceSubject = new BehaviorSubject<number>(0);
  private currentPeriod!: PeriodType;
  private selectedAccounts: AccountModel[] = [];
  private allMovements: MovementModel[] = [];
  private currentBudgets: BudgetModel[] = [];
  private date: moment.Moment = moment();
  public movementsPeriod: MovementModel[] = [];

  //MARK: GETTERS ------------------------------------------------------------------------------------
  public get period(): PeriodType {
    return this.currentPeriod;
  }

  public get accounts(): AccountModel[] {
    return [...this.selectedAccounts];
  }

  public get movementsAll(): MovementModel[] {
    return [...this.allMovements];
  }

  public get budgets(): BudgetModel[] {
    return [...this.currentBudgets];
  }

  public get currentDate(): moment.Moment {
    return this.date;
  }

  //MARK: SETTERS ------------------------------------------------------------------------------------
  public set period(period: PeriodType) {
    this.currentPeriod = period;
  }

  public set accounts(account: AccountModel[]) {
    this.selectedAccounts = [...account];
  }

  public set movementsAll(allMovements: MovementModel[]) {
    this.allMovements = [...allMovements];
  }

  public set budgets(budget: BudgetModel[]) {
    this.currentBudgets = [...budget];
  }

  public set currentDate(date: moment.Moment) {
    this.date = date;
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public setBalanceAccount(value: number) {
    this.accountBalanceSubject.next(value);
  }

  public getBalanceAccount() {
    return this.accountBalanceSubject.asObservable();
  }

  public setDebts(debts: DebtModel[]) {
    this.debtsSubject.next([...debts]);
  }

  public getDebts() {
    return this.debtsSubject.asObservable();
  }

}
