<div class="col-12 ms-1">
    <div class="row">
            <div
                class="form-control text-size-date-selector transparent-selector no-padding"
                >
                <div
                    ngClass="{{isDarkmode ? 'bg-dark-input' : 'bg-white'}}"
                    style="border-radius: 10px;"
                    class="row no-margin no-padding pb-1"
                    >
                    <button
                        [ngClass]="{'white-text': isDarkmode}"
                        (click)="decrease(false)"
                        class="col-2 transparent-date text-size-date-selector"
                        ><
                    </button>

                    <mat-form-field
                        class="col-8 remove-background-form"
                        (click)="picker.open()"
                        >
                        <div class="col-12">
                            <input
                                [ngClass]="{'white-text' : isDarkmode}"
                                class="text-capitalize col-12 pointer transparent-date text-size-date-selector"
                                type="button"
                                [value]="getDate()"
                                />

                            <input
                                style="display: none;"
                                hidden
                                matInput
                                height="0px"
                                width="0px"
                                [matDatepicker]="picker"
                                (dateChange)="changeDate($event.value)"
                                />
                            <mat-datepicker 
                                #picker
                                color="primary"
                                startView="multi-year"
                                (monthSelected)="changeDatePickerType($event, picker, pickerType.MONTH)"
                                (yearSelected)="changeDatePickerType($event, picker, pickerType.YEAR)"
                                >
                            </mat-datepicker>
                        </div>
                    </mat-form-field>    
                    <button
                        [ngClass]="{'white-text': isDarkmode}"
                        (click)="increment(false)"
                        class="col-2 transparent-selector text-size-date-selector"
                        >>
                    </button>
                </div>
            </div>

        <div
            *ngIf="selectedPeriod == period.byDates"
            class="text-center col-12 mt-1"
            >
            <div
                class="form-control text-size-date-selector transparent-selector no-padding ps-2"
                >
                <div
                    ngClass="{{isDarkmode ? 'bg-dark-input' : 'bg-white'}}"
                    style="border-radius: 10px;"
                    class="row no-padding pb-1"
                    >
                    <button
                        [ngClass]="{'white-text': isDarkmode}"
                        (click)="decrease(true)"
                        class="col-2 transparent-date text-size-date-selector"
                        ><
                    </button>
                    <mat-form-field
                        class="col-8 remove-background-form"
                        (click)="picker2.open()"
                        >
                        <div class="col-12">
                            <input
                                [ngClass]="{'white-text' : isDarkmode}"
                                class="text-capitalize col-12 pointer transparent-date text-size-date-selector"
                                type="button"
                                [value]="getDate2()"
                                />

                            <input
                                style="display: none;"
                                hidden
                                matInput
                                height="0px"
                                width="0px"
                                [matDatepicker]="picker2"
                                (dateChange)="changeDate2($event.value)"
                                />
                                <mat-datepicker
                                    startView="multi-year"
                                    #picker2
                                    color="primary"
                                    >
                                </mat-datepicker>
                        </div>
                    </mat-form-field>    

                    <button  
                        [ngClass]="{'white-text': isDarkmode}"
                        (click)="increment(true)"
                        class="col-2 transparent-selector text-size-date-selector"
                        >>
                    </button>
                </div>
            </div>
        </div>

        <div
            *ngIf="selectedPeriod == period.weekly && 
            (selectorComponet == selectorDateType.budgets ||
            selectorComponet == selectorDateType.reportsByCategory || 
            selectorComponet == selectorDateType.home)"
            class="text-center col-12 mt-1"
            >
            <div
                class="form-control text-size-date-selector transparent-selector no-padding"
                >
                <div
                    style="border-radius: 10px;"
                    ngClass="{{isDarkmode ? 'bg-dark-input' : 'bg-white'}}"
                    class="row pb-1">
                    <button
                        [ngClass]="{'white-text': isDarkmode}"
                        (click)="decreaseSecondComponent(true)"
                        class="col-2 transparent-selector"
                        ><
                    </button>
                    <div class="col-8">
                        <mat-select
                            panelClass="panelPeriodDate"
                            (valueChange)="setWeek($event, true)"
                            [(ngModel)]='selectedWeek'
                            disableOptionCentering
                            class="selector transparent-selector no-arrow pointer" 
                            >
                        <mat-option
                            *ngFor="let week of calendar.weeksOfYear"
                            [value]="week.weekNumber"
                            class="no-margin no-padding mat-option"
                            ngClass="{{isDarkmode ? 'white-text' : 'font-secondary'}}"
                            >{{week.weekRange}}
                        </mat-option>
                    </mat-select>
                    </div>
                    
                    <button  
                        [ngClass]="{'white-text': isDarkmode}"
                        (click)="incrementSecondComponent(true)"
                        class="col-2 transparent-selector"
                        >>
                    </button>
                </div>
            </div>
        </div>

        <div
            *ngIf="selectedPeriod == period.fortnightly &&
            (selectorComponet == selectorDateType.budgets ||
            selectorComponet == selectorDateType.reportsByCategory ||
            selectorComponet == selectorDateType.home)"
            class="text-center col-12 mt-1"
            >
            <div
                class="form-control text-size-date-selector transparent-selector no-padding ps-2"        
                >
                <div
                    ngClass="{{isDarkmode ? 'bg-dark' : 'bg-white'}}"
                    style="border-radius: 10px;"
                    class="row pb-1">
                    <button
                        [ngClass]="{'white-text': isDarkmode}"
                        (click)="decreaseSecondComponent(false)"
                        class="col-2 transparent-selector"
                        ><
                    </button>
                    <div class="col-8">
                        <mat-select
                            panelClass="panelPeriodDate"
                            ngClass="{{isDarkmode ? 'dark-mode bg-dark' : 'light-mode bg-white'}}"
                            (valueChange)="setFortnight($event, true)"
                            [(ngModel)]='selectedFortnight'
                            disableOptionCentering
                            class="selector col-12 transparent-selector no-arrow pointer" 
                            >
                            <mat-option
                                *ngFor="let fortnight of calendar.fortnightsOfYear"
                                [value]="fortnight"
                                class="no-margin no-padding mat-option"
                                ngClass="{{isDarkmode ? 'white-text' : 'font-secondary'}}"
                                >{{fortnight.title}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <button  
                        [ngClass]="{'white-text': isDarkmode}"
                        (click)="incrementSecondComponent(false)"
                        class="col-2 transparent-selector"
                        >>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>