import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { SelectorAccountsService } from './services/selector-accounts.service';
import { AccountModel } from 'src/app/models/general/account.model';

@Component({
  selector: 'app-selector-multi-account',
  templateUrl: './selector-multi-account.component.html',
  styleUrls: ['./selector-multi-account.component.css']
})
export class SelectorMultiAccountComponent implements OnInit {

  @Input() accounts : AccountModel[] = [];
  @Input() showSelectAll : boolean = false;
  ls = LocalStorageManager;
  selectedAccountsPks : number[] = [];
  selectAll : boolean = false;
  @Output() emmitAccountsSelected = new EventEmitter<AccountModel[]>();

  constructor(
    private services: SelectorAccountsService,
    private translate: TranslateService
    ) {
    this.translate.use(this.ls.lang!);
  }

  ngOnChanges(): void {
    this.accounts.sort((a, b) => {
      return a.accountName.localeCompare(b.accountName);
    });
    this.getListSelectedPks();
    let accountSelecteds = this.accounts.filter(account => account.selected == 1);
    this.selectAll = this.accounts.length == accountSelecteds.length;
  }

  ngOnInit(): void {
    this.accounts.sort((a, b) => {
      return a.accountName.localeCompare(b.accountName);
    });
    this.getListSelectedPks();
    let accountSelecteds = this.accounts.filter(account => account.selected == 1);
    this.selectAll = this.accounts.length == accountSelecteds.length;
  }

  changeSelection(accountsSelections: number[]) {
    this.accounts.forEach(account => {
      account.selected = accountsSelections.includes(account.pkAccount) ? 1 : 0;
    });
    let accountSelecteds = this.accounts.filter(account => account.selected == 1);
    this.selectAll = this.accounts.length == accountSelecteds.length;
    if (accountSelecteds.length == 0) {
      this.setSelectAll();
    }
    this.setServerUpdateSelectionAccounts(accountSelecteds);
  }

  emitSelectAll(selectAll: boolean) {
    this.selectAll = selectAll;
    this.setSelectAll();
  }

  setSelectAll() {
    this.accounts.forEach(account => {
      account.selected = 1;
    });
    this.getListSelectedPks();
    const accountsIds = this.getAccountsId();
    this.updateServerAccounts(accountsIds, this.accounts);
  }

  getAccountsId() {
    let id_accounts : number [] = [];
    this.accounts.forEach(account =>{
      if (this.selectedAccountsPks.includes(account.pkAccount)) {
        id_accounts.push(account.pkAccount);
      }
    })
    return id_accounts;
  }

  setServerUpdateSelectionAccounts(accountSelecteds: AccountModel[]) {
    this.getListSelectedPks();
    const accountsIds = this.getAccountsId();
    this.updateServerAccounts(accountsIds, accountSelecteds);
  }

  updateServerAccounts(accountsIds : number[], accountsSelecteds: AccountModel[]) {
    this.services.updateAccountsSelecteds(accountsIds).then( _ => {
      this.accounts.filter(account => this.selectedAccountsPks.includes(account.pkAccount)).forEach(account => {
        account.selected = 1
      });
      LocalStorageManager.movements.setAccountsIds(accountsSelecteds.map(row => row.pkAccount));
      this.emmitAccountsSelected.emit(accountsSelecteds);
    }, _ => {
    });
  }

  getListSelectedPks() {
    this.selectedAccountsPks = this.accounts.filter(account => account.selected == 1).map(account => account.pkAccount);
  }

  isSelected(account : AccountModel) {
    return account.selected == 1;
  }

}
