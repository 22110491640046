import { TransfersOptions } from "src/app/pages/options/interface/settings.interfaces";
import {Preference} from '../../interfaces/general/preference.interface';

export class PreferencesSessionStorage {
    public KEYS = {
        TRANSFERS: 'transfers',
        TRANSFERS_HOME: 'transfers_home',
        TRANSFERS_AGENDA: 'transfers_agenda',
        TRANSFERS_REPORTS_DATE: 'transfers_reports_date',
        TRANSFERS_REPORTS_CATEGORY: 'transfers_reports_category',
    }

    public getTransfers(): TransfersOptions {
      return {
        TRANSFERS: this.get(this.KEYS.TRANSFERS),
        TRANSFERS_HOME: this.get(this.KEYS.TRANSFERS_HOME),
        TRANSFERS_AGENDA: this.get(this.KEYS.TRANSFERS_AGENDA),
        TRANSFERS_REPORTS_DATE: this.get(this.KEYS.TRANSFERS_REPORTS_DATE),
        TRANSFERS_REPORTS_CATEGORY: this.get(this.KEYS.TRANSFERS_REPORTS_CATEGORY),
      }
    }

    public saveTransfers(transfers: TransfersOptions) {
      this.set(this.KEYS.TRANSFERS_HOME, transfers.TRANSFERS_HOME);
      this.set(this.KEYS.TRANSFERS_AGENDA, transfers.TRANSFERS_AGENDA);
      this.set(this.KEYS.TRANSFERS_REPORTS_DATE, transfers.TRANSFERS_REPORTS_DATE);
      this.set(this.KEYS.TRANSFERS_REPORTS_CATEGORY, transfers.TRANSFERS_REPORTS_CATEGORY);
    }

    public savePreferences(preference: Preference): void {
      console.log('information', preference);
        this.set(this.KEYS.TRANSFERS, preference.transfers);
        this.set(this.KEYS.TRANSFERS_HOME, preference.transfers_home);
        this.set(this.KEYS.TRANSFERS_AGENDA, preference.transfers_agenda);
        this.set(this.KEYS.TRANSFERS_REPORTS_DATE, preference.transfers_reports_date);
        this.set(this.KEYS.TRANSFERS_REPORTS_CATEGORY, preference.transfers_reports_category);
    }

    public getReportCategory(): boolean {
        return this.getValue(this.KEYS.TRANSFERS_REPORTS_CATEGORY);
    }

    public getReportDate(): boolean {
        return this.getValue(this.KEYS.TRANSFERS_REPORTS_DATE);
    }

    public getHome(): boolean {
        return this.getValue(this.KEYS.TRANSFERS_HOME);
    }

    public set(key: string, value: number) {
        sessionStorage.setItem(key, value.toString());
    }

    public get(key: string): number {
        return parseInt(sessionStorage.getItem(key) || '0');
    }

    public getValue(key: string): boolean {
        const value = this.get(key);
        return value == 1;
    }
}
