import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';
import { CardTransfersModel } from './model/card-transfer.model';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesSessionStorage } from '../../../../../utils/local-storage-manager/session-storage-preferences.utils';
import { TransfersOptions } from '../../../interface/settings.interfaces';

@Component({
  selector: 'app-card-transfers',
  templateUrl: './card-transfers.component.html',
  styleUrls: ['./card-transfers.component.css']
})
export class CardTransfersComponent implements OnInit {
  @Output() emitter = new EventEmitter<TransfersOptions>();
  preferencesSessionStorage = new PreferencesSessionStorage();
  transfers = this.preferencesSessionStorage.getTransfers();
  localStorage = LocalStorageManager;
  preferences = PreferencesModel.shared;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
  }

  setValue(key: keyof TransfersOptions, value: number): void {
    this.transfers[key] = value;
    this.emitter.emit(this.transfers);
  }
}
