<mat-select
    disableOptionCentering
    panelClass="panelAccounts"
    [(value)]="selectedPkAccount"
    class="select-dialog p-1"
    style="border-radius:10px; border : 1px solid #9E9E9EB3 "
    (valueChange)="selectAccount($event)"
    ngClass="{{ls.isDarkmode ? 'dark-mode' : 'light-mode borderThemeWhite'}}"
    [ngStyle]="{'background-color' : accountSelected ? getColor() : ls.isDarkmode || (showAllAccounts && selectedPkAccount == 0) ? '#575f68' : 'white' , 'color' : accountSelected || ls.isDarkmode ? 'white' : 'black'}"
    >
    <mat-select-trigger
        *ngIf="accountSelected"
        class="no-margin no-padding text-start"
        [ngClass]="{accountSelected : 'white-text' }"
        >
        <p class="no-margin no-padding">
        <img
            class="{{accountSelected ?  'filter-white' :  ls.isDarkmode ? 'filter-white' : 'filter-black1'}}"
            class="no-margin no-padding pe-3"
            width="55"
            height="55"
            src="assets/images/icon-cat/{{accountSelected.iconName}}.svg"
            >
          <i
            *ngIf="accountSelected.isShared"
            class="fa fa-user-friends align-self-center col-1 pe-0 gray"
            aria-hidden="true"
          ></i>
          {{accountSelected.accountName}}
        </p>
    </mat-select-trigger>
    <mat-select-trigger
        *ngIf="!accountSelected"
        class="no-margin no-padding text-start"
        ngClass="{{ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        ><p class="no-margin no-padding">
            <img
                ngClass="{{!accountSelected || ls.isDarkmode ?  'filter-white' : 'filter-black1'}}"
                class="no-margin no-padding pe-3"
                width="55"
                height="55"
                src="assets/images/icon-cat/{{showAllAccounts ? 'icon_all_accounts' : 'icon_empty'}}.svg"
                > {{(showAllAccounts ? "selecteds.all_accounts" : "web_messages.select_account") | translate}}

        </p>
    </mat-select-trigger>
    <mat-option
        *ngIf="showAllAccounts"
        ngClass="{{ls.isDarkmode || !accountSelected ? 'white-text' : 'font-secondary'}}"
        [value]="0"
        ><p
            class="col-12 no-margin no-padding"
            [ngClass]="{'border-cell' : !accountSelected}"
            [ngStyle]="{'background-color' : ls.isDarkmode ? !accountSelected ? 'black' : '#343a40' : accountSelected ? '#ffffff' : '#343a40',  'color' : !accountSelected || ls.isDarkmode ? 'white' : '#9E9E9E'}"
            >
            <img
                class="{{ls.isDarkmode || !accountSelected ? 'filter-white' : 'filter-black1'}}"
                class="no-margin p-2"
                width="55"
                height="55"
                src="assets/images/icon-cat/{{showAllAccounts ? 'icon_all_accounts' : 'icon_empty'}}.svg"
                >{{(showAllAccounts ? "selecteds.all_accounts" : "web_messages.select_account") | translate}}
        </p>
    </mat-option>
    <mat-option
        *ngFor="let account of accounts"
        [value]="account.pkAccount"
        class="no-margin no-padding mat-option"
        ngClass="{{account.pkAccount == accountSelected?.pkAccount || ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        ><p
            class="col-12 no-margin"
            [ngClass]="{'border-cell' : account.pkAccount == accountSelected?.pkAccount}"
            [ngStyle]="{'background-color' : account.pkAccount == accountSelected?.pkAccount ? '#'+account.colorHex :  ls.isDarkmode ?  '#343a40' : '#ffffff', 'color' : account.pkAccount == accountSelected?.pkAccount || ls.isDarkmode ? 'white' : '#9E9E9E'}"
            >
            <img
                ngClass="{{account.pkAccount == accountSelected?.pkAccount || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
                class="me-2 ms-1"
                width="36"
                height="36"
                src="assets/images/icon-cat/{{account.iconName}}.svg"
                >
            <i
              *ngIf="account.isShared"
              class="fa fa-user-friends align-self-center col-1 pe-0 gray"
              aria-hidden="true"
            ></i>
            {{account.accountName}}
        </p>
    </mat-option>
</mat-select>
