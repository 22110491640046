import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.css']
})
export class HeaderTitleComponent implements OnInit {
  @Input() title : string = "settions_section.settings_about";
  @Input() translateBoolean : boolean = true;
  @Input() closeButton : string = "buttonCloseModal";
  @Input() modalId : string = "modal"

  @Output() emmitClickCloseButton = new EventEmitter<boolean>();
  ls = LocalStorageManager;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }
  ngOnInit(): void {
  }

  clickToClose() {
    ($("#"+this.modalId) as any)?.modal('hide');
    this.emmitClickCloseButton.emit(true);
  }

}
