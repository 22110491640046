<div class="row">
    <div class="col-6 offset-3">
        <footer
            class="footer container text-center animate__animated animate__backInUp shadow"
            [ngClass]="{'bg-dark white-text': isDarkmode}"
            >
            <button
                class="btn btn-primary btn-lg btn-circle mb-1 center-icon center"
                [ngStyle]="{'background-color' : color}"
                >
                <!-- [attr.data-bs-target]="'#'+modalId" -->
                 <!-- data-bs-toggle="modal" -->
                  <!--
                    aria-hidden="true" -->
                <i
                    class="fa fa-plus fa-2x"
                    (click)="buttonPressed()"
                >
                </i>
            </button>
            <div
              class="mt-2"
              *ngFor="let balance of balanceCurrency">
                <h5
                    class="mb-1"
                    [ngClass]="{'white-text': isDarkmode && !textColor}"
                    [ngStyle]="{
                      'color': textColor ?? 'black'
                    }">
                    {{ "words.balance" | translate }}
                    {{ balance.currency.isoCode.toUpperCase() }}:
                    {{ balance.balance | currencyFormat }}
                </h5>
                <p
                    *ngIf="balanceCurrency.length == 1"
                    class="gray"
                    >{{ (subtitle | translate) + (showIsoCode ? currency?.isoCode : "") }}
                </p>
            </div>
            <div
              *ngIf="balanceCurrency.length == 0 && title"
              [ngClass]="{'white-text': isDarkmode && !textColor}"
              [ngStyle]="{
                'color': textColor ?? 'black'
              }">
              {{(title | translate) | currencyFormat: currency }}
              <p
                *ngIf="subtitle"
                class="gray">
                {{ (subtitle | translate) + (showIsoCode ? currency?.isoCode : "") }}
              </p>
            </div>
        </footer>
    </div>
</div>
<!-- (click)="editing = false; categorySelected = null; selectedAccount1 = null; selectedAccount2 = null; resetMap()" -->
