import {CategoryModel} from '../../models/general/category.model';
import {SubcategoryModel} from '../../models/general/subcategory.model';
import {NameColumn} from '../enums/utils.enums';
import moment, {Moment} from 'moment';
import {AccountModel} from '../../models/general/account.model';
import {PeriodType} from '../../components/common-components/selector-period/interfaces/period.interface';
import {RangeDateModel} from '../../models/general/range.date.model';
import {FrequentRecord} from './frequent.record.interface';
import {Movement} from './movement.interface';
import {DayMovementModel} from '../../models/general/day.movement.model';
import StartOf = moment.unitOfTime.StartOf;
import {DurationInputArg2} from 'moment/moment';
import {ChartLine, ChartPie, ReportDates} from './reports.interfaces';
import {ReportCategoryModel} from '../../models/general/report.category.model';
import {TrendsCategoryModel} from '../../models/general/category.trends.model';
import {MovementModel} from '../../models/general/movement.model';

export interface BalanceData {
  incomes: number;
  expenses: number;
}

export interface DataSelectorCategories {
  category: CategoryModel | null;
  subcategory: SubcategoryModel | null;
}

export interface MultipleChangeValues {
  idMovements: number[];
  fkAccount: number | null;
  fkCategory: number | null;
  fkSubcategory: number | null;
}

export interface FiltersData {
  accounts: number[];
  period: PeriodType;
  date: moment.Moment;
  dateRange: RangeDateModel | null;
}

export interface ImagesAndId {
  pkPicture: number;
  name: string;
}

export interface ImagesUpload {
  file: any;
  name: string;
}

export enum CardType {
  buttons = 1,
  balance = 2,
  balancePrevious = 3,
  flowDays = 4,
  categoriesBalances = 5,
  lastTenMovements = 6,
  lastThirtyDays = 7,
  accountsBalances = 8,
  debts = 9,
  budgets = 10
}

export interface DateMovement {
  date: moment.Moment;
  next: moment.Moment;
}

export interface UpdateFrequent {
  frequent_operations: FrequentRecord[];
  movements: Movement[];
}

export interface DataMovements {
  model: DayMovementModel[],
  totalBalance: number
}

export interface DateReportDate {
  model: DayMovementModel[];
  incomes: number;
  expenses: number;
}

export interface ReportDateGroup {
  group: StartOf;
  filter: StartOf;
  format: string;
}

export interface ExchangeRate {
  result: string;
  documentation: string;
  terms_of_use: string;
  time_last_update_unix: number;
  time_last_update_utc: string;
  time_next_update_unix: number;
  time_next_update_utc: string;
  base_code: string;
  conversion_rates: { [key: string]: number };
}

export interface CurrencyRate {
  movement: Movement;
  account: AccountModel;
  toAccount: AccountModel;
}

export interface HomeFilters {
  startOf: StartOf;
  datePrevious: Moment;
}

export interface ReportDateFilters {
  dateFormat: string
  generalFilterMovements?: StartOf | null;
  groupMovements: StartOf;
  stepsModel: DurationInputArg2;
}

export interface ReportTrendsFilters{
  filterMovements: StartOf | null;
  groupModel: StartOf;
  dateFormat: string;
  stepsModel: DurationInputArg2;
}

export interface ReportDateModelInfo {
  modelReport: DayMovementModel[];
  dataChart: ReportDates[];
  incomes: number;
  expenses: number
}

export interface ReportCategoryModelInfo {
  modelReport: ReportCategoryModel[];
  dataChart: ChartPie[];
  colorsChart: ChartPie[];
  totalBalance: number;
}

export interface ReportTrendsModelInfo {
  modelReport: TrendsCategoryModel[];
  totalBalance: number;
  dataChart: ChartLine[];
  colors: Set<string>
}

export interface ReportScheduleModelInfo {
  scheduleData: ScheduleData[];
  movements: MovementModel[];
  balance: number;
}

export interface ListMovementsInfo {
  dayModel: DayMovementModel[];
  totalBalance: number;
}

export interface ScheduleData {
  title: string;
  date: string;
  textColor: string;
}
