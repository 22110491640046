<!--panelClass="{{ls.isDarkmode ? 'myPanelClassDarkType' : 'myPanelClassLightType'}}"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark' : 'light-mode bg-white'}}"-->
    
<mat-select
    panelClass="selectorPanelType"
    class="form-group clear-outline no-margin no-padding p-1 ps-3"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark-input' : 'light-mode bg-white'}}"
    disableOptionCentering
    (selectionChange)="emitType($event.value)"
    [(ngModel)]="selectedType"
    >
    <!-- ngClass="{{ls.isDarkmode ? 'white-text' : 'font-secondary'}}" -->

    <mat-option 
        *ngFor="let type of movementTypes" [value]="type.type"
        class="no-margin no-padding mat-option "
        >
      {{type.title | translate}}
    </mat-option>
</mat-select>