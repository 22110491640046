import { Category } from "src/app/interfaces/general/category.interface";
import { SubcategoryModel } from "./subcategory.model";
export class CategoryModel {
  pkCategory: number;
  name!: string;
  sign!: string;
  iconName!: string;
  colorHex!: string;
  shown!: number;
  deleted!: number;
  shared!: number;
  fkSubscription!: number;
  fkUser!: number;
  serverDate!: string;
  click: boolean = false;

  //OTHER PROPERTIES --------------------------
  subcategories: SubcategoryModel[] = [];
  expandedList: boolean = false;
  selected: boolean = false;
  isShared: boolean = false;

  constructor(json: Category) {
    this.pkCategory = json.pk_category;
    this.updateValues(json);
  }

  public updateValues(json: Category) {
    this.name = json.name;
    this.sign = json.sign;
    this.iconName = json.icon_name;
    this.colorHex = json.color_hex;
    this.shown = json.shown;
    this.deleted = json.deleted;
    this.shared = json.shared;
    this.fkSubscription = json.fk_subscription;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
  }

  public toJSON(): Category {
    return {
      pk_category: this.pkCategory,
      name: this.name,
      sign: this.sign,
      icon_name: this.iconName,
      color_hex: this.colorHex,
      shown: this.shown,
      deleted: this.deleted,
      shared: this.shared,
      fk_subscription: this.fkSubscription,
      fk_user: this.fkUser,
      server_date: this.serverDate,
    }
  }
}
