import { Injectable } from '@angular/core';
import { Currency, UserResponse } from '../shared/navbar/interfaces/userNavbar.interface';
import { PreferencesModel } from '../models/preferences.model';
import { UserPreference } from './interfaces/syncAll.interface';
import { ModelCurrency } from '../models/currency.model';
import {Preference} from '../interfaces/general/preference.interface';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private _preference : PreferencesModel[] = [];
  private _currencies : ModelCurrency[] = [];

  public get preferences() : PreferencesModel[] {
    return [...this._preference];
  }

  public get currencies() : ModelCurrency[] {
    return [...this._currencies];
  }

  constructor(
    private api: ApiService
  ) { }

  //TODO: REVIEW THIS FUNCTION
  public getCurrentUser() {
    const params = {
      "type_request" : "json_search_user",
      "server" : this.api.DbServer,
      "get" : "user_search",
      "table" : "user_accounts",
      "email" : this.api.userData?.email,
      "service" : 1
    };
    return new Promise((resolve, reject) => {
      this.api.getWithOutAuth(params).subscribe((response: UserResponse) => {
        if (response.status == '1' && response.found == 1) {
          let user = response.data.user[0];
          this._preference = this.convertPreference(response.data.preference);
          this._currencies = this.convertCurrency(response.data.currencies);
          resolve(user);
        } else {
          reject(response.message)
        }
      });
    });
  }

  convertPreference(preferences : UserPreference[]) {
    let models : PreferencesModel[] = [];
    if (preferences == undefined) {
      return [];
    }
    preferences.forEach(preference => {
      const model = this.getModel(preference as any);
      models.push(model);
    });
    return models;
  }

  convertCurrency(currencies : Currency[]) {
    let models : ModelCurrency[] = [];
    if (currencies == undefined) {
      return [];
    }
    currencies.forEach(currency => {
        const model = this.getModelCurrency(currency);
        models.push(model);
    });
    return models;
  }

  getModelCurrency(currency : Currency) {
    return new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol);
  }

  getModel(record : Preference) {
    return new PreferencesModel(record);
  }
}
