export class LocalStorageSchedule {

    private keyCategories: string = 'LSScheduleCategories'
    private KeyDate: string = 'LSDateSchedule';

    getCategoriesIds(): number[] {
        return JSON.parse(localStorage.getItem(this.keyCategories)!);
    }
    setCategoriesIds(ids: number[]) {
        localStorage.setItem(this.keyCategories, JSON.stringify(ids))
    }

    getDate(): Date {
      let date = localStorage.getItem(this.KeyDate);
      return date ? new Date(date!) : new Date();
    }

    setDate(date: Date) {
      localStorage.setItem(this.KeyDate, date.toISOString());
    }

    removeAll() {
        localStorage.removeItem(this.keyCategories);
    }
}
