<div 
    class="modal fade" 
    id="modal-mail-password" 
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="modelTitleId" 
    aria-hidden="true"
    >
    <div class="modal-dialog modal-dialog-centered">
        <div 
            [ngClass]="{'bg-dark white-text': isDarkmode}"
            class="modal-content rounded">
            <div class="modal-header">
                <h5
                    class="modal-title offset-1 col-10 text-center gray" 
                    [ngClass]="{'white-text':isDarkmode}"
                    >{{ "password_section.title_send_code" | translate }}
                </h5>
                <button 
                    id="button-close-dialog"
                    type="button" 
                    class="btn btn-close" 
                    data-bs-dismiss="modal" 
                    aria-label="Close" 
                    [ngClass]="{'white-text': isDarkmode}"
                    >
                </button>
            </div>
            <div
                [ngClass]="{'background-dark-card white-text': isDarkmode}"
                class="modal-body"
                >
                <form [formGroup]="form">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="col-12">
                                    <p
                                        [ngClass]="{'white-text':isDarkmode}"
                                        class="no-margin"
                                        >{{ "password_section.message_send_code" | translate }}
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p  
                                        class="no-margin font-secondary text-left mt-2"
                                        [ngClass]="{'white-text':isDarkmode}"
                                        >{{ "password_section.email" | translate }}
                                    </p>
                                    <input
                                        class="form-control input-border-light" 
                                        type="email" 
                                        formControlName="email"
                                        [ngClass]="{'bg-gray clear-outline white-text': isDarkmode}"
                                        >
                                    <div class="row mt-2">
                                        <div class="col text-end text-right">
                                            <button
                                                (click)="sendCode()"
                                                type ="button"
                                                class="btn btn-lg clear-outline bg-main-purple white-text"
                                                >{{ "buttons.button_continue" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                    <p
                                        class="mt-2"
                                        [ngClass]="{'white-text':isDarkmode}"
                                        >
                                        {{error? ('login_messages.message_wrong_email' | translate) : ''}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>