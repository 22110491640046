import { Pipe, PipeTransform } from '@angular/core';
import { SettingsDateFormat } from '../pages/options/interface/date-format.interface';
import { PreferencesModel } from '../models/preferences.model';
import * as moment from 'moment';
import { DateFormat, TimeFormat } from '../pages/options/components/cards/card-date-format/model/card-date-format.model';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  preferences = PreferencesModel.shared;

  transform(value: string, showTime: boolean = true, settings: SettingsDateFormat = this.preferences.dateSettings, onlyTime: boolean = false): string {
    let date = moment(value);
    let format = '';
    if(onlyTime) {
      const timeFormat = `${(settings.timeFormat == TimeFormat.time24) ? 'HH:mm' : 'h:mm A'}`;
      return date.format(timeFormat);
    }
    switch (settings.dateFormat) {
      case DateFormat.aaaa_mm_dd:
        format = 'YYYY-MMMM-DD';
        break;
      case DateFormat.mm_dd_aaaa:
        format = 'MMMM-DD-YYYY';
        break;
      case DateFormat.dd_mm_aaaa:
        format = 'DD-MMMM-YYYY';
        break;
    }
    if(showTime) {
      format = `${format} ${(settings.timeFormat == TimeFormat.time24) ? 'HH:mm' : 'h:mm A'}`;
    }
    return date.format(format);
  }

}
