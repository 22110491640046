import { Injectable } from '@angular/core';
import currencies from '../../json/GD4-currencies.json'
import { ModelCurrency } from '../../models/currency.model';
import {Api3Service} from '../../services/api-3.service';
import {ResponseApi3} from '../../interfaces/responses-general.interface';
import {CurrencyModel} from '../../models/general/currency.model';
import {Currency} from '../../interfaces/general/currency.interface';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  private _currencies:CurrencyModel[] = [];

  public get currencies(): CurrencyModel[] {
    return [...this._currencies];
  }

  constructor(
    private api3: Api3Service
  ) { }

  getCurrencies(currenciesUser : ModelCurrency[]) {
    this._currencies = [];
    currencies.forEach(currency => {
      let row: Currency =  {
        pk_currency: +currency.pk_currency,
        iso_code: currency.iso_code,
        symbol: currency.symbol,
        fk_user: 1
      };
      this._currencies.push(new CurrencyModel(row));
    });

    if (currenciesUser) {
      currenciesUser.forEach(currency => {
        let row: Currency =  {
          pk_currency: currency.pkCurrency,
          iso_code: currency.isoCode,
          symbol: currency.symbol,
          fk_user: this.api3.userData?.user_id ?? 1
        };
        this._currencies.push(new CurrencyModel(row));
      })
    }
  }

  public addCurrency(values : any) {
    const params = {
      data: {
        ...values,
        fk_user: this.api3.userData?.user_id
      }
    }
    return new Promise((resolve, reject) => {
      this.api3.insert(params, 'table_currencies').subscribe((response: ResponseApi3<any>) => {
        if (response.status === 1) {
          let currency: Currency = response.data[0];
          this._currencies.push(new CurrencyModel(currency));
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

}
