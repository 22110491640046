import { Budget } from "src/app/interfaces/general/budget.interface";
import { MovementModel } from "./movement.model";
import { CategoryModel } from "./category.model";
import { SubcategoryModel } from "./subcategory.model";
import { AccountModel } from "./account.model";
import {hexToCSSFilter} from 'hex-to-css-filter';

export class BudgetModel {
  pkBudget: number;
  amount!: number;
  period!: number;
  number!: number;
  year!: number;
  onlyOnce!: number;
  shown!: number;
  showHome!: number;
  dateCreation!: string;
  fkAccount!: number | null;
  fkCategory!: number | null;
  fkSubcategory!: number | null;
  fkSubscription!: number;
  fkUser!: number;
  serverDate!: string;

  //OTHER PROPERTIES ---------------------------------
  click: boolean = false;
  category: CategoryModel | null = null;
  subcategory: SubcategoryModel | null = null;
  account: AccountModel | null = null;
  movements: MovementModel[] = [];
  percentage: number = 0;
  total: number = 0;
  filter: string = '';

  constructor(json: Budget) {
    this.pkBudget = json.pk_budget;
    this.updateValues(json);
  }

  public setPercentage(total: number) {
    const percentage = (total * 100) / this.amount;
    this.total = total <= 0 ? Math.abs(total) : total;
    this.percentage = percentage <= 0 ? Math.abs(percentage) : percentage;
  }

  public setFilter() {
    this.filter = hexToCSSFilter(`#${this.category?.colorHex ?? '000000'}`).filter;
  }

  public toJSON(): Budget {
    return {
      pk_budget: this.pkBudget,
      amount: this.amount,
      period: this.period,
      number: this.number,
      year: this.year,
      only_once: this.onlyOnce,
      shown: this.shown,
      show_home: this.showHome,
      date_creation: this.dateCreation,
      fk_account: this.fkAccount,
      fk_category: this.fkCategory,
      fk_subcategory: this.fkSubcategory,
      fk_subscription: this.fkSubscription,
      fk_user: this.fkUser,
      server_date: this.serverDate
    }
  }

  public updateValues(json: Budget) {
    this.amount = json.amount;
    this.period = json.period;
    this.number = json.number;
    this.year = json.year;
    this.onlyOnce = json.only_once;
    this.shown = json.shown;
    this.showHome = json.show_home;
    this.dateCreation = json.date_creation;
    this.fkAccount = json?.fk_account ?? null;
    this.fkCategory = json?.fk_category ?? null;
    this.fkSubcategory = json?.fk_subcategory ?? null;
    this.fkSubscription = json.fk_subscription;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
  }
}
