import {CategoryModel} from './category.model';
import {MovementModel} from './movement.model';
import moment from 'moment';

export class ReportCategoryModel {
  category: CategoryModel;
  movements: MovementModel[] = [];
  originalMovements: MovementModel[] = [];
  totalAmount: number = 0;
  percentage: number = 0;

  constructor(category: CategoryModel, movements: MovementModel[]) {
    this.category = category;
    this.movements = movements;
    this.originalMovements = movements;
    this.setTotalAmount();
  }

  public filterByType(sign: string) {
    this.movements = this.movements.filter(row => row.sign == sign);
    if(this.movements.length == 0) {
      this.movements = this.originalMovements.filter(row => row.sign == sign);
    }
    this.setTotalAmount();
  }

  public filterByAccount(pkAccounts: number[]) {
    this.movements = this.movements.filter(row => pkAccounts.includes(row.fkAccount));
    this.setTotalAmount();
  }

  public setPercentage(total: number) {
    this.percentage = (this.totalAmount * 100) / Math.abs(total);
    if(this.percentage < 0) {
      this.percentage = Math.abs(this.percentage);
    }
  }

  public resetFilter() {
    this.movements = [...this.originalMovements];
    this.setTotalAmount();
  }

  public setTotalAmount() {
    this. totalAmount = this.movements.reduce((sum, row) => sum + (row.sign == '+' ? row.amount : -row.amount), 0);
  }
}
