<div class="container mt-5">
    <div class="row">
        <div class="card-columns">
            <div 
                class="card semi-rounded" 
                style="background-image: url('assets/images/our-plans/GD4-512.png'); background-repeat: no-repeat; background-position: right top;" 
                [ngClass]="{'bg-dark white-text': isDarkmode}"
                >
                <div class="card-body">
                    <h2 
                        [ngClass]="{'title-dark': !isDarkmode, 'title-light': isDarkmode}"
                        class="card-title">
                        <i 
                            class="fas fa-crown" 
                            style="color: gold;"
                            >
                        </i> {{ "subscription_section.title_premium" | translate }}
                    </h2>
                <div class="container mt-4">
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-all-accounts.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_unlimited_accounts" | translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-budget.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_manage_budgets" | translate }}</p>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-goals.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_manage_goals" | translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-debts.svg" 
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_manage_debt" | translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-sync.svg" 
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_automatic_sync" | translate }}</p>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-bank.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_sync_banks_accounts" | translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-laptop.svg" 
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_web" | translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-xls.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_export" | translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-users.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_share_accounts" | translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-camera.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_save_photo" | translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-agenda.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_agenda" |  translate }}</p>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-2">
                            <img 
                                src="assets/images/our-plans/icon-no-ads.svg"
                                width="30" 
                                [ngClass]="{'filter-white': isDarkmode}"
                                >
                        </div>
                        <div class="col-10">
                            <p class="mt-1 text-left">{{ "subscription_section.plan_remove_advertising" | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div 
            class="card semi-rounded" 
            [ngClass]="{'bg-dark': isDarkmode}"
            >
            <div 
                class="card-body text-center semi-rounded" 
                style="color: white; background-color: #A75DE8;"
                >
                <h3 
                    class="card-title white-text"
                    >{{ "subscription_section.plan_monthly"| translate }}
                </h3>
                <h1 
                    class="price white-text"
                    >
                    <b>$59.00</b>
                </h1>
                <p 
                    class="mt-4 white-text"
                    >{{ "subscription_section.plan_more_sell" | translate }}
                </p>
            </div>
          </div>
          <div 
            class="card semi-rounded" 
            [ngClass]="{'bg-dark': isDarkmode}"
            >
            <div 
                class="card-body text-center semi-rounded" 
                style="color: white; background-color: #009CDE;"
                >
                <h3 
                    class="card-title white-text"
                    >{{ "subscription_section.plan_yearly" | translate }}
                </h3>
                <h1 
                    class="price white-text"
                    >
                    <b>$599.00</b>
                </h1>
                <p 
                    class="mt-4 white-text"
                    >{{ "subscription_section.plan_discount" | translate }}
                </p>
            </div>
          </div>
        </div>
    </div>
</div>