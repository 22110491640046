import {Component, OnInit} from '@angular/core';
import {LocalStorageManager} from 'src/app/utils/local-storage-manager/local-storage-manager.utils';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {FilterService} from '../../../../services/filter.service';
import {BudgetModel} from '../../../../models/general/budget.model';
import {MovementModel} from '../../../../models/general/movement.model';

@Component({
  selector: 'app-card-budgets',
  templateUrl: './card-budgets.component.html',
  styleUrls: ['./card-budgets.component.css']
})
export class CardBudgetsComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;
  noData = false;
  budgets: BudgetModel[] = [];
  loading: boolean = false;

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.loading = false;
    this.budgets = this.filters.budgets.filter(row => {
      return row.shown == 1 && row.showHome == 1;
    });
    this.setBudgetMovements(this.filters.movementsPeriod);
    this.noData = (this.budgets.length == 0);
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  changePage() {
    this.route.navigate(['Budgets/list']);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private setBudgetMovements(movements: MovementModel[]) {
    for (const budget of this.budgets) {
      budget.movements = [...movements];
      if (budget.account) {
        budget.movements = budget.movements.filter(row => row.account == budget.account);
      }
      if (budget.category) {
        budget.movements = budget.movements.filter(row => row.category == budget.category);
      }
      if (budget.subcategory) {
        budget.movements = budget.movements.filter(row => row.subcategory == budget.subcategory);
      }
      const total = budget.movements.reduce((sum, row) => sum + (row.sign == '-' ? -row.amount : row.amount), 0);
      budget.setPercentage(total);
      budget.setFilter();
    }
  }
}
