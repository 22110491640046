import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';
import { Language } from '../../../interface/language.interface';

@Component({
  selector: 'app-card-language',
  templateUrl: './card-language.component.html',
  styleUrls: ['./card-language.component.css']
})
export class CardLanguageComponent implements OnInit {
  @Output() emitter = new EventEmitter<string>();

  localStorage = LocalStorageManager;

  languages : Language[] = [];

  selectedLang? : Language;

  constructor(public translate: TranslateService) {
    let lang = LocalStorageManager.lang != null ? LocalStorageManager.lang : 'en';
    translate.addLangs(['es', 'en', 'pt', 'ru', 'fr', 'it', 'de', 'ar', 'bn', 'ca', 'hi', 'nl', 'ro', 'tr', 'vi']);
    translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.languages = this.loadList();
    this.selectedLang = this.languages.find(model => model.value == lang);
  }

  ngOnInit(): void {}

  loadList() {
    let langs = this.translate.getLangs();
    let languages : Language[] = [];
    langs.forEach(lang => {
      let values = languages.map(lang => lang.value);
      if (!values.includes(lang)) {
        let model : Language = this.getTitleLang(lang);
        languages.push(model);
      }
    });
    languages.sort((a,b) => a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase() ? -1 : 1)
    return languages;
  }

  getTitleLang(lang : any) : Language {
    let response : Language = {
      title : 'English',
      value : 'en'
    };
    switch (lang) {
      case 'de':
        response.title = 'Deutsch';
        response.value = 'de';
        break;
      case 'es':
        response.title = 'Español';
        response.value = 'es';
        break;
      case 'pt':
        response.title = 'Portuguese';
        response.value = 'pt';
        break;
      case 'ru':
        response.title = 'Русский';
        response.value = 'ru';
        break;
      case 'fr':
        response.title = 'Français';
        response.value = 'fr';
        break;
      case 'it':
        response.title = 'Italiano';
        response.value = 'it';
        break;
      case 'ar':
        response.title = 'عربي';
        response.value = 'ar';
        break;
      case 'bn':
        response.title = 'বাংলা';
        response.value = 'bn';
        break;
      case 'ca':
        response.title = 'Català';
        response.value = 'ca';
        break;
      case 'hi':
        response.title = 'हिन्दी';
        response.value = 'hi';
        break;
      case 'nl':
        response.title = 'Nederlands';
        response.value = 'nl';
        break;
      case 'ro':
        response.title = 'Română';
        response.value = 'ro';
        break;
      case 'tr':
        response.title = 'Türkçe';
        response.value = 'tr';
        break;
      case 'vi':
        response.title = 'Tiếng Việt';
        response.value = 'vi';
        break;
      case 'en':
      default:
    }
    return response;
  }

  switchLang(lang: Language) {
    this.emitter.emit(lang.value);
  }
}
