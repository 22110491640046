import { Currency } from "src/app/interfaces/general/currency.interface";

export class CurrencyModel {
  pkCurrency: number;
  isoCode: string;
  symbol: string;
  fkUser: number;

  constructor(json: Currency) {
    this.pkCurrency = json.pk_currency;
    this.isoCode = json.iso_code;
    this.symbol = json.symbol;
    this.fkUser = json.fk_user;
  }
}
