import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {MovementTypeModel} from 'src/app/components/common-components/selector-movement-type/Model/movementType.utils';
import {MovementTypeData, MovementTypes} from './interfaces/movement-type.interface';
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-selector-movement-type',
  templateUrl: './selector-movement-type.component.html',
  styleUrls: ['./selector-movement-type.component.css']
})
export class SelectorMovementTypeComponent implements OnInit {

  @Input() showAllOption: boolean = true;
  @Input() showTransferOption: boolean = true;
  @Input() currentType: MovementTypes | null = null;
  @Input() showAllOptions: boolean = true;

  @Output() emitter = new EventEmitter<MovementTypes>();

  ls = LocalStorageManager;

  movementTypes: MovementTypeData[] = new MovementTypeModel().movementTypes;
  selectedType? : MovementTypes = MovementTypes.all;

  constructor() {}

  ngOnInit(): void {
    this.selectedType = this.currentType?? MovementTypes.all;
    if (!this.showAllOptions) {
      this.movementTypes = this.movementTypes.filter(row =>  {
        return row.sign === '-' || row.sign === '+';
      })
    }
  }

  emitType(type?: MovementTypes) {
    this.selectedType = type;
    this.emitter.emit(type);
  }
}
