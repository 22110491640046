import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/login/layout/login.component';
import { PlansComponent } from './pages/plans/plans.component';
import { OptionsComponent } from './pages/options/options.component';
import { TermsAndConditionsComponent } from './shared/terms-and-conditions/terms-and-conditions.component';
import { NoticeOfPrivacyComponent } from './shared/notice-of-privacy/notice-of-privacy.component';
import { HomeComponent } from './pages/home/layout/home.component';

const routes: Routes = [
  {path: 'Home', component: HomeComponent},
  {path: 'Movements', loadChildren: () => import('../app/pages/movements/movements.module').then( m => m.MovementsModule)},
  {path: 'Categories', loadChildren: () => import('../app/pages/categories/categories.module').then(m => m.CategoriesModule)},
  {path: 'Accounts', loadChildren: () => import('../app/pages/accounts/accounts.module').then( m => m.AccountsModule)},
  {path: 'Schedule', loadChildren: () => import('./pages/schedule/schedule.module').then( m => m.AgendaModule)},
  {path: 'Login', component: LoginComponent},
  {path: 'termsandconditions', component: TermsAndConditionsComponent},
  {path: 'noticeofprivacy', component: NoticeOfPrivacyComponent},
  {path: 'Reports-by-date', loadChildren: () => import('../app/pages/reports-by-date/reports-by-date.module').then( m => m.ReportsByDateModule)},
  {path: 'Reports-by-category', loadChildren: () => import('../app/pages/reports-by-category/reports-by-category.module').then( m => m.ReportsByCategoryModule)},
  {path: 'Trends-in-categories', loadChildren: () => import('../app/pages/trends-in-categories/trends-in-categories.module').then( m => m.TrendsInCategoriesModule)},
  {path: 'Budgets', loadChildren: () => import('../app/pages/budgets/budgets.module').then( m => m.BudgetsModule)},
  {path: 'Debts', loadChildren: () => import('./pages/debts/debts.module').then( m => m.DebtsModule )},
  {path: 'Goals', loadChildren: () => import('./pages/goals/goals.module').then( m => m.GoalsModule )},
  {path: 'Frequent-records', loadChildren: () => import('../app/pages/frequent-records/frequent-records.module').then( m => m.FrequentRecordsModule )},
  {path: 'Plans', component: PlansComponent},
  {path: 'Options', component: OptionsComponent},
  {path: 'Profile', loadChildren: () => import('../app/pages/profile/profile.module').then( m => m.ProfileModule )},
  {path: '**', pathMatch: 'full', redirectTo: 'Login'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false }
      ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
