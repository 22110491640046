import { Component } from '@angular/core';
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-loading-view',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.css']
})
export class LoadingViewComponent {

  darkmode = LocalStorageManager.isDarkmode;
}
