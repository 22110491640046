import { Pipe, PipeTransform } from '@angular/core';
import { PreferencesModel } from '../models/preferences.model';
import { NumberSeparator, Side, ShowHide } from '../pages/options/components/cards/card-currency-format/model/card-currency-format.model';
import { SettingsCurrencyFormat } from '../pages/options/interface/currency-format.interface';
import { ModelCurrency } from '../models/currency.model';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  preferences: PreferencesModel = PreferencesModel.shared;

  transform(value:number | string, currency? : ModelCurrency, currencySettings:SettingsCurrencyFormat = PreferencesModel.shared.currencySettings): string {
    if (!value) {
      value = 0;
    }
    if (!currency) {
      currency = PreferencesModel.shared.currency
    }
    if (isNaN(+value)) return String(value);

    let decimals = currencySettings.decimals;
    let separator = currencySettings.separator;
    let showSymbol = currencySettings.showSymbol;
    let showISOCode = currencySettings.showISOCode;
    let side = currencySettings.symbolsSide;

    let number = (+value).toFixed(decimals);
    let formattedNumber = this.numberWithCommas(number, separator);
    return this.currencyOrientation(formattedNumber, showSymbol, showISOCode, currency!, side);
  }

  private currencyOrientation(formattedNumber:string, showSymbol:ShowHide, showISOCode: ShowHide, currency?:ModelCurrency, side?:Side): string {
    let number = formattedNumber;
    let symbol = currency?.symbol;
    let isoCode = currency?.isoCode;
    if (showSymbol == ShowHide.show) {
      number = side == Side.left ? (symbol + ' ' + number) : (number + ' ' + symbol);
    }
    if (showISOCode == ShowHide.show) {
      number = side == Side.left ? (isoCode + ' ' + number) : (number + ' ' + isoCode)
    }
    return number;
  }

  private numberWithCommas(value: string, separator: NumberSeparator): string {
    let number = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    if (separator == NumberSeparator.dot) {
      return number;
    } else {
      return this.numberWithDecimalCommaSeparator(number);
    }
  }

  private numberWithDecimalCommaSeparator(numberWithCommas: string): string {
    const point: string = '.';
    const comma: string = ',';
    const pointX: string = 'X';
    const commaY: string = 'Y'
    let numberReplaced = numberWithCommas.split(comma).join(pointX);
    numberReplaced = numberReplaced.split(point).join(commaY);
    numberReplaced = numberReplaced.split(pointX).join(point);
    numberReplaced = numberReplaced.split(commaY).join(comma);
    return numberReplaced;
  }
}
