import { Component, OnInit } from '@angular/core';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesModel } from '../../../../models/preferences.model';
import { Router } from '@angular/router';
import {AccountModel} from '../../../../models/general/account.model';
import {FilterService} from '../../../../services/filter.service';
import {hexToCSSFilter} from 'hex-to-css-filter';
import {MovementModel} from '../../../../models/general/movement.model';
import {SettingsCurrencyFormat} from '../../../../pages/options/interface/currency-format.interface';
import {CurrencyModel} from '../../../../models/general/currency.model';

@Component({
  selector: 'app-balance-per-account',
  templateUrl: './balance-per-account.component.html',
  styleUrls: ['./balance-per-account.component.css']
})
export class BalancePerAccountComponent implements OnInit {
  isDarkmode = LocalStorageManager.isDarkmode;
  noData: boolean = false;
  accounts: AccountModel[] = [];
  totalBalance: number = 0;
  preferences = PreferencesModel.shared;
  currency?: CurrencyModel;

  constructor(
      private translate: TranslateService,
      private route: Router,
      private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.currency = this.filters.currency;
    this.getBalance();
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public changePage() {
    this.route.navigate(['Accounts']).then();
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getBalance() {
    this.totalBalance = 0;
    this.accounts = this.filters.accounts;
    this.totalBalance = this.filters.totalBalanceByAccount
  }


}
