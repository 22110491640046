import { Injectable } from '@angular/core';
import {ApiService} from '../../../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SelectorAccountsService {

  constructor(
    private api: ApiService,
  ) { }

  updateAccountsSelecteds(accountsIds: number[], accountsSharedIds: number[] = [], sharedsIds: number[] = []) {
    const params = {
      'get' : 'accounts_selected',
      'pk_accounts' : accountsIds,
      'fk_accounts' : accountsSharedIds,
      'fk_shareds' : sharedsIds,
      'fk_subscription' : this.api.userData?.subscription_id
    }
    return new Promise((resolve, reject) => {
      this.api.update(params).subscribe(response => {
        if (response.status == '1') {
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

}
