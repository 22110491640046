<div
    class="modal fade"
    id="modal-recover-password"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modelTitleId"
    aria-hidden="true"
    >
    <div class="modal-dialog modal-dialog-centered">
        <div
            [ngClass]="{'bg-dark white-text': isDarkmode}"
            class="modal-content rounded">
            <div class="modal-header">
                <h5
                    [ngClass]="{'white-text':isDarkmode}"
                    class="modal-title offset-1 col-10 text-center gray">
                    {{ "password_section.password_confirm" | translate }}
                </h5>
                <button
                    id="button-close-dialog2"
                    type="button"
                    class="btn btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    [ngClass]="{'white-text': isDarkmode}"
                    >
                </button>
            </div>
            <div
                [ngClass]="{'background-dark-card white-text': isDarkmode}"
                class="modal-body">
                <form [formGroup]="form">
                    <div class="container">
                        <div class="row">
                            <p class="text-gray-card mb-3">
                              {{ "password_section.message_send_code_success" | translate }}
                            </p>
                            <div class="col-12">
                                <div class="col-12">
                                    <p
                                        [ngClass]="{'white-text':isDarkmode}"
                                        class="no-margin"
                                        >{{ "password_section.code" | translate }}
                                    </p>
                                    <input
                                        type="text"
                                        class="form-control input-border-light mb-4"
                                        formControlName="passwordCode"
                                        [ngClass]="{'bg-gray clear-outline white-text': isDarkmode}"
                                        >
                                    <p
                                        [ngClass]="{'white-text':isDarkmode}"
                                        class="no-margin"
                                        >{{ "password_section.password" | translate }}
                                    </p>
                                    <input
                                        type="password"
                                        class="form-control input-border-light mb-4"
                                        formControlName="password"
                                        [ngClass]="{'bg-gray clear-outline white-text': isDarkmode}"
                                        >
                                    <p
                                        [ngClass]="{'white-text':isDarkmode}"
                                        class="no-margin"
                                        >{{ "password_section.password_confirm" | translate }}
                                    </p>
                                    <input
                                        type="password"
                                        class="form-control input-border-light mb-2"
                                        formControlName="confirmPassword"
                                        [ngClass]="{'bg-gray clear-outline white-text': isDarkmode}"
                                        >
                                    <div class="row mt-2">
                                        <div class="col text-end text-right">
                                            <button
                                                (click)="changePassword()"
                                                type ="button"
                                                class="btn btn-lg clear-outline bg-main-purple white-text"
                                                >{{ "buttons.button_continue" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                    <p
                                        [ngClass]="{'white-text':isDarkmode}"
                                        class="mt-2"
                                        >
                                        {{ this.error | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
