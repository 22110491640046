import {CategoryModel} from './category.model';
import {MovementModel} from './movement.model';
import moment from 'moment';

export class ReportCategoryModel {
  category: CategoryModel;
  movements: MovementModel[] = [];
  originalMovements: MovementModel[] = [];
  totalAmount: number = 0;
  percentage: number = 0;

  constructor(category: CategoryModel, movements: MovementModel[]) {
    this.category = category;
    this.movements = movements;
    this.originalMovements = movements;
  }
}
