import {MovementModel} from './movement.model';
import {MovementTypes} from '../../components/common-components/selector-movement-type/interfaces/movement-type.interface';
import {MovementStatus} from '../../components/common-components/selector-movement-status/interfaces/movement-status.interfaces';
import moment from 'moment';

export class DayMovementModel {
  date: moment.Moment;
  dateFormat: string;
  movements: MovementModel[] = [];
  originalMovements: MovementModel[] = [];
  incomes: number = 0;
  expenses: number = 0;
  totalAmount: number = 0;

  constructor(date: moment.Moment, movements: MovementModel[], formatDate?: string) {
    this.date = date;
    this.dateFormat = date.format(formatDate ?? 'dddd, DD MMMM');
    this.movements = movements;
    this.orderMovements();
    this.originalMovements = [...movements];
    this.setTotalAmount();
  }

  public applyFilterByCategories(idCategories: number[]) {
    this.movements = this.movements.filter(({fkCategory}) => idCategories.includes(fkCategory ?? 0));
    this.setTotalAmount();
  }

  public applyFilerByAccounts(idAccounts: number[]) {
    this.movements = this.originalMovements.filter(({fkAccount}) => idAccounts.includes(fkAccount));
    this.setTotalAmount();
  }

  public applyFilterType(type: MovementTypes) {
    if(type == MovementTypes.all) {
      this.resetFilter();
    } else if(type == MovementTypes.tranfers) {
      this.movements = this.movements.filter(row => row.transfer == 1);
    } else {
      this.movements = this.movements.filter(row => row.sign == type);
    }
    this.setTotalAmount();
  }

  public applyFilterStatus(status: MovementStatus) {
    if(status == MovementStatus.all) {
      this.resetFilter();
    } else {
      this.movements = this.movements.filter(row => row.status == status);
    }
  }

  public applyFilterTransfers(showTransfers: boolean) {
    if(showTransfers) {
      this.resetFilter();
    } else {
      this.movements = this.movements.filter(row => row.transfer == 0);
    }
  }

  public filterShared(show: boolean) {
    if(show) {
      this.resetFilter();
    } else {
      this.movements = this.movements.filter(row => !row.isShared);
    }
  }

  public resetFilter() {
    this.movements = [...this.originalMovements];
    this.setTotalAmount();
    this.orderMovements();
  }

  private orderMovements() {
    this.movements.sort((a, b) => {
      const aDate = moment(a.date);
      const bDate = moment(b.date);
      return bDate.diff(aDate);
    });
  }

  public setTotalAmount() {
    this.totalAmount = 0;
    this.incomes = 0;
    this.expenses = 0;
    this.movements.forEach(row => {
      if(row.sign == '+') {
        this.incomes = this.incomes + row.amount;
      } else {
        this.expenses = this.expenses + row.amount;
      }
    });
    this.totalAmount = this.movements.reduce((sum, row) => sum + (row.sign == '+' ? row.amount : -row.amount), 0);
  }
}
