<div>
  <div class="container">
    <div class="img-container text-center">
      <span class="loader"></span>
      <img
        src="./assets/images/GD4-256.png"
        alt=""
        class="img-icon">
    </div>
  </div>
</div>
<!--
<div class="row flex-center">
    <img
        src="assets/images/GD4-256.png"
        class="img-size">
    <div class="loader"></div>
</div>-->
