import moment from 'moment';

export class RangeDateModel {
  dateStart: moment.Moment;
  dateEnd: moment.Moment;
  dateFormatter!: string;

  constructor(dateStart: moment.Moment, dateEnd: moment.Moment, isWeekly: boolean) {
    this.dateStart = dateStart.clone();
    this.dateEnd = dateEnd.clone();
    this.formaterDate(isWeekly);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private formaterDate(isWeekly: boolean) {
    const formatStart = this.getDateFormat(this.dateStart, isWeekly);
    const formatEnd = this.getDateFormat(this.dateEnd, isWeekly);
    this.dateFormatter = `${formatStart} Al ${formatEnd}`;
  }

  private getDateFormat(date: moment.Moment, isWeekly: boolean) {
    const month = date.format(isWeekly ? 'MMM' : 'MMMM');
    const day = date.format('DD');
    return isWeekly ? `${day}-${month}` : `${month} ${day}`;
  }
}
