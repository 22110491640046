import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {PreferencesModel} from 'src/app/models/preferences.model';
import {LocalStorageManager} from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {curveNatural} from 'd3-shape';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {PreferencesSessionStorage} from 'src/app/utils/local-storage-manager/session-storage-preferences.utils';
import {MovementsService} from '../../../../pages/movements/services/movements.service';
import {FilterService} from '../../../../services/filter.service';
import {DayMovementModel} from '../../../../models/general/day.movement.model';

@Component({
  selector: 'app-balance-last-thirty-days',
  templateUrl: './balance-last-thirty-days.component.html',
  styleUrls: ['./balance-last-thirty-days.component.css']
})
export class BalanceLastThirtyDaysComponent implements OnInit {
  showTransfers = new PreferencesSessionStorage().getHome();
  isDarkmode = LocalStorageManager.isDarkmode;

  preferences = PreferencesModel.shared;
  noData: boolean = false;
  date: moment.Moment = moment();
  dayMovements: DayMovementModel[] = [];
  curve = curveNatural;
  customColors = [
    {name: 'Total', value: '#3F647EB3'}
  ];
  totalBalanceAccount: number = 0;
  dataChart: any[] = [];

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService,
    private movementService: MovementsService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.filters.getBalanceAccount().subscribe(amount => {
      this.totalBalanceAccount = amount;
      this.setDataMovements();
    });
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  changePage() {
    this.route.navigate(['Reports-by-date/reports']).then();
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private setDataMovements() {
    const movements = this.getMovements();
    this.movementService.setDayMovementModel('day', movements);
    this.dayMovements = this.movementService.getDayMovementModel();
    this.orderModel();
    this.setDataChart();
  }

  private orderModel() {
    this.dayMovements = this.dayMovements.sort((a, b) => {
      if(a.date.isAfter(b.date)) return 1;
      if(a.date.isBefore(b.date)) return -1;
      return 0;
    });
  }

  private setDataChart() {
    const dateAgo = this.date.clone().subtract(1, 'month');
    const currentDate = dateAgo.clone();
    this.dataChart = [];
    const series: any[] = [];
    while (currentDate.isBefore(this.date)) {
      const dayMovement = this.dayMovements.find(row => currentDate.isSame(row.date, 'day'));
      if(dayMovement) {
        series.push({
          name: dayMovement.date.format('DD-MM'),
          value: dayMovement.totalAmount
        });
      } else {
        series.push({
          name: currentDate.format('DD-MM'),
          value: this.totalBalanceAccount
        });
      }
      currentDate.add(1, 'day');
    }
    this.dataChart = [{
      name: 'Total',
      series: series
    }];
  }

  private getMovements() {
    const dateAgo = this.date.clone().subtract(1, 'month');
    return this.filters.movementsAll.filter(row => {
      const dateRow = moment(row.date);
      return dateRow.isSameOrAfter(dateAgo) && dateRow.isSameOrBefore(this.date);
    });
  }
}
