import { Movement } from "src/app/interfaces/general/movement.interface";
import { CategoryModel } from "./category.model";
import { SubcategoryModel } from "./subcategory.model";
import { AccountModel } from "./account.model";
import {PictureModel} from './picture.model';

export class MovementModel {
  pkMovement: number;
  amount!: number;
  banking!: number;
  beneficiary!: string | null;
  date!: string;
  detail!: string;
  latitude!: string | null;
  longitude!: string | null;
  placeName!: string | null;
  sign!: string;
  status!: number;
  transfer!: number;
  transferCode!: string | null;
  fkAccount!: number;
  fkCategory!: number | null;
  fkFrequent_operation!: number | null;
  fkSubcategory!: number  | null;
  fkUser!: number;
  serverDate!: string;

  // OTHER PROPERTIES ---------------------------------
  click: boolean = false;
  category: CategoryModel | null = null;
  subcategory: SubcategoryModel | null = null;
  account: AccountModel | null = null;
  pictures: PictureModel[] = [];
  hasPictures: boolean = false;
  fkToAccount: number | null = null;
  pkTransfer: number | null = null;
  iconName: string = '';
  colorHex: string = '';
  filter: string = '';
  isShared: boolean = false;

  constructor(json: Movement, account?: AccountModel, category?: CategoryModel, subcategory?: SubcategoryModel) {
    this.pkMovement = json.pk_movement;
    this.updateValues(json);
    this.account = account ?? null;
    this.category = category ?? null;
    this.subcategory = subcategory ?? null;
    this.setColorAndIcon();
  }

  public setColorAndIcon() {
    if(this.transfer == 1) {
      this.colorHex = '#000000';
      this.iconName = 'icon-two-arrows';
    } else {
      if(this.category) {
        this.iconName = this.category.iconName;
        this.colorHex = '#'+this.category.colorHex;
      }
      if(this.subcategory) {
        this.iconName = this.subcategory.iconName;
      }
    }
  }

  public updateValues(json: Movement, account?: AccountModel, category?: CategoryModel, subcategory?: SubcategoryModel) {
    this.amount = json.amount;
    this.banking = json.banking;
    this.beneficiary = json.beneficiary;
    this.date = json.date;
    this.detail = json.detail;
    this.latitude = json.latitude;
    this.longitude = json.longitude;
    this.placeName = json.place_name;
    this.sign = json.sign;
    this.status = json.status;
    this.transfer = json.transfer;
    this.transferCode = json.transfer_code;
    this.fkAccount = json.fk_account;
    this.fkCategory = json.fk_category;
    this.fkFrequent_operation = json.fk_frequent_operation;
    this.fkSubcategory = json.fk_subcategory;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
    if (account) {
      this.account = account;
    }
    if (category) {
      this.category = category;
    }
    if (subcategory) {
      this.subcategory = subcategory;
    }
    this.setColorAndIcon();
  }

  public toJson(): Movement {
    return  {
      pk_movement: this.pkMovement,
      amount: this.amount,
      banking: this.banking,
      beneficiary: this.beneficiary,
      date: this.date,
      detail: this.detail,
      latitude: this.latitude,
      longitude: this.longitude,
      place_name: this.placeName,
      sign: this.sign,
      status: this.status,
      transfer: this.transfer,
      transfer_code: this.transferCode,
      fk_account: this.fkAccount,
      fk_category: this.fkCategory,
      fk_frequent_operation: this.fkFrequent_operation,
      fk_subcategory: this.fkSubcategory,
      fk_user: this.fkUser,
      server_date: this.serverDate
    }
  }
}
