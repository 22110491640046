<div
    (click)="changePage()"
    class="card shadow clear-card-outline"
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5
            class="card-title"
            [ngClass]="{'title-dark': !isDarkmode, 'title-light': isDarkmode}">
            {{ "title_cardviews.card_balance_last_thirty_days" | translate }}
        </h5>
        <ng-container *ngIf="noData">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <img
                            class="img-fluid"
                            src="assets/images/empty_cards/card-last-thirty-days.png"
                            >
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!noData">
            <div class="row">
                <div class="col no-padding">
                    <ngx-charts-area-chart
                        [results]="dataChart"
                        [curve]="curve"
                        [showGridLines]="true"
                        [yAxis]="true"
                        [autoScale]="true"
                        [customColors]="customColors"
                        [gradient]="false"
                        [baseValue]="0"
                        [ngClass]="{'white-chart': isDarkmode}"
                        [animations]="false"
                        >
                    </ngx-charts-area-chart>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-6 text-end text-right no-padding">
                        <p>{{ "title_cardviews.balance_today" | translate }}</p>
                    </div>
                    <div class="col-6 text-end text-right no-padding">
                        <p>
                            {{ totalBalanceAccount | currencyFormat : currency}}
                        </p>
                        <p class="gray tiny">
                            <small class="gray tiny">
                                {{ ("currency_section.currency_calculated" | translate) }} {{ currency?.isoCode }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
