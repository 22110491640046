import {Picture} from '../../interfaces/general/picture.interface';

export class PictureModel {
  pkPicture: number;
  name: string;
  fkMovement: number;
  fkUser: number;
  serverDate: string;

  //MARK: OTHER PROPERTIES ------------------------------
  url: string | null = null;

  constructor(json: Picture) {
    this.pkPicture = json.pk_picture;
    this.name = json.name;
    this.fkMovement = json.fk_movement;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
  }
}
