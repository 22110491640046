import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BalanceDebtsComponent } from './card-debts/layout/balance-debts.component';
import { BalanceLastThirtyDaysComponent } from './card-thirty-days-balance/layout/balance-last-thirty-days.component';
import { BalancePerAccountComponent } from './card-accounts-balance/layout/balance-per-account.component';
import { CardBudgetsComponent } from './card-budget/layout/card-budgets.component';
import { LastTenMovementsComponent } from './card-last-movements/layout/last-ten-movements.component';
import { PeriodBalanceComponent } from './card-balance/layout/period-balance.component';
import { MonthlyBalancePreviousBalanceComponent } from './card-previous-balances/layout/monthly-balance-previous-balance.component';
import { SummaryByCategoriesComponent } from './card-summary-by-categories/layout/summary-by-categories.component';
import { RecentDaysFlowComponent } from './card-recent-days/layout/recent-days-flow.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RowDebtComponent } from './card-debts/layout/row-debt/row-debt.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    BalanceDebtsComponent,
    BalanceLastThirtyDaysComponent,
    BalancePerAccountComponent,
    CardBudgetsComponent,
    LastTenMovementsComponent,
    PeriodBalanceComponent,
    MonthlyBalancePreviousBalanceComponent,
    SummaryByCategoriesComponent,
    RecentDaysFlowComponent,
    RowDebtComponent
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
    exports: [
        CardBudgetsComponent

    ]
})
export class CardsModule { }
