<div 
    class="card rounded" 
    [ngClass]="{'bg-dark white-text': ls.isDarkmode, 'card-shadow': !ls.isDarkmode}"
    >
    <div class="card-body">
        <h4 
            class="card-title title-secondary"
            [ngClass]="{'white-text': ls.isDarkmode}"
            >{{"settions_section.settings_contact" | translate}}
        </h4>
        <div class="container text-center">
            <div class="row">
                <div class="col-12 no-padding">
                    
                    <p class="font-secondary text-start" [ngClass]="{'font-secondary-dark': ls.isDarkmode}">
                        {{ "contact_section.contact_body_1" | translate }}
                        <!-- Comunícate con nosotros si necesitas soporte o si tienes dudas sobre la aplicación. -->
                    </p>
                    <p class="font-secondary text-start" [ngClass]="{'font-secondary-dark': ls.isDarkmode}">
                        {{ "contact_section.contact_body_2" | translate }}
                        <!-- Comunícate con nosotros si necesitas soporte o si tienes dudas sobre la aplicación. -->
                    </p>
                    <p
                        class="font-secondary text-start"
                        [ngClass]="{'font-secondary-dark': ls.isDarkmode}"
                        >{{ "contact_section.contact_body_3" | translate }}
                        <!-- Comunícate con nosotros si necesitas soporte o si tienes dudas sobre la aplicación. -->
                    </p>
                    <button
                        class="col-12 btn btn-block clear-outline bg-main-purple white-text mt-2"
                        (click)="redirectTo('https://encodemx.com/daily-expenses-4?section=menu')">
                        {{ "settions_section.settings_help_body" | translate }}
                    </button>
                    <button
                        class="col-12 btn btn-block clear-outline bg-main-purple white-text mt-2"
                        (click)="redirectTo('https://encodemx.com/daily-expenses-4?section=frequent-questions')">
                        {{ "contact_section.contact_frequent_questions" | translate }}
                    </button>
                    <a [href]="mailLink" class="hiden-link">
                        <button class="col-12 btn btn-block clear-outline bg-main-purple white-text mt-2">
                            {{ "contact_section.contact_support" | translate }}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>