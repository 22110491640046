import { Subcategory } from "src/app/interfaces/general/subcategory.interface";

export class SubcategoryModel {
  pkSubcategory: number;
  name!: string;
  iconName!: string;
  deleted!: number;
  shown!: number;
  fkCategory!: number;
  fkUser!: number;
  serverDate!: string;

  //OTHER PROPERTIES --------------------------
  click: boolean = false;

  constructor(json: Subcategory) {
    this.pkSubcategory = json.pk_subcategory;
    this.updateValues(json);
  }

  public updateValues(json: Subcategory) {
    this.name = json.name;
    this.iconName = json.icon_name;
    this.deleted = json.deleted;
    this.shown = json.shown;
    this.fkCategory = json.fk_category;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
  }

  public toJSON(): Subcategory {
    return {
      pk_subcategory: this.pkSubcategory,
      name: this.name,
      icon_name: this.iconName,
      deleted: this.deleted,
      shown: this.shown,
      fk_category: this.fkCategory,
      fk_user: this.fkUser,
      server_date: this.serverDate,
    }
  }
}
