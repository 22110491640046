import {Component, Input, OnChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-message-info-alert',
  templateUrl: './message-info-alert.component.html',
  styleUrls: ['./message-info-alert.component.css']
})
export class MessageInfoAlertComponent implements OnChanges {
  @Input() keyMessage: string = '';
  isDarkMode = LocalStorageManager.isDarkmode;

  constructor(private translate: TranslateService) {
    this.translate.use(LocalStorageManager.lang ?? 'es');
  }

  ngOnChanges() {
    if(this.keyMessage) {
      this.showMessage()
    }
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private showMessage() {
    this.translate.get(this.keyMessage).subscribe(text => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        background: this.isDarkMode ? '#212429' : '#FFF',
        color: this.isDarkMode ? '#FFF' : '#212429',
        confirmButtonColor: '#D4AEF6',
        text,
        timer: undefined
      });
    });
  }
}
