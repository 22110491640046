import {Component, OnInit} from '@angular/core';
import {PreferencesModel} from 'src/app/models/preferences.model';
import {PeriodType} from '../../../common-components/selector-period/interfaces/period.interface';
import {LocalStorageManager} from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {PreferencesSessionStorage} from 'src/app/utils/local-storage-manager/session-storage-preferences.utils';
import {FilterService} from '../../../../services/filter.service';
import {MovementModel} from '../../../../models/general/movement.model';
import moment from 'moment';
import {CurrencyModel} from '../../../../models/general/currency.model';
import {SettingsCurrencyFormat} from '../../../../pages/options/interface/currency-format.interface';

@Component({
  selector: 'app-period-balance',
  templateUrl: './period-balance.component.html',
  styleUrls: ['./period-balance.component.css']
})
export class PeriodBalanceComponent implements OnInit {
  isDarkmode = LocalStorageManager.isDarkmode;
  preferences = PreferencesModel.shared;

  totalBalance: number = 0;
  totalIncomes: number = 0;
  totalExpenses: number = 0;
  dataChart: any[] = [];
  selectedPeriodText: string = "";
  currency?: CurrencyModel;
  customColors = [
    { name: 'Incomes', value: '#0EB59499' },
    { name: 'Expenses', value: '#D1359499' },
    { name: 'Total', value: '#42424299' }
  ];

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang!;
    this.translate.use(lang);
  }

  ngOnInit(): void {
    this.currency = this.filters.currency;
    this.getData();
  }

  public changePage() {
    this.route.navigate(['Reports-by-date/reports']);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getData() {
    this.totalExpenses = this.filters.balanceExpenses;
    this.totalIncomes = this.filters.balanceIncomes;
    this.totalBalance = this.filters.balancePeriod;
    this.selectedPeriodText = `text_cardviews.${this.getPeriodTitle()}`;
    this.setDataChart();
  }

  private setDataChart() {
    this.dataChart = [
      {name: 'Incomes', value: this.totalIncomes},
      {name: 'Expenses', value: this.totalExpenses},
      {name: 'Total', value: this.totalBalance},
    ];
  }

  private getPeriodTitle(): string {
    switch(this.filters.period) {
      case PeriodType.daily: return "card_balance_by_day";
      case PeriodType.weekly: return "card_balance_weekly";
      case PeriodType.fortnightly: return "card_balance_biweekly";
      case PeriodType.monthly: return "card_balance_monthly";
      case PeriodType.annually: return "card_balance_yearly";
      default: return "card_balance_monthly";
    }
  }
}
