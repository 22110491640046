import {Injectable} from '@angular/core';
import {MovementsService} from '../pages/movements/services/movements.service';
import {
  ListMovementsInfo,
  ReportCategoryModelInfo,
  ReportDateFilters,
  ReportDateModelInfo,
  ReportScheduleModelInfo,
  ReportTrendsFilters,
  ReportTrendsModelInfo,
  ScheduleData
} from '../interfaces/general/utils.interface';
import moment, {Moment} from 'moment';
import {MovementModel} from '../models/general/movement.model';
import {AccountModel} from '../models/general/account.model';
import {CurrencyModel} from '../models/general/currency.model';
import {DayMovementModel} from '../models/general/day.movement.model';
import {ChartLine, ChartPie, ReportDates, ReportSeries} from '../interfaces/general/reports.interfaces';
import {DurationInputArg2} from 'moment/moment';
import {MovementTypes} from '../components/common-components/selector-movement-type/interfaces/movement-type.interface';
import {CategoryModel} from '../models/general/category.model';
import {ReportCategoryModel} from '../models/general/report.category.model';
import {CATEGORY_TRANSFER} from '../interfaces/constants/utils.constant';
import {TranslateService} from '@ngx-translate/core';
import {CategoryMovement, TrendsCategoryModel} from '../models/general/category.trends.model';
import {CurrencyFormatPipe} from '../pipes/currency-format.pipe';
import {MovementStatus} from '../components/common-components/selector-movement-status/interfaces/movement-status.interfaces';
import StartOf = moment.unitOfTime.StartOf;

@Injectable({
  providedIn: 'root'
})
export class MultiCurrencyCalculatorService {

  public isMultiCurrency: boolean = false;
  public currency?: CurrencyModel;
  public transferReportDate: boolean = false;
  public transferReportCategory: boolean = false;
  public transferReportSchedule: boolean = false;
  public currentAccounts: AccountModel[] = [];

  constructor(
    private movementService: MovementsService,
    private translate: TranslateService
  ) {  }

  //MARK: REPORT DATE MODEL ----------------------------------------------------------------
  public getReportDateModel(date: moment.Moment, dataReport: ReportDateFilters, showDateEnd: boolean, isFortnight: boolean): ReportDateModelInfo | null {
    const movements = this.getMovementsForReportDate(date, dataReport.generalFilterMovements, this.transferReportDate);
    let dateInitial = this.getDateReport(movements, false)?.startOf(isFortnight ? 'month' : dataReport.stepsModel)!;
    const dateEnd = this.getDateReport(movements, true);
    const modelReport: DayMovementModel[] = [];
    const dataChart: ReportDates[] = [];
    let incomes = 0;
    let expenses = 0;
    if (!dateInitial || !dateEnd) { return null;}
    while (dateInitial.isSameOrBefore(dateEnd)) {
      const dateEndModel = this.getDatePeriod(dateInitial, dataReport.stepsModel, isFortnight, false);
      const modelMovements = this.getMovementsInRangeDate(movements, dateInitial, dateEndModel);
      if (modelMovements.length != 0) {
        const model = new DayMovementModel(dateInitial, modelMovements, dataReport.dateFormat);
        if (showDateEnd) {
          model.setDateEnd(dateEndModel, dataReport.dateFormat, 'al', isFortnight);
        }
        model.incomes = this.sumBySign(modelMovements, '+');
        model.expenses = this.sumBySign(modelMovements, '-');
        incomes += model.incomes;
        expenses += model.expenses;
        model.totalAmount = (model.incomes - model.expenses);
        dataChart.push({
          name: model.dateFormat,
          series: [
            {name: 'Incomes', value: model.incomes},
            {name: 'Expenses', value: -model.expenses},
          ]
        });
        modelReport.push(model);
      }
      dateInitial = this.getDatePeriod(dateInitial, dataReport.stepsModel, isFortnight, true);
    }

    return {modelReport, incomes, expenses, dataChart};
  }

  private getMovementsForReportDate(date: moment.Moment, filter?: StartOf, showTransfers: boolean = true): MovementModel[] {
    return this.movementService.getMovements()
      .filter(movement => {
        const isSameDate = filter ? moment(movement.date).isSame(date, filter) : true;
        const includeTransfer = showTransfers ? true : movement.transfer !== 1;
        const inAccount = this.currentAccounts.includes(movement.account!);
        return isSameDate && includeTransfer && inAccount;
      });
  }

  private getMovementsInRangeDate(movements: MovementModel[], date: moment.Moment, dateEnd: moment.Moment, category: CategoryModel | null = null): MovementModel[] {
    const filterCategory = (item: MovementModel) => category ? item.category === category : true;
    return movements.filter(row => moment(row.date).isBetween(date, dateEnd, undefined, '[)') && filterCategory(row));
  }

  //MARK: REPORT CATEGORY MODEL ------------------------------------------------------------
  public getReportCategoryModel(date: Moment, sign: MovementTypes, period: StartOf, dateEnd?: Moment): ReportCategoryModelInfo {
    const movements = this.getMovementsByReportCategory(date, sign, period, dateEnd);
    const categories = this.getCategoriesForModel(movements);
    const modelReport: ReportCategoryModel[] = [];
    const dataChart: ChartPie[] = [];
    const colorsChart: ChartPie[] = [];
    const totalBalance = this.sumBySign(movements, sign);
    categories.forEach(category => {
      const model = this.getModelReportCategory(category, movements);
      model.totalAmount = this.sumBySign(model.movements, sign);
      model.percentage = (model.totalAmount * 100) / totalBalance;
      dataChart.push({name: model.category.name, value: model.percentage});
      colorsChart.push({name: model.category.name, value:`#${model.category.colorHex}`});
      modelReport.push(model);
    });
    modelReport.sort((a, b) => b.percentage - a.percentage);
    return {modelReport, dataChart, colorsChart, totalBalance};
  }

  private getModelReportCategory(category: CategoryModel, movements: MovementModel[]): ReportCategoryModel {
    let model = category;
    if (!category) {
      model = new CategoryModel(CATEGORY_TRANSFER);
      model.name = this.translate.instant('words.transfers');
    }
    return new ReportCategoryModel(
      model,
      movements.filter(row => row.category === category)
    );
  }

  private getMovementsByReportCategory(date: Moment, sign: MovementTypes, period: StartOf, dateEnd?: Moment): MovementModel[] {
    return this.movementService.getMovements().filter(movement => {
      const inDate = dateEnd ? moment(movement.date).isBetween(date, dateEnd) : moment(movement.date).isSame(date, period);
      const sameSign = (movement.sign === sign);
      const inAccount = this.currentAccounts.includes(movement.account!)
      const includeTransfer = this.transferReportCategory ? (movement.transfer == 1 || !!movement.category) : (movement.transfer == 0);
      return inDate && sameSign && inAccount && includeTransfer;
    });
  }

  private getCategoriesForModel(movements: MovementModel[]): CategoryModel[] {
    const categories = new Set(movements.map(row => row.category!));
    return Array.from(categories);
  }

  //MARK: REPORT TRENDS CATEGORY -----------------------------------------------------------
  public getReportTrendsCategory(date: Moment, filters: ReportTrendsFilters, categories: CategoryModel[], showDateEnd: boolean, isFortnight: boolean): ReportTrendsModelInfo | null {
    const movements = this.getMovementsTrends(date, filters.filterMovements, categories);
    const modelReport: TrendsCategoryModel[] = [];
    const colors: Set<string> = new Set();
    let totalBalance = 0;
    let dateInitial = this.getDateReport(movements, false)?.startOf(isFortnight ? 'month' : filters.stepsModel)!;
    let dateEnd = this.getDateReport(movements, true)!;
    if (!dateInitial || !dateEnd) { return null }
    const dataChart: ChartLine[] = this.getChartVoid(categories, dateInitial, filters, isFortnight);
    while (dateInitial.isSameOrBefore(dateEnd, filters.stepsModel)) {
      const dateModel = this.getDatePeriod(dateInitial, filters.stepsModel, isFortnight, false);
      const categoryMovement: CategoryMovement[] = []
      categories.forEach(category => {
        const movementModel = this.getMovementsInRangeDate(movements, dateInitial, dateModel, category);
        const chartCategory = dataChart.find(row => row.name === category.name);
        const seriesCategory = chartCategory?.series.find(row => row.name === dateInitial.format(filters.dateFormat));
        if (movementModel.length > 0) {
          const model = new CategoryMovement(category, movementModel);
          model.totalAmount = this.sumMovements(movementModel);
          totalBalance += model.totalAmount;
          categoryMovement.push(model);
          if (seriesCategory) {
            seriesCategory.value = model.totalAmount;
          }
        }
        colors.add(`#${category.colorHex}`);
      });
      if (categoryMovement.length > 0) {
        const dateInitialModel = showDateEnd ? dateInitial : dateModel;
        const dateEndModel = showDateEnd ? dateModel.clone() : null;
        const model = new TrendsCategoryModel(dateInitialModel, categoryMovement);
        model.setDateView(filters.dateFormat, dateEndModel);
        modelReport.push(model);
      }
      dateInitial = this.getDatePeriod(dateInitial, filters.stepsModel, isFortnight, true);
    }
    return { modelReport, totalBalance, dataChart, colors };
  }

  private getChartVoid(categories: CategoryModel[], date: Moment, filters: ReportTrendsFilters, isFortnight: boolean): ChartLine[] {
    const chartData: ChartLine[] = [];
    categories.forEach(row => {
      chartData.push({ name: row.name, series: this.getSeriesChart(date, filters, isFortnight)})
    });
    return chartData;
  }

  private getSeriesChart(date: Moment, filters: ReportTrendsFilters, isFortnight: boolean): ReportSeries[] {
    let initialDate = date.clone().startOf(isFortnight ? 'month' : filters.groupModel);
    let endDate = (filters.groupModel != 'year') ? date.clone().endOf(filters.filterMovements) : moment();
    if (filters.groupModel === 'week' && (initialDate.year() === endDate.year())) {
      endDate = endDate.clone().add(1, 'year').endOf('year');
    }
    const series: ReportSeries[] = [];
    while (initialDate.isSameOrBefore(endDate, filters.stepsModel)) {
      series.push({
        name: initialDate.format(filters.dateFormat),
        value: 0
      });
      initialDate = this.getDatePeriod(initialDate, filters.stepsModel, isFortnight, true);
    }
    return series;
  }

  private sumMovements(movements: MovementModel[]): number {
    return movements.reduce((sum, row) => sum + this.getRateAmount(row.amount, row.account!), 0);
  }

  private getMovementsTrends(date: Moment, filter: StartOf | null, categories: CategoryModel[]): MovementModel[] {
    if (!filter) {
      return this.movementService.getMovements();
    }
    return this.movementService.getMovements().filter(movement => {
      const inDate = moment(movement.date).isSame(date, filter);
      const inCategory = categories.includes(movement.category!);
      const inAccount = this.currentAccounts.includes(movement.account!);
      return inDate && inCategory && inAccount
    });
  }

  //MARK: REPORT SCHEDULE ------------------------------------------------------------------
  public getReportSchedule(date: Moment, categories: CategoryModel[]): ReportScheduleModelInfo {
    let initialDate = date.clone().startOf('month');
    const pipe = new CurrencyFormatPipe();
    const endDate = date.clone().endOf('month');
    const movements = this.getMovementsSchedule(initialDate, endDate, categories);
    const calendarEvents: ScheduleData[] = [];
    let balance = this.getBalance(initialDate);
    let balancePeriod = 0;

    while (initialDate.isSameOrBefore(endDate, 'date')) {
      const movementsDay = movements.filter(row => initialDate.isSame(row.date, 'day'));
      const incomes = this.sumBySign(movementsDay, '+');
      const expenses = this.sumBySign(movementsDay, '-');
      balance += (incomes - expenses);
      balancePeriod += (incomes - expenses);
      calendarEvents.push(
        {
          title: this.pipeAmount(incomes, pipe),
          date: initialDate.format('YYYY-MM-DD'),
          textColor: incomes > 0 ? '#0EB594' : 'transparent',
        },
        {
          title: this.pipeAmount(expenses, pipe),
          date: initialDate.format('YYYY-MM-DD'),
          textColor: expenses > 0 ? '#D13594' : 'transparent',
        },
        {
          title: this.pipeAmount(balance, pipe),
          date: initialDate.format('YYYY-MM-DD'),
          textColor: '#B8B8B8'
        }
      )
      initialDate = initialDate.add(1, 'day');
    }

    return { scheduleData: calendarEvents, balance: balancePeriod, movements };
  }

  private getBalance(initialDate: Moment): number {
    let balance = 0;
    const movements = this.movementService.getMovements().filter(row => initialDate.isBefore(row.date, 'day'));

    this.currentAccounts.forEach(row => {
      const amount = this.getRateAmount(row.initialBalance, row);
      balance += (row.sign === '+') ? amount : -amount;
    });

    movements.forEach(row => {
      const amount = this.getRateAmount(row.amount, row.account!);
      balance += (row.sign === '+') ? amount : -amount
    });

    return balance;
  }


  private pipeAmount(amount: number, pipe: CurrencyFormatPipe) {
    return pipe.transform(amount.toString());
  }

  private getMovementsSchedule(initial: Moment, end: Moment, categories: CategoryModel[]): MovementModel[] {
    return this.movementService.getMovements().filter(movement => {
      const inAccount = this.currentAccounts.includes(movement.account!);
      const inTransferOrCategory = this.transferReportSchedule ? (movement.transfer == 1 || categories.includes(movement.category!)) : categories.includes(movement.category!);
      const inDate = moment(movement.date).isBetween(initial, end, undefined, '[]');
      return inAccount && inTransferOrCategory && inDate;
    });
  }

  //MARK: LIST MOVEMENTS -------------------------------------------------------------------
  public getListMovements(date: Moment, startOf: StartOf, status: MovementStatus, orderDescending: boolean, typeMovement: MovementTypes | null, categories?: number[]): ListMovementsInfo | null {
    const movements = this.getMovementsList(date, startOf, status, typeMovement, categories);
    const dayModel: DayMovementModel[] = [];
    let totalBalance: number = 0;
    let initialDate = this.getDateReport(movements, false)!;
    const endDate = this.getDateReport(movements, true);
    if (!initialDate || !endDate) { return null }

    while (initialDate.isSameOrBefore(endDate, 'day')) {
      const movementsDate = movements.filter(row => initialDate.isSame(row.date, 'day'));
      if (movementsDate.length > 0) {
        const model = new DayMovementModel(initialDate.clone(), movementsDate);
        model.incomes = this.sumBySign(movementsDate, '+');
        model.expenses = this.sumBySign(movementsDate, '-');
        totalBalance += (model.incomes - model.expenses);
        orderDescending ? dayModel.unshift(model) : dayModel.push(model);
      }
      initialDate = initialDate.add(1, 'day');
    }
    return { dayModel, totalBalance };
  }

  private getMovementsList(date: Moment, startOf: StartOf, status: MovementStatus, type: MovementTypes | null, categories?: number[]) {
    const rowType = (item: MovementModel) => (!type || type === MovementTypes.all) ? true : (type === MovementTypes.tranfers) ? item.transfer == 1 : item.sign == type;
    const rowCategory = (item: MovementModel) => categories ? categories.includes(item.fkCategory ?? 0) : true;
    const rowStatus = (item: MovementModel)=> status === MovementStatus.all ? true : item.status === status;
    return this.movementService.getMovements().filter(row => {
      const inAccount = this.currentAccounts.includes(row.account!);
      const inDate = date.isSame(row.date, startOf);
      return inAccount && inDate && rowCategory(row) && rowType(row) && rowStatus(row);
    });
  }

  //MARK: UTILS FUNCTIONS ------------------------------------------------------------------
  private getDateReport(movements: MovementModel[], isEndDate: boolean): moment.Moment | null {
    if (movements.length == 0) {
      return null;
    }
    return movements.reduce((date, movement) => {
      const movementDate = moment(movement.date);
      return isEndDate
        ? (movementDate.isAfter(date) ? movementDate : date)
        : (movementDate.isBefore(date) ? movementDate : date);
    }, moment(movements[0].date));
  }

  private getDatePeriod(date: Moment, steps: DurationInputArg2, isFortnight: boolean, isNextPeriod: boolean): Moment {
    if (!isFortnight) {
      return isNextPeriod ? date.clone().add(1, steps).startOf(steps) : date.clone().endOf(steps);
    } else {
      if (date.date() <= 15) {
        return isNextPeriod ? date.clone().date(16).startOf('day') : date.clone().date(15).endOf('day');
      } else {
        return isNextPeriod ? date.clone().add(1, 'month').startOf('month') : date.clone().endOf('month');
      }
    }
  }

  //MARK: CALCULATOR MULTI-CURRENCY --------------------------------------------------------
  public getTitleReport(totalAccount: number): string {
    if (this.currentAccounts.length === totalAccount) {
      return this.translate.instant('selecteds.all_accounts');
    }
    let titleReport = '';
    this.currentAccounts.forEach(row => titleReport = `${titleReport}, ${row.accountName}`);
    return titleReport.substring(2)
  }

  public sumBySign(movements: MovementModel[], sign: string): number {
    return movements
      .filter(row => row.sign === sign)
      .reduce((sum, row) => sum + this.getRateAmount(row.amount, row.account!), 0);
  }

  public getRateAmount(amount: number, account: AccountModel): number {
    if (!this.isMultiCurrency || !account) {
      return amount;
    }
    return amount * account.rate;
  }
}
