import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { FormBuilder } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recover-mail-password',
  templateUrl: './modal-recover-mail-password.component.html',
  styleUrls: ['./modal-recover-mail-password.component.css']
})
export class ModalRecoverMailPasswordComponent implements OnInit {
  @Output() onEventEmittedPkUser = new EventEmitter<string>();
  @Output() onEventEmittedToken = new EventEmitter<string>();
  @Output() onEventEmittedCode= new EventEmitter<number>();
  @Output() onEventEmittedEmail = new EventEmitter<string>();

  isDarkmode = LocalStorageManager.isDarkmode;

  error: boolean = false;

  form = this.fb.group(
    {
      email : ['']
    });

  messages_send_mail: string[] = [];

  constructor(private fb: FormBuilder, private services: LoginService, private translate: TranslateService) {
    this.loadTranslate();
  }

  loadTranslate(){
    let lang = navigator.language
    if (lang == "es-419") {
      lang = "es";
    }
    this.translate.use(lang);
    if (this.messages_send_mail.length < 1) {
      this.translate.get("app_name").subscribe(app_name => {
        this.messages_send_mail.push(app_name);
        this.translate.get("password_section.message_recover_password").subscribe(message_recover_password => {
          this.messages_send_mail.push(message_recover_password);
          this.translate.get("password_section.button_recover_password").subscribe(button_recover_password => {
            this.messages_send_mail.push(button_recover_password);
          });
        });
      });
    }
  }

  ngOnInit(): void {}

  sendCode() {
    if (this.messages_send_mail.length < 1) {
      return;
    }
    if(this.verifyEmail()) {
      this.services.recoverPassword(this.form.value, this.messages_send_mail).then(_ => {
        document.getElementById('button-close-dialog')?.click();
        this.onEventEmittedPkUser.emit(this.services.pkUserPassword);
        this.onEventEmittedToken.emit(this.services.tokenPassword);
        this.onEventEmittedCode.emit(this.services.getCode);
        this.onEventEmittedEmail.emit(this.services.getEmail);
        this.error = false;
      }, reject => {

        this.error = true;
      });
    }
  }

  private verifyEmail() {
    const email = this.form.value['email'];
    if(!email?.trim()) {
      return false;
    }
    return true;
  }
}
