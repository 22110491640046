import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { ResponseRecoverPassword, ResponseUserAccount, ResponseUpdatePassword } from '../interfaces/login.interfaces';
import { PreferencesSessionStorage } from 'src/app/utils/local-storage-manager/session-storage-preferences.utils';
import {ApiService} from '../../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private preferencesManager = new PreferencesSessionStorage();
  _pkUserPassword: string = "";
  _tokenPassword: string = "";
  _code: number = 0;
  _email: string = "";

  public get pkUserPassword(): string {
    return this._pkUserPassword;
  }

  public get tokenPassword(): string {
    return this._tokenPassword;
  }

  public get getCode(): number {
    return this._code;
  }

  public get getEmail(): string{
    return this._email;
  }

  constructor(
    private api: ApiService
  ) { }

  public getUser = ((user : any) => {
    user['table'] = 'user_accounts';
    const params = {
      get : 'login',
      type_request: 'json_login',
      server: this.api.DbServer,
      user: user
    };
    return new Promise((resolve, reject) => {
      this.api.getWithOutAuth(params).subscribe((response: ResponseUserAccount) => {
        if (response.found == 1) {
          if (response.data.subscription[0].active == "1") {
            const authData = {
              'user_id' : response.data.user[0].pk_user,
              'subscription_id' : response.data.subscription[0].pk_subscription,
              'email' : response.data.user[0].apple_id ?? response.data.user[0].email,
              'token' : response.token
            }
            sessionStorage.setItem('user_data', JSON.stringify(authData));
            sessionStorage.setItem('logged', '1');
            sessionStorage.setItem('type_subscription', response.data.subscription[0].type);
            this.preferencesManager.savePreferences(response.data.preference[0] as any );
            const appTheme = response.data.preference[0].theme == '1' ? 'true' : 'false';
            localStorage.setItem('darkmode', appTheme);
            this.saveLogin(authData).then(res => {
              res.subscribe(response => {
                resolve(true);
              })
            }, _ => {
              resolve(true)
            });
          } else {
            resolve(false);
          }
        } else {
          sessionStorage.setItem('logged', '0');
          reject(false);
        }
      });
    });
  });

  private async saveLogin(data: any) {
    const params = this.getParams(data)
    const headers = this.getHeaders(data)
    return this.api.getWithOutAuth(params, headers);
  }

  private getParams(authData: any) {
    return {
      type_request: "json_insert",
      server: this.api.DbServer,
      data: {
          get: "history_login_web",
          os: window.navigator.userAgent,
          fk_user: authData.user_id
      },
      auth_params: {
          email: authData.email,
          user_id: authData.user_id
      }
    }
  }

  private getHeaders(authData: any) {
    const header = new HttpHeaders({ 'x-token' : authData.token ?? '' });
    return { headers : header }
  }

  public recoverPassword(values: any, messages: string[]) {
    let params = values;
    let code = this.generateCode();
    params['code'] = code;
    params['search_email'] = '1';
    params['app_name'] = messages[0];
    params['title'] = messages[1];
    params['body_message'] = messages[2];
    params['type_request'] = 'send_code';
    params['server'] = this.api.DbServer;
    return new Promise((resolve, reject) => {
      this.api.getWithOutAuth(params).subscribe((response: ResponseRecoverPassword) => {
        if (response.found == 1) {
          this._pkUserPassword = response.pk_user;
          this._tokenPassword = response.token;
          this._code = code;
          this._email = values['email'];
          resolve(true);
        } else {
          reject(false);
        }
      })
    });
  }

  public changePassword(values : any, token: string, pk_user: string, email: string) {
    let data = values;
    data['get'] = 'password';
    const paramsData = {
      'type_request':'json_update',
      'data': data,
      'auth_params':{
        'user_id' : pk_user,
        'email' : email
      }
    };
    let headers = new HttpHeaders({ 'x-token' : token});
    let requestOptions = { headers: headers };
    return new Promise((resolve, reject) => {
      this.api.getWithOutAuth(paramsData, requestOptions).subscribe(response => {
        if (response.status == '1') {
          resolve(true);
        } else {
          reject(response.message)
        }
      });
    });
  }

  private generateCode() {
    let random = Math.floor(Math.random() * (100000-999979) + 20);
    return random < 0 ? random * -1 : random;
  }
}
