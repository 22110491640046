import { Injectable } from '@angular/core';
import currencies from '../../json/GD4-currencies.json'
import { ModelCurrency } from '../../models/currency.model';
import {ApiService} from '../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  private _currencies:ModelCurrency[] = [];

  public get currencies():ModelCurrency[] {
    return [...this._currencies];
  }

  constructor(private api: ApiService) { }

  getCurrencies(currenciesUser : ModelCurrency[]) {
    this._currencies = [];
    currencies.forEach(currency => {
      this._currencies.push(new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol));
    });

    if (currenciesUser) {
      currenciesUser.forEach(currency => {
        this._currencies.push(new ModelCurrency(+currency.pkCurrency, currency.isoCode, currency.symbol));
      })
    }
  }

  public addCurrency(values : any) {
    let params = values;
    params['get'] = 'currency';
    params['fk_user'] = this.api.userData?.user_id;
    return new Promise((resolve, reject) => {
      this.api.create(params).subscribe(response => {
        if (response.status == '1') {
          let currency = response.data[0];
          this._currencies.push(new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol));
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

}
