import {Component, OnInit} from '@angular/core';
import {LocalStorageManager} from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {TranslateService} from '@ngx-translate/core';
import {PreferencesModel} from '../../../../models/preferences.model';
import {Router} from '@angular/router';
import {FilterService} from '../../../../services/filter.service';
import {DebtModel} from '../../../../models/general/debt.model';

@Component({
  selector: 'app-balance-debts',
  templateUrl: './balance-debts.component.html',
  styleUrls: ['./balance-debts.component.css']
})
export class BalanceDebtsComponent implements OnInit {
  isDarkmode = LocalStorageManager.isDarkmode;
  preferences = PreferencesModel.shared;
  noData: boolean = false;
  loading: boolean = false;

  debtsThey: DebtModel[] = [];
  debtsMe: DebtModel[] = [];
  totalDebtIncomes = 0;
  totalDebtExpenses = 0;

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.loading = true;
    this.getData();
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  changePage() {
    this.route.navigate(['Debts/list']);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getData() {
    this.filters.getDebts().subscribe(debts => {
      this.loading = false;
      const debtsFilter = debts.filter(debt => debt.showHome == 1);
      this.debtsThey = debtsFilter.filter(debt => debt.sign == '-');
      this.debtsMe = debtsFilter.filter(debt => debt.sign == '+');
      this.getTotal();
      this.noData = (debtsFilter.length == 0);
    });
  }

  private getTotal() {
    this.totalDebtIncomes = this.debtsThey.reduce((sum, row) => sum + row.balance, 0);
    this.totalDebtExpenses = this.debtsMe.reduce((sum, row) => sum + row.balance, 0);
  }

}
