import {Component, OnInit} from '@angular/core';
import moment, {Moment} from 'moment';
import {CalendarModel} from 'src/app/models/calendar.model';
import {PreferencesModel} from 'src/app/models/preferences.model';
import {CardControl, CardType} from '../../../components/cards/interfaces/CardsControl.interface';
import {PeriodData, PeriodType} from '../../../components/common-components/selector-period/interfaces/period.interface';
import {PeriodModel} from '../../../components/common-components/selector-period/Model/periodSelector.Utils';
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';
import {FortnightModel} from '../../../models/biweekly.model';
import {WeekModel} from '../../../models/week.model';
import {TranslateService} from '@ngx-translate/core';
import {SyncAll} from '../../../services/sync-all.service';
import {CalendarOptions} from 'src/app/interfaces/calendar-options.interface';
import {PickerType} from 'src/app/components/common-components/selector-date-by-periods/selector-date-by-periods.component';
import {UserCardModel} from '../../../models/general/user.card.model';
import {AccountModel} from '../../../models/general/account.model';
import {FilterService} from '../../../services/filter.service';
import {RangeDateModel} from '../../../models/general/range.date.model';
import {MovementModel} from '../../../models/general/movement.model';
import {Card} from '../../../interfaces/general/card.interface';
import {hexToCSSFilter} from 'hex-to-css-filter';
import StartOf = moment.unitOfTime.StartOf;
import {HomeFilters} from '../../../interfaces/general/utils.interface';

@Component({
  selector: 'app-home',
  templateUrl: './Home.component.html',
  styleUrls: ['./Home.component.css']
})
export class HomeComponent implements OnInit {
  movements: MovementModel[] = [];
  accounts: AccountModel[] = [];
  selectedWeek: WeekModel;
  selectedFortnight: FortnightModel;
  showCards: boolean = true;
  noData: boolean = false;

  cards: CardControl[] = [];
  cardsTypes = CardType;
  userCards: UserCardModel[] = [];

  isDarkmode = LocalStorageManager.isDarkmode;
  preferences = PreferencesModel.shared;

  date: moment.Moment = LocalStorageManager.home.getDate();
  dateRanges: RangeDateModel | null = null;
  secondDate: moment.Moment = LocalStorageManager.home.getDate2();

  calendarOptions!: CalendarOptions;
  options2?: CalendarOptions;

  isDragEnabled = false;

  periods = new PeriodModel().periods;
  period = PeriodType;
  selectedPeriod: PeriodData;
  calendar: CalendarModel;

  loading: boolean = false;
  notHaveData: boolean = false;
  loadingChange: boolean = false;
  message: string | null = null;
  afterRangeDates: RangeDateModel | null = null;

  selectedAccounts: AccountModel[] = [];
  showTransfers: boolean = this.preferences.transfersHome;
  keyMessage: string = '';
  lang = LocalStorageManager.lang;
  typeDate = PickerType.YEAR;

  constructor(
    private translate: TranslateService,
    private services: SyncAll,
    private filterService: FilterService,
  ) {
    this.translate.use(this.lang!);
    this.selectedPeriod = this.services.currentPeriod;
    this.services.calendar = new CalendarModel(this.date);
    this.calendar = this.services.calendar;
    this.selectedWeek = this.services.calendar.currentWeek;
    this.selectedFortnight = this.services.calendar.currentFortnight;
    this.setPeriod(this.selectedPeriod.period);
  }

  ngOnInit() {
    this.loadData();
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public getData() {
    this.isDragEnabled = false;
    this.accounts = this.services.getAccounts().filter(row => row.shown === 1 && row.deleted === 0);
    this.filterService.budgets = this.services.getBudgetModel();
    this.filterService.setDebts(this.services.getDebtModel());
    setTimeout(() => {
      this.userCards = this.services.getUserCards();
      this.selectedAccounts = this.accounts;
    }, 1);
  }

  public changePeriod(period: PeriodType) {
    this.setPeriod(period);
    this.setFilters();
  }

  public setPeriod(period: PeriodType) {
    LocalStorageManager.home.setPeriod(period);
    let periodModel = this.periods.find(p => p.period == period)!;
    this.selectedPeriod = periodModel;
    this.services.currentPeriod = periodModel;
    switch (period) {
      case PeriodType.daily:
        this.typeDate = PickerType.DATE;
        this.calendarOptions = {format: 'DD-MMMM-YYYY', locale: this.lang!, maxDate: false};
        break;
      case PeriodType.weekly:
      case PeriodType.fortnightly:
      case PeriodType.annually:
        this.typeDate = PickerType.YEAR;
        this.calendarOptions = {format: 'YYYY', locale: this.lang!, maxDate: false};
        break;
      case PeriodType.monthly:
        this.typeDate = PickerType.MONTH;
        this.calendarOptions = {format: 'MMMM-YYYY', locale: this.lang!, maxDate: false};
        break;
      case PeriodType.byDates:
        this.typeDate = PickerType.DATE;
        this.calendarOptions = {format: 'DD-MMMM-YYYY', locale: this.lang!, maxDate: this.secondDate};
        this.options2 = {format: 'DD-MMMM-YYYY', locale: this.lang!, minDate: this.date};
        break;
    }
  }

  public changeDate(date: moment.Moment) {
    this.date = date;
    this.setFilters();
  }

  public changeRangeDate(range: RangeDateModel) {
    this.dateRanges = range;
    this.setFilters();
  }

  public setAfterRangeDates(range: RangeDateModel) {
    this.afterRangeDates = range;
  }

  public setSelectedAccounts(accounts: AccountModel[]) {
    this.selectedAccounts = accounts;
    this.showTransfers = (this.accounts.length === accounts.length) ? false : this.preferences.transfersHome;
    this.verifyMultiCurrency();
    this.setFilters();
  }

  public updateCards() {
    let list = document.getElementById('list')!.getElementsByTagName('li');
    let cardsUpdated: CardControl[] = [];
    let cardButton = this.cards.find(c => c.type == CardType.buttons);
    let cardIndexIncrement: boolean = false;
    for (let i = 0; i < list!.length; i++) {
      const model = this.userCards.find(row => row.card.id == list[i].value)!;
      if (cardButton?.order == i) {
        cardIndexIncrement = true;
      }
      cardsUpdated.push({
        ...model.card,
        id: model.pkUserCard,
        order: cardIndexIncrement ? i + 1 : i
      });
    }
    this.sendUpdateCards(cardsUpdated)
  }

  public toggleDragDropMode() {
    this.isDragEnabled = !this.isDragEnabled;
  }

  public toggleCardHidden(card: CardControl) {
    card.hidden = !card.hidden;
  }

  public setDate(date: moment.Moment) {
    this.date = date;
    LocalStorageManager.home.setDate(date);
    this.services.currentDate = date;
    this.loadingChange = this.services.showLoadingChange;
    setTimeout(() => {
      this.changeDate(date);
    }, 800);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private loadData() {
    this.notHaveData = true;
    this.loading = true;
    if (this.services.getMovementModel().length > 0) {
      this.loading = false;
      this.notHaveData = false;
      this.loadingChange = this.services.showLoadingChange;
      setTimeout(() => {
        this.getData();
      }, 800);
    } else {
      this.services.getAll().then(_ => {
        this.loading = false;
        this.notHaveData = false;
        this.loadingChange = this.services.showLoadingChange;
        setTimeout(() => {
          this.getData();
        }, 800);
      }, _ => {
        this.notHaveData = true;
        this.loading = false;
        this.keyMessage = 'error_messages.sync';
      });
    }
  }

  private sendUpdateCards(cardsUpdate: CardControl[]) {
    this.toggleDragDropMode();
    this.services.updateCards(cardsUpdate).then((data: Card[]) => {
      this.userCards = this.userCards.map(row => {
        const cardUser = data.find(item => item.pk_user_card === row.pkUserCard);
        if (cardUser) {
          return {
            ...row,
            show: cardUser.shown,
            orderCard: cardUser.order_card
          }
        }
        return row;
      });
    });
  }

  private verifyMultiCurrency() {
    const currencies = this.selectedAccounts.map(row => row.currency!);
    this.filterService.isMultiCurrency = (new Set(currencies).size > 1);
    this.filterService.showTransfers = this.showTransfers;
    if (this.filterService.isMultiCurrency) {
      this.filterService.currency = PreferencesModel.shared.currency;
    } else {
      this.filterService.currency = this.selectedAccounts[0]?.currency ?? undefined;
    }
  }

  private setFilters(rangeDate: RangeDateModel | null = null) {
    this.verifyMultiCurrency();
    this.showCards = false;
    if (this.filterService.movementsAll.length == 0) {
      this.filterService.movementsAll = this.services.getMovementModel();
    }
    this.filterService.period = this.selectedPeriod.period;
    this.filterService.currentDate = rangeDate?.dateStart ?? this.date;
    this.filterService.accounts = this.selectedAccounts;
    this.getBalance();
    this.generateReportsCards(rangeDate);
    this.filterService.movementsPeriod = this.movements;
    setTimeout(() => {
      this.showCards = true;
      this.loadingChange = false;
    }, 0);
  }

  private generateReportsCards(rangeDate: RangeDateModel | null) {
    const filters = this.getFilterDate();
    const currentDate = rangeDate?.dateStart ?? this.date;
    this.filterService.balanceByAccount();
    this.filterService.balanceByPeriods(currentDate, filters, rangeDate?.dateEnd);
  }

  private getFilterDate(): HomeFilters {
    const date = this.date.clone();
    switch (this.selectedPeriod.period) {
      case PeriodType.daily:
        return { startOf: 'day', datePrevious: date};
      case PeriodType.weekly:
        return { startOf: 'day', datePrevious: date.startOf('week').subtract(1, 'day') };
      case PeriodType.fortnightly:
        let fortnightlyDate = date.clone();
        if (date.date() <= 15) {
          fortnightlyDate = date.clone().startOf('month').subtract(1, 'day');
        } else {
          fortnightlyDate = date.clone().startOf('month').add(14, 'day');
        }
        return { startOf: 'day', datePrevious: fortnightlyDate };
      case PeriodType.monthly:
        return { startOf: 'month', datePrevious: date.startOf('month').subtract(1, 'day') };
      default:
        return { startOf: 'year', datePrevious: date.startOf('year').subtract(1, 'day') };
    }
  }

  private getBalance() {
    this.selectedAccounts.forEach(account => {
      const listMovements = this.filterService.movementsAll.filter(movement => movement.account === account);
      const incomes = this.getSumMovements(listMovements, '+');
      const expense = this.getSumMovements(listMovements, '-');
      const initialBalance = account.sign === '+' ? account.initialBalance : -account.initialBalance;
      account.balance = initialBalance + incomes - expense;
    });
  }

  private getSumMovements(movements: MovementModel[], sing: string): number {
    return movements.filter(row => row.sign === sing).reduce((sum, row) => sum + row.amount, 0);
  }
}
