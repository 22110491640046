import { Account } from "src/app/interfaces/general/account.interface";
import { CurrencyModel } from "./currency.model";
import { AccountType } from "src/app/interfaces/enums/utils.enums";
import {SharedAccountModel} from './shared.account.model';
import {hexToCSSFilter} from 'hex-to-css-filter';

export class AccountModel {
  pkAccount: number;
  detail!: string;
  accountName!: string;
  balance!: number;
  bank_account_number!: string;
  colorHex!: string;
  deleted!: number;
  iconName!: string;
  initialBalance!: number;
  negativeLimit!: number;
  positiveLimit!: number;
  rate!: number;
  selected!: number;
  shown!: number;
  sign!: string;
  type!: string;
  webColor!: string;
  fkCurrency!: number;
  fkSubscription!: number;
  fkUser!: number;
  serverDate!: string;
  click: boolean = false;

  //MARK: RELATIONSHIP ------------------
  currency: CurrencyModel | null = null;
  shared: SharedAccountModel | null = null;

  //MARK: ADDITIONAL DATA ---------------
  percentage: number = 0;
  isShared: boolean = false;
  filter: string = '';

  constructor(json: Account) {
    this.pkAccount = json.pk_account;
    this.updateValues(json);
    this.getFilterColor();
  }

  public setBalance(incomes: number, expenses: number) {
    if(this.type == AccountType.expenses) {
      this.initialBalance = (this.initialBalance * -1);
      this.balance = (this.balance * -1);
    }
    this.balance = (this.initialBalance + incomes) - expenses;
    this.setPercentage();
  }

  public setPercentage() {
    if(this.positiveLimit != 0) {
      this.percentage = (this.balance*100) / this.positiveLimit;
      if(this.percentage < 0) {
        this.percentage = (this.percentage * -1);
      }
    }
  }

  public updateValues(json: Account) {
    this.detail = json.detail;
    this.accountName = json.account_name;
    this.balance = json.balance;
    this.bank_account_number = json.bank_account_number;
    this.colorHex = json.color_hex;
    this.deleted = json?.deleted ?? 0;
    this.iconName = json.icon_name;
    this.initialBalance = json.initial_balance;
    this.negativeLimit = (json.negative_limit * -1);
    this.positiveLimit = json.positive_limit;
    this.rate = json.rate;
    this.selected = json.selected;
    this.shown = json.shown;
    this.sign = json.sign;
    this.type = json.type;
    this.webColor = json.web_color;
    this.fkCurrency = json.fk_currency;
    this.fkSubscription = json.fk_subscription;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
  }

  public toJSON(): Account {
    return {
      pk_account: this.pkAccount,
      detail: this.detail,
      account_name: this.accountName,
      balance: this.balance,
      bank_account_number: this.bank_account_number,
      color_hex: this.colorHex,
      deleted: this.deleted,
      icon_name: this.iconName,
      initial_balance: this.initialBalance,
      negative_limit: this.negativeLimit,
      positive_limit: this.positiveLimit,
      rate: this.rate,
      selected: this.selected,
      shown: this.shown,
      sign: this.sign,
      type: this.type,
      web_color: this.webColor,
      fk_currency: this.fkCurrency,
      fk_subscription: this.fkSubscription,
      fk_user: this.fkUser,
      server_date: this.serverDate,
    }
  }

  private getFilterColor() {
    this.filter = hexToCSSFilter(`#${this.colorHex}`).filter;
  }
}
