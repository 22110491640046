<div
    (click)="changePage()"
    class="animate__animated animate__fadeIn"
    *ngIf="!loading"
    >
    <div
        class="card shadow clear-card-outline"
        [ngClass]="{'bg-dark white-text': isDarkmode}"
        >
        <ng-container *ngIf="noData">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <img
                            class="img-fluid"
                            src="assets/images/empty_cards/card_debts.png"
                            >
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!noData">
            <div class="card-body">
                <h5
                    class="card-title"
                    [ngClass]="{'title-dark': !isDarkmode, 'title-light': isDarkmode}">
                        {{ "title_cardviews.card_balance_debts" | translate }}
                </h5>
                <ng-container *ngIf="debtsThey.length != 0">
                    <div class="mb-3">
                        <small class="mb-1 gray">
                          {{"title_cardviews.card_i_owe" | translate }}
                        </small>
                        <div *ngFor="let debt of debtsThey">
                            <app-row-debt
                                [debt]="debt"
                                >
                            </app-row-debt>
                        </div>
                    </div>
                </ng-container>
                <div class="row mb-3">
                  <div class="col text-end text-right">
                    <p>{{totalDebtIncomes | currencyFormat}}</p>
                    <p class="gray tiny">
                      <small class="gray tiny">
                        {{ ("currency_section.currency_calculated" | translate) + preferences.currency?.isoCode }}
                      </small>
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="debtsMe.length != 0">
                    <div class="mb-3">
                        <small class="mb-1 gray">
                          {{ "title_cardviews.card_they_owe" | translate }}
                        </small>
                        <div *ngFor="let debt of debtsMe">
                            <app-row-debt
                                [debt]="debt"
                                >
                            </app-row-debt>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col text-end text-right">
                        <p>{{totalDebtExpenses | currencyFormat}}</p>
                        <p class="gray tiny">
                            <small class="gray tiny">
                                {{ ("currency_section.currency_calculated" | translate) + preferences.currency?.isoCode }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
