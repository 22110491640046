<mat-select
    multiple
    panelClass="selectorPanelCategories"
    disableOptionCentering
    [(ngModel)]="selectedCategory"
    (selectionChange)="check()"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark-input' : 'light-mode bg-white'}}"
    class="form-group clear-outline no-margin no-padding p-1 ps-3"
    >
    <!-- (selectionChange)="changeSelection($event.value)" -->
    <mat-select-trigger
        *ngIf="selectAll"
        class="no-margin no-padding text-left"
        ><p
            [ngStyle]="{'color' : ls.isDarkmode ? 'white' : 'black'}"
            class="no-margin no-padding">
            {{"selecteds.all_categories" | translate}}
        </p>

    </mat-select-trigger>

    <app-select-check-all-categories
        *ngIf="showSelectAll"
        [selectAll]="selectAll"
        (emmitSelectAll)="emitSelectAll()"
        >
    </app-select-check-all-categories>

    <mat-option
        [disabled]="limit != 0 && selectedCategoriesPks.length == limit && !selectedCategoriesPks.includes(category.pkCategory)"
        [value]="category"
        *ngFor="let category of categories"
        ngClass="{{category.selected || ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        >
        <p
            class="col-12 no-margin m-2"
            [ngClass]="{'border-cell' : category.selected}"
            [ngStyle]="{'background-color' : category.selected ? '#'+category.colorHex :  ls.isDarkmode ?  '#343a40' : '#ffffff', 'color' : category.selected || ls.isDarkmode ? 'white' : '#9E9E9E'}"
            >
            <img
                ngClass="{{category.selected || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
                class="m-2 p-0"
                width="30"
                height="30"
                src="assets/images/icon-cat/{{category.iconName}}.svg"
                >
              <i
                *ngIf="category.isShared"
                class="fa fa-user-friends align-self-center col-1 pe-0 gray"
                aria-hidden="true"
              ></i>
              {{category.name}}
        </p>
    </mat-option>
</mat-select>
