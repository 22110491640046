import { NumberSeparator } from "../pages/options/components/cards/card-currency-format/model/card-currency-format.model";
import { SettingsCurrencyFormat } from "../pages/options/interface/currency-format.interface";
import { SettingsDateFormat } from '../pages/options/interface/date-format.interface';
import { ModelCurrency } from './currency.model';
import {Preference} from '../interfaces/general/preference.interface';

export class PreferencesModel {

    static shared: PreferencesModel;

    pkPreference: number;
    symbolSide: number;
    showSymbol: number;
    showIsoCode: number;
    decimalFormat: number;
    decimalNumber: number;
    dateFormat: number;
    timeFormat: number;
    theme: number;
    floatingButton: number;
    firstWeekDay: number;
    fkUser: number;
    fkCurrency: number;
    transfers: boolean;
    transfersHome: boolean;
    transfersAgenda: boolean;
    transfersReportsDate: boolean;
    transfersReportsCategory: boolean;

    currencies:ModelCurrency[] = [];
    currency? : ModelCurrency;

    currencySettings:SettingsCurrencyFormat;
    dateSettings:SettingsDateFormat;

    constructor(jsonPreferences: Preference) {
      this.pkPreference = jsonPreferences.pk_preference;
      this.symbolSide = jsonPreferences.symbol_side;
      this.showSymbol = jsonPreferences.show_symbol;
      this.showIsoCode = jsonPreferences.show_iso_code;
      this.decimalFormat = jsonPreferences.decimal_format;
      this.decimalNumber = jsonPreferences.decimal_number;
      this.dateFormat = jsonPreferences.format_date;
      this.timeFormat = jsonPreferences.format_time;
      this.transfers = (jsonPreferences.transfers == 1);
      this.theme = jsonPreferences.theme;
      this.floatingButton = jsonPreferences.floating_button;
      this.firstWeekDay = jsonPreferences.first_weekday;
      this.fkUser = jsonPreferences.fk_user;
      this.fkCurrency = jsonPreferences.fk_currency;
      this.transfersHome = (jsonPreferences.transfers_home == 1);
      this.transfersAgenda = (jsonPreferences.transfers_agenda == 1);
      this.transfersReportsDate = (jsonPreferences.transfers_reports_date == 1);
      this.transfersReportsCategory = (jsonPreferences.transfers_reports_category == 1);
      this.currencySettings = {
        separator: this.decimalFormat == 1 ? NumberSeparator.comma : NumberSeparator.dot,
        symbolsSide: this.symbolSide,
        showSymbol: this.showSymbol,
        showISOCode: this.showIsoCode,
        decimals: this.decimalNumber
      }
      this.dateSettings = {
        dateFormat: this.dateFormat,
        timeFormat: this.timeFormat,
        firstWeekday: this.firstWeekDay
      }
    }

    /*constructor(pkPreference: number, symbolSide: number, showSymbol: number, showIsoCode: number, decimalFormat: number,
                decimalNumber: number, dateFormat: number, timeFormat: number, transfers: number, theme: number,
                floatingButton: number, firstWeekDay: number, fkUser: number, fkCurrency: number) {

        this.pkPreference = pkPreference;
        this.symbolSide = symbolSide;
        this.showSymbol = showSymbol;
        this.showIsoCode = showIsoCode;
        this.decimalFormat = decimalFormat;
        this.decimalNumber = decimalNumber;
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        this.transfers = transfers;
        this.theme = theme;
        this.floatingButton = floatingButton;
        this.firstWeekDay = firstWeekDay;
        this.fkUser = fkUser;
        this.fkCurrency = fkCurrency;

        this.currencySettings = {
            separator: decimalFormat == 1 ? NumberSeparator.comma : NumberSeparator.dot,
            symbolsSide: symbolSide,
            showSymbol: showSymbol,
            showISOCode: showIsoCode,
            decimals: decimalNumber
        }
        this.dateSettings = {
            dateFormat: +dateFormat,
            timeFormat: +timeFormat,
            firstWeekday: +firstWeekDay
        }
    }*/

}
