import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import moment from 'moment';
import {PeriodType} from '../selector-period/interfaces/period.interface';
import {RangeDateModel} from '../../../models/general/range.date.model';
import {MatCalendar} from '@angular/material/datepicker';
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';
import {isDataSource} from '@angular/cdk/collections';

@Component({
  selector: 'app-selector-date-periods',
  templateUrl: './selector-date-periods.component.html',
  styleUrls: ['./selector-date-periods.component.css']
})
export class SelectorDatePeriodsComponent implements OnChanges {
  @ViewChild(MatCalendar) calendar!: MatCalendar<moment.Moment>;

  @Input() currentDate!: moment.Moment;
  @Input() requiredAfterPeriod: boolean = false;
  @Input() period!: PeriodType;
  @Output() date = new EventEmitter<moment.Moment>();
  @Output() rangeDates = new EventEmitter<RangeDateModel>();
  @Output() afterRangeDates = new EventEmitter<RangeDateModel>();

  darkMode = LocalStorageManager.isDarkmode;
  rangeDatesList: RangeDateModel[] = [];
  selectRange!: RangeDateModel;
  showBtnDateRange = false;
  isWeekly = false;
  dateFormatter = '';
  typeFormat = 'YYYY';
  periodDate: DurationConstructor = 'days';

  ngOnChanges() {
    this.getFormat();
    this.setDateFormat();
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public changeDate(isAdd: boolean) {
    if(isAdd) {
      this.incrementDate();
    } else {
      this.reduceDate();
    }
    this.setDateFormat();
    this.regenerateDatesModel();
    this.selectRange = this.rangeDatesList[0];
    this.emitDate();
  }

  public changeRange(isAdd: boolean) {
    this.setChangeRange(isAdd);
  }

  public setYear(date: any) {
    this.currentDate.year(date.getFullYear());
    this.setDateFormat();
    this.calendar.currentView = 'multi-year';
    this.emitDate();
  }

  public preventViewChange(view: string) {
    if(view != 'multi-year') {
      this.calendar.currentView = 'multi-year';
    }
  }

  public setRange(range: RangeDateModel) {
    if(range != this.selectRange) {
      this.selectRange = range;
      this.emitRangeDates();
    }
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private emitDate() {
    this.date.emit(this.currentDate);
  }

  private emitRangeDates() {
    if(this.requiredAfterPeriod) {
      const indexAfter = this.rangeDatesList.findIndex(row => row == this.selectRange) - 1;
      const afterRange = this.rangeDatesList[indexAfter];
      if(afterRange) {
        this.afterRangeDates.emit(afterRange);
      }
    }
    if (this.showBtnDateRange) {
      this.rangeDates.emit(this.selectRange);
    }
  }

  private setDateFormat() {
    this.dateFormatter = this.currentDate.format(this.typeFormat);
  }

  private incrementDate() {
    this.currentDate.add(1, this.periodDate);
  }

  private reduceDate() {
    this.currentDate.subtract(1, this.periodDate);
  }

  private setChangeRange(isAdd: boolean) {
    const index = this.rangeDatesList.findIndex(row => row == this.selectRange);
    let newIndex = index + (isAdd ? 1 : -1);
    const range = this.rangeDatesList[newIndex];
    if(range && (this.selectRange != range)) {
      this.selectRange = range;
      this.emitRangeDates();
    }
  }

  private regenerateDatesModel() {
    this.rangeDatesList = [];
    if(this.period == PeriodType.weekly) {
      this.setRangeDatesList();
    } else {
      this.setRangeDatesListFortnightly();
    }
  }

  private getFormat() {
    this.rangeDatesList = [];
    switch (this.period) {
      case PeriodType.daily:
        this.typeFormat = 'DD-MMMM-YYYY';
        this.periodDate = 'days';
        this.showBtnDateRange = false;
        break;
      case PeriodType.weekly:
        this.typeFormat = 'YYYY';
        this.periodDate = 'year';
        this.showBtnDateRange = true;
        this.isWeekly = true;
        this.setRangeDatesList();
        break;
      case PeriodType.fortnightly:
        this.typeFormat = 'YYYY';
        this.periodDate = 'year';
        this.showBtnDateRange = true;
        this.isWeekly = false;
        this.setRangeDatesListFortnightly()
        break;
      case PeriodType.monthly:
        this.typeFormat = 'MMMM-YYYY';
        this.periodDate = 'month';
        this.showBtnDateRange = false;
        break;
      default:
        this.typeFormat = 'YYYY';
        this.periodDate = 'year';
        this.showBtnDateRange = false;
    }
  }

  private setRangeDatesList() {
    const dayWeekStart = this.currentDate.clone().startOf('year').startOf('isoWeek');
    const dayWeekEnd = this.currentDate.clone().endOf('year').endOf('isoWeek');
    let currentWeek = dayWeekStart.clone();
    while (currentWeek.isBefore(dayWeekEnd)) {
      const startWeek = currentWeek.clone().startOf('isoWeek');
      const endWeek = currentWeek.clone().endOf('isoWeek');
      this.rangeDatesList.push(new RangeDateModel(startWeek, endWeek, true));
      currentWeek = currentWeek.add(1, 'week');
    }
    this.getCurrentRange();
  }

  private setRangeDatesListFortnightly() {
    const startDay = this.currentDate.clone().startOf('year');
    const endDay = this.currentDate.clone().endOf('year');
    let currentDay = startDay.clone();
    while (currentDay.isBefore(endDay)) {
      const isFirst = (currentDay.date() <= 15);
      const startDate = currentDay.clone().startOf('month').date(isFirst ? 1 : 16);
      const endDate = isFirst ? currentDay.clone().startOf('month').date(15) : currentDay.endOf('month');
      this.rangeDatesList.push(new RangeDateModel(startDate, endDate, false));
      currentDay = isFirst ? currentDay.clone().startOf('month').date(16) : currentDay.endOf('month').add(1, 'day');
    }
    this.getCurrentRange();
  }

  private getCurrentRange() {
    const range = this.rangeDatesList.find(row => row.dateStart.isBefore(this.currentDate) && row.dateEnd.isAfter(this.currentDate));
    if (range) {
      this.selectRange = range;
    } else {
      this.selectRange = this.rangeDatesList[0];
    }
    this.emitRangeDates();
  }
}
