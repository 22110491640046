<div
    (click)="changePage()"
    class="card shadow clear-card-outline"
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5
            class="card-title"
            [ngClass]="{'title-dark': !isDarkmode, 'title-light': isDarkmode}">
            {{ selectedPeriodText | translate }}
        </h5>
        <div class="container">
            <div class="row">
                <div class="col-5 no-padding">
                    <p>{{("words.income" | translate) + ":"}}</p>
                </div>
                <div class="col-7 text-end text-right  no-padding">
                    <p>
                        <b
                            >{{totalIncomes | currencyFormat : currency }}
                        </b>
                        <img
                          class="img-sign"
                          src="./assets/images/icon-plus.png"
                          alt=""
                        >
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-5 no-padding">
                    <p>{{("words.expense" | translate) + ":"}}</p>
                </div>
                <div class="col-7 text-end text-right no-padding">
                    <p >
                        <b
                            >{{totalExpenses | currencyFormat : currency}}
                        </b>
                        <img
                          class="img-sign"
                          src="./assets/images/icon-minus.png"
                          alt=""
                        >
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 no-padding" style="height: 60px;">
                    <ngx-charts-bar-vertical
                        [results]="dataChart"
                        [customColors]="customColors"
                        >
                    </ngx-charts-bar-vertical>
                </div>
                <div class="col-6  no-padding pt-2">
                    <p
                        class="text-end"
                        [ngClass]="{'me-3 pe-1': (totalBalance == 0)}">
                        <b>
                            {{totalBalance | currencyFormat  : currency}}
                        </b>
                        <img
                          class="img-sign"
                          src="./assets/images/icon-{{totalBalance > 0 ? 'plus' : 'minus'}}.png"
                          alt=""
                        >
                    </p>
                    <p class="gray tiny text-end me-3 pe-1">
                        <small class="gray tiny">
                            {{ ("currency_section.currency_calculated" | translate) }} {{  currency?.isoCode  }}
                        </small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
