import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { CategoryModel } from 'src/app/models/general/category.model';
import { SubcategoryModel } from 'src/app/models/general/subcategory.model';

@Component({
  selector: 'app-selector-categories',
  templateUrl: './selector-categories.component.html',
  styleUrls: ['./selector-categories.component.css']
})
export class SelectorCategoriesComponent implements OnInit {
  @Input() isStyleMain: boolean = true;
  @Input() categories: CategoryModel[] = [];
  @Input() showAllCategories: boolean = false;
  @Input() categorySelected: CategoryModel | null = null;
  @Input() subcategorySelected: SubcategoryModel | null = null;
  @Input() showSubcategories: boolean = true;
  @Output() setCategorySelected = new EventEmitter<CategoryModel>();
  @Output() setSubcategorySelected = new EventEmitter<SubcategoryModel>();

  hideSubcategoriesSelector: boolean = this.categorySelected != null ? this.categorySelected.subcategories.length == 0 : false;

  select_pk_category: number = 0;
  select_pk_subcategory: number = 0;

  ls = LocalStorageManager;


  constructor() { }

  ngOnInit(): void {
    this.categories?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  ngOnChanges(): void {
    this.filter();
    this.select_pk_category = this.categorySelected ? this.categorySelected.pkCategory : 0;
    this.select_pk_subcategory = this.subcategorySelected ? this.subcategorySelected.pkSubcategory : 0;
    this.hideSubcategoriesSelector = this.categorySelected != null ? this.categorySelected.subcategories.length == 0 : false;
  }

  getColor() {
    let color = this.categorySelected ? this.categorySelected.colorHex : this.ls.isDarkmode ? "#575f68" : "#ffffff";
    return color.includes('#') ? color : '#' + color;
  }

  setCategory(pk_category: number) {
    this.select_pk_category = pk_category;
    if (this.showAllCategories && pk_category == 0) {
      this.categorySelected = null;
      this.subcategorySelected = null;
    } else {
      this.categorySelected = this.categories.find(category => category.pkCategory == pk_category)!;
      if (this.categorySelected && this.categorySelected.subcategories.length == 0) {
        this.subcategorySelected = null;
      }
      let haveSubcategories = this.haveSubcategories();
      this.hideSubcategoriesSelector = this.categorySelected ? !haveSubcategories : true;
    }
    this.setCategorySelected.emit(this.categorySelected!);
    this.setSubcategory(0);
  }

  setSubcategory(pk_subcategory: number) {
    if (pk_subcategory == 0) {
      this.subcategorySelected = null;
    } else {
      let subcategory: any = this.categorySelected?.subcategories.find(subcategory => subcategory.pkSubcategory == pk_subcategory);
      this.subcategorySelected = subcategory;
    }
    this.setSubcategorySelected.emit(this.subcategorySelected!);
  }

  getSubcategories() {
    return this.categorySelected?.subcategories;
  }

  haveSubcategories() {
    return this.categorySelected!.subcategories.filter(sub => sub.deleted == 0).length > 0;
  }

  private filter() {
    if (this.categorySelected) {
      this.categories = this.categories.filter(({ sign }) => this.categorySelected!.sign == sign);
    }
  }
}
