<div class="col-12">
  <!-- MARK: YEAR SELECTOR -->
  <div
    class="row btn-date center"
    [class.dark-theme]="darkMode">
    <button
      class="col btn-date"
      [class.dark-theme]="darkMode"
      (click)="changeDate(false)">
      <
    </button>
    <div
        class="col-8 text-center pointer text-capitalize"
        data-bs-toggle="dropdown">
      {{ dateFormatter }}
    </div>
    <div
        class="dropdown-menu size-menu"
        [class.dark-theme]="darkMode">
      <mat-card class="demo-inline-calendar-card">
        <mat-calendar
          [startView]="'multi-year'"
          (yearSelected)="setYear($event)"
          (viewChanged)="preventViewChange($event)"
          [selected]="currentDate">
        </mat-calendar>
      </mat-card>
    </div>
    <button
      class="col btn-date"
      [class.dark-theme]="darkMode"
      (click)="changeDate(true)">
      >
    </button>
  </div>
  <!-- MARK: DATE BY RANGE TYPE -->
  <div
      *ngIf="showBtnDateRange"
      class="row btn-date center mt-1"
      [class.dark-theme]="darkMode">
    <button
      class="col btn-date"
      [class.dark-theme]="darkMode"
      (click)="changeRange(false)">
      <
    </button>
    <div
      class="col-8 text-center pointer text-capitalize"
      [class.dark-theme]="darkMode"
      data-bs-toggle="dropdown">
      {{ selectRange?.dateFormatter }}
    </div>
    <div
        #dropdownMenu
        class="dropdown-menu size-menu h-menu overflow-auto text-center"
        [class.dark-theme]="darkMode">
        <p
          *ngFor="let date of rangeDatesList"
          [id]="date == selectRange ? 'current-range-date' : null"
          class="pointer m-0 pt-1 pb-1 mt-1 mb-1 date-item text-capitalize"
          [ngClass]="{
            'date-select': (date == selectRange),
            'dark-theme': darkMode,
          }"
          (click)="setRange(date)">
          {{ date.dateFormatter }}
        </p>
    </div>
    <button
      class="col btn-date"
      [class.dark-theme]="darkMode"
      (click)="changeRange(true)">
      >
    </button>
  </div>
</div>
