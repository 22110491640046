<div class="dropdown">

<button
  class="btn btn-secondary w-100 p-0 text-start ps-4 pe-4 btn-style"
  type="button"
  data-bs-toggle="dropdown"
  aria-expanded="false"
  [style.background-color]="'#'+currentAccount?.colorHex">
  <img
    ngSrc="./assets/images/icon-cat/{{currentAccount?.iconName}}.svg"
    alt=""
    class="me-4 filter-white"
    width="40"
    height="40">
    <i
      *ngIf="currentAccount?.isShared"
      class="fa fa-user-friends align-self-center col-1 pe-0 gray"
      aria-hidden="true"
    ></i>
    {{ currentAccount?.accountName }}
</button>
<div
    class="dropdown-menu size-menu col-12"
    [style.background-color]="menuColor">
    <ng-container *ngFor="let account of accounts">
      <div
          class="item-list ms-1 me-1 pointer relative"
          (click)="selectAccount(account)"
          [ngStyle]="{
            'background-color': '#'+account.colorHex
          }">
        <img
          ngSrc="./assets/images/icon-cat/{{account.iconName}}.svg"
          alt=""
          class="me-4 filter-white"
          width="40"
          height="40">
        <i
          *ngIf="account.isShared"
          class="fa fa-user-friends align-self-center col-1 pe-0 gray"
          aria-hidden="true"
        ></i>
        {{ account.accountName }}
        <img
            *ngIf="currentAccount?.pkAccount === account.pkAccount"
            src="./assets/images/icon-done.png"
            alt=""
            width="25"
            height="25"
            class="icon-done">
      </div>
    </ng-container>
</div>

</div>
