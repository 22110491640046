import { Debt } from "src/app/interfaces/general/debt.interface";
import { AccountModel } from "./account.model";
import { DebtRecordModel } from "./debt.record.model";
import { StatusFilter } from "src/app/interfaces/constants/status-list.constant";
import moment from 'moment';

export class DebtModel {
  pkDebt: number;
  name!: string;
  detail!: string;
  amount!: number;
  sign!: string;
  dateLoan!: string;
  dateExpiration!: string;
  status!: number;
  iconName!: string;
  showHome!: number;
  fkAccount!: number;
  fkUser!: number;
  serverDate!: string;

  //OTHER PROPERTIES ---------------------------
  click: boolean = false;
  amountOriginal: number = 0;
  isMultiCurrency: boolean = false;
  account?: AccountModel;
  debtRecords: DebtRecordModel[] = [];
  percentage: number = 0;
  total: number = 0;
  balance: number = this.amount;
  statusData!: StatusFilter;

  constructor(json: Debt) {
    this.pkDebt = json.pk_debt;
    this.updateValues(json);
  }

  public setPercentage(total: number) {
    this.total = total;
    this.balance = (this.sign == '+') ? (-this.amount + total) : (this.amount + total);
    this.percentage = (total * 100) / this.amount;
    if(this.balance <= 0) this.balance = this.balance * -1;
    if(this.percentage <= 0) this.percentage = this.percentage * -1;
  }

  public toJSON(): Debt {
    return {
      pk_debt: this.pkDebt,
      name: this.name,
      detail: this.detail,
      amount: this.amount,
      sign: this.sign,
      date_loan: this.dateLoan,
      date_expiration: this.dateExpiration,
      status: this.status,
      icon_name: this.iconName,
      show_home: this.showHome,
      fk_account: this.fkAccount,
      fk_user: this.fkUser,
      server_date: this.serverDate,
    }
  }

  public updateValues(json: Debt) {
    this.name = json.name;
    this.detail = json.detail;
    this.amount = json.amount;
    this.amountOriginal = json.amount;
    this.sign = json.sign;
    this.dateLoan = moment(json.date_loan).format('DD-MMM-YYYY');
    this.dateExpiration = moment(json.date_expiration).format('DD-MMM-YYYY');
    this.status = json.status;
    this.iconName = json.icon_name;
    this.showHome = json.show_home;
    this.fkAccount = json.fk_account;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
  }
}
