import { Component, Inject } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { UsersService } from './services/users.service';
import { LocalStorageManager } from './utils/local-storage-manager/local-storage-manager.utils';
import { DOCUMENT } from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import 'moment/locale/es.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  logged: boolean = sessionStorage.getItem('logged') == '1';

  routeIsTermsAndConditions: string = "";

  title = 'Gastos-Diarios4';
  isDarkMode = LocalStorageManager.isDarkmode;

  constructor(
    private router: Router,
    private services: UsersService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private documentTheme : Document
  ) { }

  ngOnInit(): void {
    this.setLangDates();
    if(this.isDarkMode) {
      this.documentTheme.body.classList.add('dark-mode');
    } else {
      this.documentTheme.body.classList.remove('dark-mode');
    }
    if (!this.logged) {
      this.redirectRoute();
    }
      /*this.services.getCurrentUser().then((user:any) => {
        if (user == null || user.date_disabled == "") {
          sessionStorage.setItem('logged', "0");
          this.redirectRoute();
        } else {
          this.redirectRoute();
        }
      });*/
  }

  ngOnChanges() {
    this.setLangDates();
  }

  setLangDates() {
    this.translate.onLangChange.subscribe(lang => {
      if (!lang.lang) { return }
      moment.locale(lang.lang);
    });
  }

  redirectRoute() {
    this.router.events.subscribe( value => {
      if (value instanceof NavigationStart) {
        let url = value.url;
        if (url == "/termsandconditions" || url == "/noticeofprivacy") {
          this.routeIsTermsAndConditions = url;
        }
      }
    });
  }
}
