<div
    class="card shadow clear-card-outline"
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    (click)="changePage()"
    >
    <div class="card-body">
        <h5
            class="card-title"
            [ngClass]="{'title-dark': !isDarkmode, 'title-light': isDarkmode}"
            >{{ ("title_cardviews.card_balance_accounts" | translate)}}
        </h5>
        <ng-container *ngIf="noData">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <img
                            class="img-fluid"
                            src="assets/images/empty_cards/card-accounts.png"
                            >
                    </div>
                </div>
            </div>
        </ng-container>
        <div
            class="container"
            *ngIf="!noData"
            >
            <ng-container>
                <div *ngFor="let account of accounts">
                    <div class="row mb-2 no-padding">
                        <div class="col-1 no-padding">
                            <img
                                src="assets/images/icon-cat/{{account.iconName}}.svg"
                                width="28"
                                height="25"
                                style="filter: {{ account.filter }}"
                                >
                        </div>
                        <div class="col-6 text-left no-padding">
                            <p class="gray text-truncate ms-1">
                              {{account.accountName}}
                            </p>
                        </div>
                        <div class="col-5 text-end text-right no-padding">
                            <small
                                class="gray"
                                [ngClass]="{
                                  'white-text': isDarkmode,
                                  'text-red' : account.balance < 0
                                }">
                                {{ account.balance | currencyFormat }}
                            </small>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 text-end text-right no-padding mt-3"
                    [ngClass]="{'text-red' : totalBalance < 0}">
                    <p>{{totalBalance | currencyFormat}}</p>
                    <small class="gray tiny">{{ ('currency_section.currency_calculated' | translate) + preferences.currency?.isoCode }}</small>
                </div>
            </ng-container>
        </div>
    </div>
</div>
