<div
    class="modal fade"
    id="app-modal-success"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modelTitleId"
    aria-hidden="true"
    >
    <div
        class="modal-dialog modal modal-dialog-centered"
        role="document"
        >
        <div
            class="modal-content rounded"
            [ngClass]="{'bg-dark': isDarkmode}"
            >
                <div
                    class="modal-header"
                    [ngClass]="{'white-text': isDarkmode}"
                    >
                    <h5
                        class="modal-title"
                        [ngClass]="{'white-text': isDarkmode}"
                        >{{title | translate}}
                    </h5>
                    <button
                        id="closeDeleteModal3"
                        type="button"
                        class="btn btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        [ngClass]="{'white-text btn-close-white': isDarkmode}"
                        >
                    </button>
                </div>
            <div
                class="modal-body"
                [ngClass]="{'white-text': isDarkmode}"
                >
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h4 [ngClass]="{'white-text': isDarkmode}">
                                {{bodyText | translate}}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn bg-main-purple clear-outline white-text"
                    data-bs-dismiss="modal"
                    >{{ "buttons.button_ok" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
