import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';
import {DebtModel} from '../../../../../models/general/debt.model';

@Component({
  selector: 'app-row-debt',
  templateUrl: './row-debt.component.html',
  styleUrls: ['./row-debt.component.css']
})
export class RowDebtComponent implements OnInit {
  @Input() debt!: DebtModel;
  isDarkmode = LocalStorageManager.isDarkmode;

  constructor() { }

  ngOnInit(): void {
  }

}
