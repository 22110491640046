import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesModel } from '../../../models/preferences.model';
import {BalanceCurrencyModel} from '../../../models/utils/balance.currency.model';
import {CurrencyModel} from '../../../models/general/currency.model';

@Component({
  selector: 'app-bottom-bar-add-button',
  templateUrl: './bottom-bar-add-button.component.html',
  styleUrls: ['./bottom-bar-add-button.component.css']
})
export class BottomBarAddButtonComponent implements OnInit, OnChanges {

  @Input() balanceCurrency: BalanceCurrencyModel[] = []
  @Input() title: any = '';
  @Input() subtitle: string = '';
  @Input() color: string = '#A75DE8';
  @Input() textColor: string | null = null;
  @Input() modalId: string = '';
  @Input() showIsoCode: boolean = false;
  @Input() currency?: CurrencyModel

  @Output() onClick = new EventEmitter();

  isDarkmode = LocalStorageManager.isDarkmode;

  preference = PreferencesModel.shared;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.balanceCurrency.forEach(row => row.setBalance());
    if (typeof (this.title) == 'number') {
      this.title = this.title.toString();
    }
  }

  ngOnChanges() {
    this.balanceCurrency.forEach(row => row.setBalance());
    if (!this.currency) {
      this.currency = this.preference.currency;
    }
  }

  buttonPressed() {
    this.onClick.emit();
  }
}
