import { DebtRecord } from "src/app/interfaces/general/debt.record.interface";

export class DebtRecordModel {
  pkDebtRecord: number;
  amount!: number;
  sign!: string;
  date!: string;
  detail!: string;
  fkDebt!: number;
  fkAccount!: number;
  fkUser!: number;
  serverDate!: string;

  //OTHER PROPERTIES -------------------------------
  click: boolean = false;
  isEditable: boolean = true;

  constructor(json: DebtRecord) {
    this.pkDebtRecord = json.pk_debt_record;
    this.updateValues(json);
  }

  public toJSON(): DebtRecord {
    return {
      pk_debt_record: this.pkDebtRecord,
      amount: this.amount,
      sign: this.sign,
      date: this.date,
      detail: this.detail,
      fk_debt: this.fkDebt,
      fk_account: this.fkAccount,
      fk_user: this.fkUser,
      server_date: this.serverDate,
    }
  }

  public updateValues(json: DebtRecord) {
    this.amount = json.amount;
    this.sign = json.sign;
    this.date = json.date;
    this.detail = json.detail;
    this.fkDebt = json.fk_debt;
    this.fkAccount = json.fk_account;
    this.fkUser = json.fk_user;
    this.serverDate = json.server_date;
  }
}
