<div
    class="card rounded"
    [ngClass]="{'bg-dark white-text': ls.isDarkmode, 'card-shadow': !ls.isDarkmode}"
    >
    <div class="card-body">
        <h4
            class="card-title title-secondary"
            [ngClass]="{'white-text': ls.isDarkmode}"
            >{{ 'settions_section.settings_format_currency' | translate }}
        </h4>
        <div class="container text-center mt-3">
            <div class="row">
                <div class="col-12">
                    <div
                        class="clear-outline"
                        [ngClass]="{'bg-gray': ls.isDarkmode, 'bg-light': !ls.isDarkmode}"
                        >
                        <p
                            class="title-secondary no-margin text-preview"
                            [ngClass]="{'white-text': ls.isDarkmode}"
                            >{{ testNumber | currencyFormat:currentCurrency }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row mt-2 font-secondary" [ngClass]="{'font-secondary-dark': ls.isDarkmode}">
                <div class="col-6 no-padding pe-1">
                    <p class="no-margin text-start">
                        {{ "currency_section.currency_separator" | translate }}
                    </p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="separatorsItmes"
                        [currentItem]="currencySettings.separator"
                        (emitter)="setDecimalFormat($event)"
                        >
                    </app-segmented-selector>
                </div>
                <div class="col-6 no-padding ps-1">
                    <p class="no-margin text-start">{{ "currency_section.currency_orientation" | translate }}</p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="symbolSidesItems"
                        [currentItem]="currencySettings.symbolsSide"
                        (emitter)="setSymbolSide($event)"
                        >
                    </app-segmented-selector>
                </div>
            </div>

            <div class="row mt-2 font-secondary" [ngClass]="{'font-secondary-dark': ls.isDarkmode}">
                <div class="col-6 no-padding pe-1">
                    <p class="no-margin text-start">{{ "currency_section.currency_symbol" | translate }}</p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="showHideSymbolItems"
                        [currentItem]="currencySettings.showSymbol"
                        (emitter)="setShowSymbol($event)"
                        >
                    </app-segmented-selector>
                </div>
                <div class="col-6 no-padding ps-1">
                    <p class="no-margin text-start">{{ "currency_section.currency_iso_code" | translate }}</p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="showHideISOCodeItems"
                        [currentItem]="currencySettings.showISOCode"
                        (emitter)="setShowIsoCode($event)"
                        >
                    </app-segmented-selector>
                </div>
            </div>

        <div class="row mt-2 font-secondary" [ngClass]="{'font-secondary-dark': ls.isDarkmode}">
            <div class="col-12 no-padding">
                <p class="no-margin text-start">{{ "currency_section.currency_decimals" | translate }}</p>
                <app-segmented-selector
                    [smallSize]="true"
                    [items]="decimals"
                    [currentItem]="currencySettings.decimals"
                    (emitter)="setDecimalNumber($event)"
                    >
                </app-segmented-selector>
            </div>
        </div>

        <div
            [ngClass]="{'font-secondary-dark': ls.isDarkmode}"
            class="row mt-2 font-secondary"
            >
            <div class="col-12">
                <p class="no-margin text-start">{{ "currency_section.currency_yours" | translate }}</p>
                <table
                    class="table table-sm table-hover font-secondary"
                    [ngClass]="{'table-dark': ls.isDarkmode, 'font-secondary-dark table-white': ls.isDarkmode}"
                    >
                    <tbody>
                        <tr
                            *ngFor="let currency of usedCurrencies"
                            class="pointer"
                            >
                            <td class="text-end">{{currency.isoCode}}</td>
                            <td class="text-start">{{currency.symbol}}</td>
                            <td>
                                <i
                                    class="fa fa-check"
                                    aria-hidden="true"
                                    *ngIf="currency == currentCurrency"
                                    >
                                </i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 text-end">
                <button
                    class="btn clear-outline bg-main-purple white-text"
                    data-bs-toggle="modal"
                    data-bs-target="#app-modal-currencies"
                    (click)="showModalCurrencies()"
                    >{{ "currency_section.currency_choose" | translate }}
                </button>
            </div>
        </div>
        </div>
    </div>
</div>

<app-modal-currencies
    [isModalCalled]="isModalCurrenciesCalled"
    [currencies]="preferences.currencies"
    [currentCurrency]="preferences.currency"
    (emitter)="setCurrency($event)"
    >
</app-modal-currencies>
