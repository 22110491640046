import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {LocalStorageManager} from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {FilterService} from '../../../../services/filter.service';
import {MovementModel} from '../../../../models/general/movement.model';
import {Color, ScaleType} from '@swimlane/ngx-charts';

@Component({
  selector: 'app-recent-days-flow',
  templateUrl: './recent-days-flow.component.html',
  styleUrls: ['./recent-days-flow.component.css']
})
export class RecentDaysFlowComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;

  date: moment.Moment = moment();
  noData: boolean = false;
  dataChart: any[] = [];
  colorScheme: Color = {
    name: 'customScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#3F647E', '#7499B4', '#AECAD8', '#EE7387', '#EF9AA9', '#F0C0C9', '#F0C892']
  };

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.getDataChart(this.filters.movementsAll);
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  changePage() {
    this.route.navigate(['Reports-by-date/reports']);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getDataChart(movements: MovementModel[]) {
    const startDate = this.date.clone().subtract(1, 'week');
    this.dataChart = [];
    const movementsRange = movements.filter(row => {
      const dateRow = moment(row.date);
      return dateRow.isBetween(startDate, this.date);
    });
    movementsRange.forEach(row => {
      this.dataChart.push({
        name: moment(row.date).format('MMM-DD'),
        value: (row.sign == '-') ? -row.amount : row.amount
      });
    });
    this.noData = (movementsRange.length == 0);
  }
}
