<div
    (click)="changePage()"
    class="card shadow clear-card-outline"
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5
            class="card-title"
            [ngClass]="{'title-dark': !isDarkmode, 'title-light': isDarkmode}"
            >
            {{ (titleReport | translate) }}
        </h5>
        <div class="container">
            <div class="row">
                <div class="col-5 no-padding ">
                    <p>{{ "words.income" | translate }}</p>
                </div>
                <div class="col-7 no-padding text-end text-right">
                    <p>
                        <b
                            >{{totalIncomes | currencyFormat }}
                        </b>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-5 no-padding">
                    <p>{{ "words.expense" | translate }}</p>
                </div>
                <div class="col-7 no-padding text-end text-right">
                    <p>
                        <b
                            >{{totalExpenses | currencyFormat }}
                        </b>
                        <i class="fa fa-minus" aria-hidden="true"></i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-5 no-padding">
                    <p>{{ "title_cardviews.balance_previous" | translate }}</p>
                </div>
                <div class="col-7 no-padding text-end text-right">
                    <p>
                        <b>
                          {{ previousBalance | currencyFormat }}
                        </b>
                        <i
                          class="fa"
                          aria-hidden="true"
                          [ngClass]="{
                            'fa-plus': previousBalance > 0,
                            'fa-minus': previousBalance < 0
                          }">
                        </i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 no-padding text-right" style="height: 60px;">
                    <ngx-charts-bar-vertical
                        [results]="chartData"
                        [customColors]="customColors"
                        [animations]="false"
                        >
                    </ngx-charts-bar-vertical>
                </div>
                <div class="col-6 no-padding text-end text-right pt-2">
                    <p>
                        <b>
                            {{ totalBalance | currencyFormat}}
                        </b>
                        <i class="fa fa-plus" aria-hidden="true" *ngIf=" 0 < totalBalance"></i>
                        <i class="fa fa-minus" aria-hidden="true" *ngIf=" 0 > totalBalance"></i>
                    </p>
                    <p class="gray tiny me-3 pe-1">
                        <small class="gray tiny">
                            {{ ("currency_section.currency_calculated" | translate) +  preferences.currency?.isoCode }}
                        </small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
