import {SharedAccount} from '../../interfaces/general/shared.data.interface';

export class SharedAccountModel {
  pkSharedAccount: number;
  canAdd: boolean;
  canDelete: boolean;
  canUpdate: boolean;
  selected: number;
  fkShared: number;
  fkAccount: number;
  fkUser: number | null;

  //MARK: OTHER PROPERTIES ------------------------------
  viewAccount: boolean = false;
  permissionsChange: boolean = false;

  constructor(json: SharedAccount) {
    this.pkSharedAccount = json.pk_shared_account;
    this.canAdd = (json.can_add == 1);
    this.canDelete = (json.can_delete == 1);
    this.canUpdate = (json.can_update == 1);
    this.selected = json.selected;
    this.fkShared = json.fk_shared;
    this.fkAccount = json.fk_account;
    this.fkUser = json.fk_user?? null;
  }
}
