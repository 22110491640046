import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MovementModel} from '../models/general/movement.model';
import {PeriodType} from '../components/common-components/selector-period/interfaces/period.interface';
import {AccountModel} from '../models/general/account.model';
import {DebtModel} from '../models/general/debt.model';
import {BudgetModel} from '../models/general/budget.model';
import {PreferencesModel} from '../models/preferences.model';
import moment, {Moment} from 'moment';
import {hexToCSSFilter} from 'hex-to-css-filter';
import {CurrencyModel} from '../models/general/currency.model';
import StartOf = moment.unitOfTime.StartOf;
import {HomeFilters} from '../interfaces/general/utils.interface';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private debtsSubject = new BehaviorSubject<DebtModel[]>([]);
  private accountBalanceSubject = new BehaviorSubject<number>(0);
  private movementsPeriodSubject = new BehaviorSubject<MovementModel[]>([]);
  private currentPeriod!: PeriodType;
  private selectedAccounts: AccountModel[] = [];
  private allMovements: MovementModel[] = [];
  private currentBudgets: BudgetModel[] = [];
  private date: moment.Moment = moment();

  public movementsPeriod: MovementModel[] = [];
  public isMultiCurrency: boolean = false;
  public currency?: CurrencyModel;
  public showTransfers: boolean = false;
  public totalBalanceByAccount: number = 0;
  public balanceIncomes: number = 0;
  public balanceExpenses: number = 0;
  public balancePeriod: number = 0;
  public balancePrevious: number = 0;

  public setEndLoad(movements: MovementModel[]) {
    this.movementsPeriodSubject.next(movements);
  }

  public getEndLoad() {
    return this.movementsPeriodSubject.asObservable();
  }

  //MARK: GETTERS ------------------------------------------------------------------------------------
  public get period(): PeriodType {
    return this.currentPeriod;
  }

  public get accounts(): AccountModel[] {
    return [...this.selectedAccounts];
  }

  public get movementsAll(): MovementModel[] {
    return [...this.allMovements];
  }

  public get budgets(): BudgetModel[] {
    return [...this.currentBudgets];
  }

  public get currentDate(): moment.Moment {
    return this.date;
  }

  //MARK: SETTERS ------------------------------------------------------------------------------------
  public set period(period: PeriodType) {
    this.currentPeriod = period;
  }

  public set accounts(account: AccountModel[]) {
    this.selectedAccounts = [...account];
  }

  public set movementsAll(allMovements: MovementModel[]) {
    this.allMovements = [...allMovements];
  }

  public set budgets(budget: BudgetModel[]) {
    this.currentBudgets = [...budget];
  }

  public set currentDate(date: moment.Moment) {
    this.date = date;
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public setBalanceAccount(value: number) {
    this.accountBalanceSubject.next(value);
  }

  public getBalanceAccount() {
    return this.accountBalanceSubject.asObservable();
  }

  public setDebts(debts: DebtModel[]) {
    this.debtsSubject.next([...debts]);
  }

  public getDebts() {
    return this.debtsSubject.asObservable();
  }

  //MARK: BALANCE BY ACCOUNT -------------------------------------------------------------------------
  public balanceByAccount() {
    this.totalBalanceByAccount = 0;
    this.calculateByMovements();
    this.calculateByAccount();
  }

  private calculateByAccount() {
    this.accounts.forEach(account => {
      const amount = this.getRateAmount(account.initialBalance, account);
      this.totalBalanceByAccount += (account.sign === '+') ? amount : -amount;
    });
  }

  private calculateByMovements() {
    this.movementsAll.forEach(movement => {
      const inAccount = this.accounts.includes(movement.account!);
      const transfer = this.showTransfers ? true : (movement.transfer === 0);
      if (inAccount && transfer) {
        const amount = this.getRateAmount(movement.amount, movement.account!);
        this.totalBalanceByAccount += (movement.sign === '+') ? amount : -amount;
      }
    });
  }

  //MARK: BALANCE BY PERIOD --------------------------------------------------------------------------
  public balanceByPeriods(date: Moment, filters: HomeFilters, dateEnd?: Moment) {
    this.resetAmount();
    const currentMovements = this.getCurrentMovements(date, filters.startOf, dateEnd);
    const previousMovements = this.getPreviousMovements(filters.datePrevious);
    const previousIncomes = this.sumBySign('+', previousMovements);
    const previousExpenses = this.sumBySign('-', previousMovements)
    const initialBalanceAccounts = this.getInitialBalanceAccount();
    this.balanceIncomes = this.sumBySign('+', currentMovements);
    this.balanceExpenses = this.sumBySign('-', currentMovements);
    this.balancePeriod = (this.balanceIncomes - this.balanceExpenses);
    this.balancePrevious = ((initialBalanceAccounts + previousIncomes) - previousExpenses);
    this.setEndLoad([...currentMovements]);
  }

  public getInitialBalanceAccount(): number {
    return this.accounts
      .reduce((sum, account) => sum + this.getRateAmount(account.initialBalance, account), 0)
  }

  private resetAmount() {
    this.balanceIncomes = 0;
    this.balanceExpenses = 0;
    this.balancePeriod = 0;
    this.balancePrevious = 0;
  }

  private sumBySign(sign: string, movements: MovementModel[]): number {
    return movements
          .filter(row => row.sign === sign)
          .reduce((sum, row) => sum + this.getRateAmount(row.amount, row.account!), 0);
  }

  private getPreviousMovements(date: Moment): MovementModel[] {
    const rowAccount = (item: MovementModel) => this.accounts.includes(item.account!);
    const rowTransfer = (item: MovementModel) => this.showTransfers ? true : (item.transfer === 0);
    const rowDate = (item: MovementModel) => moment(item.date).isSameOrBefore(date, 'day');
    return this.movementsAll.filter(item => {
      return rowDate(item) && rowTransfer(item) && rowAccount(item);
    });
  }

  private getCurrentMovements(date: Moment, startOf: StartOf, dateEnd?: Moment): MovementModel[] {
    const rowDate = (item: MovementModel) => dateEnd ? moment(item.date).isBetween(date, dateEnd, undefined, '[]') : date.isSame(item.date, startOf);
    const rowTransfer = (item: MovementModel) => this.showTransfers ? true : item.transfer === 0;
    const rowAccount = (item: MovementModel) => this.accounts.includes(item.account!);
    return this.movementsAll.filter(item => {
      return rowDate(item) && rowTransfer(item) && rowAccount(item);
    });
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  public getRateAmount(amount: number, account: AccountModel): number {
    if (!this.isMultiCurrency) { return amount }
    return amount * account.rate;
  }
}
