<div class="row">
    <div class="col-8">
      <div
          class="row"
          style="margin-left: 0.2%; display: flex; align-items: center">
        <div class="col-2 p-0 text-center">
          <img
            src="./assets/images/icon-cat/{{debt.iconName}}.svg"
            alt=""
            class="w-75"
            [ngClass]="{
              'filter-white': isDarkmode,
              'filter-black-1': !isDarkmode
            }">
        </div>
        <p class="col gray m-0 p-0">
          {{ debt.name }}
        </p>
      </div>
    </div>
    <div class="col text-end text-right">
        <p class="m-0">{{ debt.amount | currencyFormat }}</p>
    </div>
</div>
<div
    [ngClass]="{'bg-primary-dark' : isDarkmode}"
    class="progress mb-3 mt-1"
    >
    <div
        class="progress-bar"
        style="border-radius: 10px"
        role="progressbar"
        [ngStyle]="{
          'width' : (debt.percentage | number: '1.2-2')+'%',
          'background-color': debt.sign == '-' ? '#6A1C4A' : '#176655'
        }"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        >
    </div>
</div>
