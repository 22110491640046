import {CardControl, CardType} from '../../components/cards/interfaces/CardsControl.interface';
import {PeriodBalanceComponent} from '../../components/cards/card-balance/layout/period-balance.component';
import {SummaryByCategoriesComponent} from '../../components/cards/card-summary-by-categories/layout/summary-by-categories.component';
import {LastTenMovementsComponent} from '../../components/cards/card-last-movements/layout/last-ten-movements.component';
import {
  MonthlyBalancePreviousBalanceComponent
} from '../../components/cards/card-previous-balances/layout/monthly-balance-previous-balance.component';
import {RecentDaysFlowComponent} from '../../components/cards/card-recent-days/layout/recent-days-flow.component';
import {BalanceLastThirtyDaysComponent} from '../../components/cards/card-thirty-days-balance/layout/balance-last-thirty-days.component';
import {CardBudgetsComponent} from '../../components/cards/card-budget/layout/card-budgets.component';
import {BalancePerAccountComponent} from '../../components/cards/card-accounts-balance/layout/balance-per-account.component';
import {BalanceDebtsComponent} from '../../components/cards/card-debts/layout/balance-debts.component';
import {KeyValue} from './status-list.constant';

export const CARDS_OBJECT: KeyValue<CardControl> = {
  1: {
    type: CardType.buttons,
    id: 1,
    order: 1,
    hidden: false,
    component: null
  },
  2: {
    type: CardType.balance,
    id: 2,
    order: 2,
    hidden: false,
    component: PeriodBalanceComponent
  },
  3: {
    type: CardType.balancePrevious,
    id: 3,
    order: 3,
    hidden: false,
    component: MonthlyBalancePreviousBalanceComponent
  },
  4: {
    type: CardType.lastTenMovements,
    id: 4,
    order: 4,
    hidden: false,
    component: BalancePerAccountComponent
  },
  5: {
    type: CardType.balancePrevious,
    id: 5,
    order: 5,
    hidden: false,
    component: RecentDaysFlowComponent
  },
  6: {
    type: CardType.flowDays,
    id: 6,
    order: 6,
    hidden: false,
    component: SummaryByCategoriesComponent
  },
  7: {
    type: CardType.lastThirtyDays,
    id: 7,
    order: 7,
    hidden: false,
    component: LastTenMovementsComponent
  },
  8: {
    type: CardType.budgets,
    id: 8,
    order: 8,
    hidden: false,
    component: BalanceLastThirtyDaysComponent
  },
  9: {
    type: CardType.accountsBalances,
    id: 9,
    order: 9,
    hidden: false,
    component: BalanceDebtsComponent
  },
  10: {
    type: CardType.debts,
    id: 10,
    order: 10,
    hidden: false,
    component: CardBudgetsComponent
  }
};

export const CARDS_LIST: CardControl[] = [
  {
    type: CardType.buttons,
    id: 0,
    order: 0,
    hidden: false,
    component: null
  },
  {
    type: CardType.balance,
    id: 1,
    order: 1,
    hidden: false,
    component: PeriodBalanceComponent,
  },
  {
    type: CardType.categoriesBalances,
    id: 2,
    order: 2,
    hidden: false,
    component: SummaryByCategoriesComponent
  },
  {
    type: CardType.lastTenMovements,
    id: 3,
    order: 3,
    hidden: false,
    component: LastTenMovementsComponent
  },
  {
    type: CardType.balancePrevious,
    id: 4,
    order: 4,
    hidden: false,
    component: MonthlyBalancePreviousBalanceComponent
  },
  {
    type: CardType.flowDays,
    id: 5,
    order: 5,
    hidden: false,
    component: RecentDaysFlowComponent
  },
  {
    type: CardType.lastThirtyDays,
    id: 6,
    order: 6,
    hidden: false,
    component: BalanceLastThirtyDaysComponent
  },
  {
    type: CardType.budgets,
    id: 7,
    order: 7,
    hidden: false,
    component: CardBudgetsComponent
  },
  {
    type: CardType.accountsBalances,
    id: 8,
    order: 8,
    hidden: false,
    component: BalancePerAccountComponent
  },
  {
    type: CardType.debts,
    id: 9,
    order: 9,
    hidden: false,
    component: BalanceDebtsComponent
  }
];
