import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Response } from 'src/app/interfaces/responses-general.interface';
import { SyncDebt } from 'src/app/interfaces/sync.interface';
import { DebtModel } from 'src/app/models/general/debt.model';
import { DebtRecordModel } from 'src/app/models/general/debt.record.model';
import { Debt } from 'src/app/interfaces/general/debt.interface';
import { DebtRecord } from 'src/app/interfaces/general/debt.record.interface';
import { AccountModel } from 'src/app/models/general/account.model';
import { Account } from 'src/app/interfaces/general/account.interface';
import { STATUS_LIST } from 'src/app/interfaces/constants/status-list.constant';

@Injectable({
  providedIn: 'root'
})
export class DebtsService {

  private statusFilter = STATUS_LIST;
  private accountsModel: AccountModel[] = [];
  private debtsModel: DebtModel[] = [];
  private debtRecordsModel: DebtRecordModel[] = [];

  constructor(private api: ApiService) { }

  //MARK: PUBLIC METHODS ----------------------------------------------------------------
  public getAccounts(): AccountModel[] {
    return [...this.accountsModel];
  }

  public getDebts(): DebtModel[] {
    return [...this.debtsModel];
  }

   public getSync() {
    let params = {
      'get' : 'debts',
      'fk_subscription' : this.api.userData?.subscription_id
    };
    return new Promise((resolve, reject) => {
      this.api.sync(params).subscribe((response: Response<SyncDebt>) => {
        if(response.status == 1) {
          this.setDebtRecordsModel(response.data.table_debts_records);
          this.setAccountModel(response.data.table_accounts);
          this.setDebtModel(response.data.table_debts);
          resolve(true);
        } else{
          reject();
        }
      });
    });
  }

  public createDebt(value : Debt) {
    let params: any = {...value};
    params['get'] = 'debt';
    params['fk_subscription'] = this.api.userData?.subscription_id;
    return new Promise((resolve, reject) => {
      this.api.create(params).subscribe((response: Response<Debt[]>) => {
        if(response.status == 1) {
          const model = new DebtModel(response.data[0]);
          this.setRelationsDebt(model);
          this.debtsModel.push(model);
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }

   public updateDebt(values : Debt, debt : DebtModel) {
    let params: any = {...values};
    params['get'] = 'debt';
    params['fk_subscription'] = this.api.userData?.subscription_id;
    params['pk_debt'] = debt.pkDebt;
    return new Promise((resolve, reject) => {
      this.api.update(params).subscribe((response: Response<Debt[]>) => {
        if(response.status == 1) {
          const data = response.data[0];
          debt.updateValues(data);
          debt.debtRecords = [];
          this.setRelationsDebt(debt);
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }

  public deleteDebt(debt: Debt) {
    let params = {
      'get': 'delete',
      'table': 'table_debts',
      'pk_subscription': this.api.userData?.subscription_id,
      'idColumnName' : 'pk_debt',
      'id' : debt.pk_debt
    }
    return new Promise((resolve, reject) => {
      this.api.delete(params).subscribe((response: Response<any>) => {
        if(response.status == 1) {
          this.debtsModel = this.debtsModel.filter(({pkDebt}) => pkDebt != debt.pk_debt);
        } else {
          this.debtsModel = this.debtsModel.filter(({pkDebt}) => pkDebt != debt.pk_debt);
          reject();
        }
      });
    });
  }

  public setTotalDebt(model: DebtModel) {
    let total = 0;
    model.debtRecords.map((row) => {
      if(!row.isEditable) { return }
      if(row.sign == '-') {
        total = total - row.amount;
      } else {
        total = total + row.amount;
      }
    });
    model.setPercentage(total);
  }

  //MARK: PRIVATE METHODS ---------------------------------------------------------------
  private setAccountModel(accounts: Account[]) {
    this.accountsModel = [];
    for (const account of accounts) {
      this.accountsModel.push(new AccountModel(account));
    }
  }

  private setDebtModel(debts: Debt[]) {
    this.debtsModel = [];
    for (const debt of debts) {
      const model = new DebtModel(debt);
      this.setRelationsDebt(model);
      this.debtsModel.push(model);
    }
  }

  private setRelationsDebt(model: DebtModel) {
    const account = this.accountsModel.find(({pkAccount}) => pkAccount == model.fkAccount);
    model.debtRecords = this.debtRecordsModel.filter(({fkDebt}) => fkDebt == model.pkDebt);
    model.statusData = this.statusFilter[model.status];
    if (account) {
      model.account = account;
    }
    this.setTotalDebt(model);
  }

  private setDebtRecordsModel(debtRecords: DebtRecord[]) {
    this.debtRecordsModel = [];
    for (const record of debtRecords) {
      this.debtRecordsModel.push(new DebtRecordModel(record));
    }
  }
}
