<div 
    class="card rounded card-shadow" 
    [ngClass]="{'bg-dark white-text': localStorage.isDarkmode, 'card-shadow': !localStorage.isDarkmode}"
    >
    <div class="card-body">
        <h4 
            class="card-title title-secondary"
            [ngClass]="{'white-text': localStorage.isDarkmode}"
            >{{ "web_titles.title_language" | translate }}
        </h4>
        <div class="container font-secondary" [ngClass]="{'font-secondary-dark': localStorage.isDarkmode}">
            <div class="row">
                <div class="col-12 no-padding">
                    <p class="no-margin text-left">
                        {{ "web_messages.body_idiom" | translate }}
                    </p>

                    <mat-select
                        panelClass="myPanelLang"
                        ngClass="{{localStorage.isDarkmode ? 'dark-mode' : 'light-mode-option'}}"
                        class="form-group clear-outline bg-main-purple pointer no-margin no-padding p-1 ps-3 white-text pointe "
                        disableOptionCentering
                        [(ngModel)]="selectedLang"
                        (selectionChange)="switchLang($event.value)"
                        >
                        <mat-option
                            *ngFor="let language of languages"
                            class="no-margin no-padding mat-option"
                            ngClass="{{localStorage.isDarkmode ? 'white-text' : 'font-secondary'}}"
                            [value]="language"
                            ><p>{{language.title}}</p>
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
</div>