import {Component, OnInit} from '@angular/core';
import {PeriodType} from '../../../common-components/selector-period/interfaces/period.interface';
import {LocalStorageManager} from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {TranslateService} from '@ngx-translate/core';
import {PreferencesModel} from '../../../../models/preferences.model';
import {Router} from '@angular/router';
import {FilterService} from '../../../../services/filter.service';
import {MovementModel} from '../../../../models/general/movement.model';
import {ReportCategoryModel} from '../../../../models/general/report.category.model';
import {CategoryModel} from '../../../../models/general/category.model';
import {CurrencyModel} from '../../../../models/general/currency.model';

@Component({
  selector: 'app-summary-by-categories',
  templateUrl: './summary-by-categories.component.html',
  styleUrls: ['./summary-by-categories.component.css']
})
export class SummaryByCategoriesComponent implements OnInit {
  isDarkmode = LocalStorageManager.isDarkmode;
  noData = false;
  preferences: PreferencesModel = PreferencesModel.shared;
  selectedPeriodText: string = '';
  categoryReportModel: ReportCategoryModel[] = [];
  dataChart: { name: string, value: number }[] = [];
  customColors: { name: string, value: string }[] = [];
  totalBalance: number = 0;
  currency?: CurrencyModel;

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.filters.getEndLoad().subscribe(movements => {
      if (movements.length === 0 ) { return }
      this.selectedPeriodText = this.getPeriodName();
      this.currency = this.filters.currency;
      const movementsFilter = movements.filter(row => row.sign === '-' && !!row.category);
      this.setModel(movementsFilter);
    });
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  changePage() {
    this.route.navigate(['Reports-by-category/reports']).then();
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getPeriodName(): string {
    switch (this.filters.period) {
      case PeriodType.daily:
        return 'text_cardviews.card_summary_by_category_by_day';
      case PeriodType.weekly:
        return 'text_cardviews.card_summary_by_category_weekly';
      case PeriodType.fortnightly:
        return 'text_cardviews.card_summary_by_category_biweekly';
      case PeriodType.monthly:
        return 'text_cardviews.card_summary_by_category_monthly';
      case PeriodType.annually:
        return 'text_cardviews.card_summary_by_category_yearly';
      case PeriodType.byDates:
        return 'text_cardviews.card_summary_by_category_date_range';
    }
  }

  private setModel(movement: MovementModel[]) {
    const categories = Array.from<CategoryModel>(new Set(movement.map(row => row.category!)));
    this.categoryReportModel = [];
    categories.forEach(category => {
      const movements = movement.filter(movement => movement.category == category);
      const model = new ReportCategoryModel(category, movements)
      model.totalAmount = this.getTotalByMovements(model.movements);
      this.categoryReportModel.push(model);
    });
    this.categoryReportModel.sort((a, b) => a.totalAmount - b.totalAmount);
    this.categoryReportModel.forEach(row => row.totalAmount = Math.abs(row.totalAmount));
    this.setDataChart();
    this.noData = (this.categoryReportModel.length == 0);
  }

  private getTotalByMovements(movements: MovementModel[]): number {
    return movements.reduce((sum, row) => sum + (row.sign == '+' ? this.filters.getRateAmount(row.amount, row.account!) : -this.filters.getRateAmount(row.amount, row.account!)), 0)
  }

  private setDataChart() {
    this.customColors = [];
    this.dataChart = [];
    this.totalBalance = 0;
    this.categoryReportModel.forEach(row => {
      this.customColors.push({
        name: row.category.name,
        value: `#${row.category.colorHex}`
      });
      this.dataChart.push({
        name: row.category.name,
        value: row.totalAmount
      });
      this.totalBalance += row.totalAmount;
    });
  }
}
