import { Injectable } from '@angular/core';
import {Api3Service} from '../../../services/api-3.service';
import {Movement} from '../../../interfaces/general/movement.interface';
import {ResponseApi3} from '../../../interfaces/responses-general.interface';

@Injectable({
  providedIn: 'root'
})
export class TransfersService {

  private subscriptionId = this.api3.userData?.subscription_id;
  private userId = this.api3.userData?.user_id;
  private tableName = 'transfer_2';

  constructor(
    private api3: Api3Service,
  ) { }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public requestInsert(movement: Movement, amountTarget: number, toAccount: number): Promise<Movement[]> {
    const params = this.getParams(movement, amountTarget, toAccount, 0);
    return new Promise((resolve, reject) => {
      this.api3.insert(params, this.tableName).subscribe((response: ResponseApi3<Movement[]>) => {
        if (response.status === 1) {
          resolve(response.data);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public requestUpdate(movement: Movement, amountTarget: number, toAccount: number, pkTransfer: number): Promise<Movement[]> {
    const params = this.getParams(movement, amountTarget, toAccount, pkTransfer);
    return new Promise((resolve, reject) => {
      this.api3.update(params, this.tableName).subscribe((response: ResponseApi3<Movement[]>) => {
        if (response.status === 1) {
          resolve(response.data);
        } else {
          reject(response.message);
        }
      });
    });
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getParams(movement: Movement, amountTarget: number, toAccount: number, pkTransfer: number): Object {
    movement.fk_user = this.userId!;
    return {
      data: {
        ...movement,
        fk_subscription: this.subscriptionId!,
        amount_source: movement.amount,
        amount_target: amountTarget,
        fk_to_account: toAccount,
        pk_transfer: pkTransfer
      }
    }
  }
 }
