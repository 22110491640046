import { FrequentStatusType, TypeStatus } from "src/app/interfaces/enums/utils.enums";

export interface KeyValue<T> {
  [key: number]: T;
}

export interface StatusFilter {
  status: TypeStatus;
  title: string;
  iconName: string;
}

export interface FrequentStatus {
  status: FrequentStatusType;
  title: string;
  color: string;
}

export const STATUS_LIST: KeyValue<StatusFilter> = {
  0 : { status: TypeStatus.active, title: 'words.current', iconName: 'menu-goals' },
  1 : { status: TypeStatus.pause, title: 'words.forgiven', iconName: 'icon-pause' },
  2 : { status: TypeStatus.finished, title: 'words.paid', iconName: 'menu-goals' },
  3 : { status: TypeStatus.all, title: 'words.all', iconName: '' },
}

export const FREQUENT_STATUS: KeyValue<FrequentStatus> = {
  0: { status: FrequentStatusType.finished, title: 'frequent_operations_section.finished', color: '0EB594' },
  1: { status: FrequentStatusType.active, title: 'security_section.security_active', color: '0EB594' },
  2: { status: FrequentStatusType.pause, title: 'words.paused', color: '0EB594' },
  3: { status: FrequentStatusType.all, title: 'words.all', color: '0EB594' },
}

export const STATUS_LIST_ARRAY: StatusFilter[] = Object.values(STATUS_LIST);

export const FREQUENT_STATUS_ARRAY: FrequentStatus[] = Object.values(FREQUENT_STATUS);
