import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import moment, { Moment } from 'moment';
import { LocalStorageManager } from 'src/app/utils/local-storage-manager/local-storage-manager.utils';
import { PeriodType } from '../selector-period/interfaces/period.interface';
import { CalendarModel } from '../../../models/calendar.model';
import { FortnightModel } from '../../../models/biweekly.model';
import { Axis, SelectorDateType } from './interfaces/selector-date-type.interface';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-selector-date-by-periods',
  templateUrl: './selector-date-by-periods.component.html',
  styleUrls: ['./selector-date-by-periods.component.css'],
})
export class SelectorDateByPeriodsComponent implements OnInit {

  @Input() currentDate!: moment.Moment;
  @Input() secondCurrentDate?: moment.Moment;
  @Input() selectedPeriod!: PeriodType;
  @Input() typePicker : PickerType = PickerType.YEAR
  @Input() options!: OPTIONS;
  @Input() options2?: OPTIONS;
  @Input() selectorComponet!: SelectorDateType;
  @Input() orientation: Axis = Axis.horizontal;

  @Output() eventEmitter = new EventEmitter<moment.Moment>();
  @Output() eventEmitter2 = new EventEmitter<moment.Moment>();

  calendarOptions = {
    format: 'DD-MMMM-YYYY',
    locale: 'es-MX',
    maxDate: false,
  }

  period = PeriodType;
  calendar!: CalendarModel;
  selectedWeek?: number;
  selectedFortnight!: FortnightModel;

  isDarkmode = LocalStorageManager.isDarkmode;

  selectorDateType = SelectorDateType;

  axis = Axis;

  isSecondDate: boolean = false;
  dateInputValue : string = "2023-09-11";
  pickerType = PickerType


  constructor() {}

  ngOnInit(): void {
    this.calendar = new CalendarModel(moment(this.currentDate.format('yyyy-MM-DD')));
    // this.selectedWeek = this.currentDate.week() - 1;
    this.selectedWeek = this.currentDate.week();
    this.selectedFortnight = this.calendar.getFortnightFromDate(this.currentDate);
    const dateMoment = moment()
    this.dateInputValue = dateMoment.format('yyyy') + " " +dateMoment.format('MMMM')    
  }

  ngOnChanges(): void { }

  increment(isSecondDate: boolean) {
    this.isSecondDate = isSecondDate;
    if (isSecondDate) {
      this.secondCurrentDate = this.dateAction(true, this.secondCurrentDate);
      this.emmitDate2();
    } else {
        this.currentDate = this.dateAction(true, this.currentDate);
        this.emmitDate();
    }
  }

  decrease(isSecondDate: boolean) {
    this.isSecondDate = isSecondDate;
    if (isSecondDate) {
      this.secondCurrentDate = this.dateAction(false, this.secondCurrentDate);
      this.emmitDate2();
    } else {
      this.currentDate = this.dateAction(false, this.currentDate);
      this.emmitDate();
    }
  }

  dateAction(isIncrement: boolean, currentDate?: moment.Moment) {
    let date = moment(currentDate);
    switch(this.selectedPeriod) {
      case PeriodType.daily:
        date = this.actionDaily(date, isIncrement);
        break;
      case PeriodType.weekly:
        date = this.actionWeekly(date, isIncrement);
        break;
      case PeriodType.fortnightly:
        date = this.actionFortnightly(date, isIncrement);
        break;
      case PeriodType.annually:
        date = this.actionAnnually(date, isIncrement);
        break;
      case PeriodType.byDates:
        date = this.actionRange(date, isIncrement);
        break;
      default:
        date = this.actionMonthly(date, isIncrement);
        break;
    }
    return date;
  }
  
  actionDaily(date: moment.Moment, isIncrement: boolean) {
    if (this.selectorComponet == SelectorDateType.home || 
      this.selectorComponet == SelectorDateType.budgets || 
      this.selectorComponet == SelectorDateType.reportsByCategory) {
      if (isIncrement) {
        date = moment(date).add(1, 'days');
      } else {
        date = moment(date).subtract(1, 'days');
      }  
    } else {
      if (isIncrement) {
        date = moment(date).add(1, 'month');
      } else {
        date = moment(date).subtract(1, 'month');
      }  
    }
    return date;
  }

  actionWeekly(date: moment.Moment, isIncrement: boolean) {
    if (isIncrement) { 
      date = moment(date).add(1, 'year');
    } else {
      date = moment(date).subtract(1, 'year');
    }
    return date;
  }

  actionFortnightly(date: moment.Moment, isIncrement: boolean) {
    if (isIncrement) {
      date = moment(date).add(1, 'year');
    } else {
      date = moment(date).subtract(1, 'year');
    }
    return date;
  }

  actionMonthly(date: moment.Moment, isIncrement: boolean) {
    let currentDate;
    if(this.selectorComponet == SelectorDateType.home || 
      this.selectorComponet == SelectorDateType.budgets || 
      this.selectorComponet == SelectorDateType.reportsByCategory ||
      this.selectorComponet == SelectorDateType.movements) {
      if (isIncrement) {
        date = moment(date).add(1, 'month');
      } else {
        date = moment(date).subtract(1, 'month');
      }
      currentDate = date;
    } else {
      if (isIncrement) {
        date = moment(date).add(1, 'year');
      } else {
        date = moment(date).subtract(1, 'year');
      }
      currentDate = date;
    }
    return currentDate;
  }

  actionAnnually(date: moment.Moment, isIncrement: boolean) {
    if (isIncrement) {
      date = moment(date).add(1, 'year');
    } else {
      date = moment(date).subtract(1, 'year');
    }
    return date;
  }

  actionRange(date: moment.Moment, isIncrement: boolean) {
    if (isIncrement) {
      if (!this.isSecondDate) {
          let dateIncrease = moment(date).add(1, 'month');
          if (dateIncrease.isAfter(this.secondCurrentDate) ||  dateIncrease.isSame(this.secondCurrentDate)) {
            return date;
          }
      }
      date = moment(date).add(1, 'month');
    } else {
      date = moment(date).subtract(1, 'month');
    }
    return date;
  }

  changeDate(date : Date) {
    this.currentDate = moment(date)
    this.calendar = new CalendarModel(this.currentDate);
    this.selectedFortnight = this.calendar.fortnightsOfYear[this.selectedFortnight!.fortnightNumber - 1  ?? 0];
    this.emmitDate();
  }

  changeDate2(date: Date) {
    this.secondCurrentDate = moment(date);
    this.emmitDate2();
  }

  setWeek(weekNumber: number, click: boolean) {
    this.selectedWeek = weekNumber;
    if (click) {
      this.emmitDate();
    }
  }

  setFortnight(fortnight: FortnightModel, click: boolean) {
    this.selectedFortnight = fortnight;
    if (click) {
      this.emmitDate();
    }
  }

  incrementSecondComponent(isWeek: boolean) {
    if (isWeek) {
      this.weekIncreaseBudget();
    } else {
      this.fortnightIncreaseBudget();
    }
    this.emmitDate();
  }
  
  decreaseSecondComponent(isWeek: boolean) {
    if (isWeek) {
      this.weekDecreaseBudget();
    } else {
      this.fortnightDecreaseBudget();
    }
    this.emmitDate();
  }

  fortnightDecreaseBudget(){
    let fortnightNumber = this.selectedFortnight.fortnightNumber - 1;
    let sizeFortnight = this.calendar!.fortnightsOfYear.length;
    if (fortnightNumber == 0) {
      this.currentDate.subtract(1, 'year');
      this.changeDate(new Date(this.currentDate.format(this.options.format)));
      this.setFortnight(this.calendar!.fortnightsOfYear[sizeFortnight - 1], false);
      this.currentDate = moment(moment(this.selectedFortnight.completeDate).format('yyyy-MM-DD'));
    } else {
      this.setFortnight(this.calendar!.fortnightsOfYear[fortnightNumber - 1], false);
      this.currentDate = moment(moment(this.selectedFortnight.completeDate).format('yyyy-MM-DD'));
      this.changeDate(new Date(this.currentDate.format(this.options.format)));
    }
  }

  weekDecreaseBudget(){
    if (this.selectedWeek == 0) {
      this.currentDate.week(this.selectedWeek);
      this.currentDate.subtract(1, "weeks");
      this.setWeek(this.calendar!.weeksOfYear.length - 1, false);
      this.currentDate = moment(this.currentDate.format('yyyy-MM-DD'));
    } else {
      this.currentDate.week(this.selectedWeek!);
      if (this.currentDate.week() - 1 == this.selectedWeek) {
        this.currentDate.subtract(1, "weeks");
      } else {
        this.currentDate.subtract(1, "weeks");
        this.changeDate(new Date(this.currentDate.format(this.options.format)));
      }
      this.setWeek(this.currentDate.week(), false);
    }
  }

  fortnightIncreaseBudget(){
    let fortnightNumber = this.calendar!.fortnightsOfYear[this.selectedFortnight?.fortnightNumber - 1  ?? 0].fortnightNumber;
    let sizeFortnight = this.calendar!.fortnightsOfYear.length;
    if (sizeFortnight == fortnightNumber) {
      this.currentDate.add(1, 'year');
      this.changeDate(new Date(this.currentDate.format(this.options.format)));
      this.setFortnight(this.calendar!.fortnightsOfYear[0], false);
      this.currentDate = moment(moment(this.selectedFortnight.completeDate).format('yyyy-MM-DD'));
    } else {
      this.setFortnight(this.calendar!.fortnightsOfYear[fortnightNumber], false);
      this.currentDate = moment(moment(this.selectedFortnight.completeDate).format('yyyy-MM-DD'));
      this.changeDate(new Date(this.currentDate.format(this.options.format)));
    }
  }

  weekIncreaseBudget() {
    this.currentDate.week(this.selectedWeek!);
    this.currentDate.add(1, "weeks");
    if ((this.calendar!.weeksOfYear.length - 1) == this.selectedWeek) {
      this.currentDate = moment(this.currentDate.format('yyyy-MM-DD'));
      this.setWeek(0, false);
    } else {
      this.setWeek(this.currentDate.week(), false);
    }
  }

  emmitDate() {
    if (this.selectorComponet == SelectorDateType.reportsByDate && this.selectedPeriod ==  this.period.weekly ) {
      this.eventEmitter.emit(moment(this.currentDate.format('yyyy')));
    } else {
      this.eventEmitter.emit(this.currentDate);
    }
  }

  emmitDate2() {
    this.eventEmitter2.emit(this.secondCurrentDate);
  }

  getDate() {
    const date = this.currentDate.format(this.options.format).toString()
    return date;
  }

  getDate2() {
    const date = this.secondCurrentDate!.format(this.options.format).toString()
    return date;
  }

  handleDateChange(event: any) { 
    const selectedDate = event.value; // Obtiene la fecha seleccionada
    if (selectedDate) {
      this.dateInputValue = selectedDate.format('yyyy') + " " +selectedDate.format('MMMM');
    }
  }

  getDateInputValue() {
    return this.dateInputValue
  }

  changeDatePickerType(date: Date, datepicker: MatDatepicker<Moment>, type : PickerType) {    
    if (datepicker != null) {
      if (type == PickerType.MONTH && this.typePicker == type) {
        this.changeDate(date);
        datepicker.close();
      } else if (type == PickerType.YEAR && this.typePicker == type) {
        this.changeDate(date);
        datepicker.close();
      } else if (type == PickerType.DATE && this.typePicker == type) {
        this.changeDate(date);
        datepicker.close();
      }
    }
  }

}

export interface OPTIONS {
    format: string,
    locale: string,
    maxDate: boolean,
}

export enum PickerType {
    YEAR = 0,
    MONTH = 1,
    DATE  = 3
}