import {Category} from '../general/category.interface';

export const CATEGORY_TRANSFER: Category = {
  pk_category: 0,
  name: 'Transfers',
  sign: '',
  icon_name: 'icon-two-arrows',
  color_hex: '000000',
  shown: 0,
  deleted: 0,
  shared: 0,
  fk_subscription: 0,
  fk_user: 0,
  server_date: '',
}
