<!-- ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark' : 'light-mode bg-white'}}" 
mat-option
-->
<mat-checkbox
    class=" no-margin"
    [checked]="selectAll"
    [disabled]="selectAll"
    (change)="isChecked()"
    ><p
        class="no-margin m-2"
        style="width: 116% !important;"
        [ngStyle]="{'background-color' : selectAll ? 'black' : !selectAll && ls.isDarkmode ? '#343a40' : 'white'}"
        ngClass="{{selectAll ? 'white-text border-cell' : ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        >
        <img
            class="m-2"
            width="30"
            height="30"
            ngClass="{{selectAll || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
            src="assets/images/icon-cat/icon_all_accounts.svg"
            >{{ "selecteds.all_accounts" | translate}}
    </p>
    
</mat-checkbox>