import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionData } from '../shared/login/interfaces/session.interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public url = "https://backend.dailyexpenses4.com/api_2.0/request.php";
  /* public url = "https://testbackend.dailyexpenses4.com/api_2.0/request.php"; */
  public userData?: SessionData = JSON.parse(sessionStorage.getItem('user_data')!);
  public DbServer = "MAIN";

  private headers = new HttpHeaders({ 'x-token' : this.userData?.token ?? '' });
  requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public sync(params: object): Observable<any> {
    const paramsWithAuth = this.getParamsWithAuthData(params, 'json_sync_web');
    return this.http.post(this.url, paramsWithAuth, this.requestOptions) as Observable<any>;
  }

  public post(typeRequest: string, params: object): Observable<any> {
    const paramsWithAuth = this.getParamsWithAuthData(params, typeRequest);
    return this.http.post(this.url, paramsWithAuth, this.requestOptions) as Observable<any>;
  }

  public create(params: any): Observable<any> {
    const paramsData = this.getParamsWithAuthData(params, "json_insert");
    return this.http.post(this.url, paramsData, this.requestOptions) as Observable<any>;
  }

  public update(params : any): Observable<any> {
    const paramsData = this.getParamsWithAuthData(params, "json_update");
    return this.http.post(this.url, paramsData, this.requestOptions ) as Observable<any>;
  }

  public delete(params: object): Observable<any> {
    const paramsWithAuth = this.getParamsWithAuthData(params, "json_delete");
    return this.http.post(this.url, paramsWithAuth, this.requestOptions) as Observable<any>;
  }

  public upload(params: any): Observable<any> {
    return this.http.post(this.url, params, this.requestOptions) as Observable<any>;
  }

  public getWithOutAuth(params : any, headers?: any) : Observable<any> {
    if (headers) {
      this.requestOptions = headers;
    }
    return this.http.post(this.url, params, this.requestOptions ) as Observable<any>;
  }

  public statusServices(): Observable<any> {
    return this.http.post("https://backend.dailyexpenses4.com/api_2.0/services.php", null, this.requestOptions) as Observable<any>;
  }

  public awsImage(params: object): Observable<any> {
    const paramsData = this.getParamsWithAuthData(params, "json_aws_image");
    return this.http.post(this.url, paramsData, this.requestOptions) as Observable<any>;
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getParamsWithAuthData(params : any, typeRequest: string) {
    return {
      data : params,
      auth_params : {
        user_id : this.userData?.user_id.toString(),
        email : this.userData?.email
      },
      server: this.DbServer,
      type_request : typeRequest
    }
  }
}
