import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Calendar
/*import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
*/

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/layout/home.component';
import { PlansComponent } from './pages/plans/plans.component';
import { OptionsComponent } from './pages/options/options.component';

// Charts
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// DatePicker
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import es from '@angular/common/locales/es';
import fr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';

// Translate
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { CardsModule } from './components/cards/cards.module';
import { SharedModule } from './shared/shared.module';
import { CommonComponentsModule } from './components/common-components/common-components.module';
import { CardCurrencyFormatComponent } from './pages/options/components/cards/card-currency-format/card-currency-format.component';
import { CardDateFormatComponent } from './pages/options/components/cards/card-date-format/card-date-format.component';
import { CardLanguageComponent } from './pages/options/components/cards/card-language/card-language.component';
import { CardTransfersComponent } from './pages/options/components/cards/card-transfers/card-transfers.component';
import { CardThemeComponent } from './pages/options/components/cards/card-theme/card-theme.component';
import { CardContactComponent } from './pages/options/components/cards/card-contact/card-contact.component';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { PipesModule } from './pipes/pipes.module';
import { ModalAddCurrencyComponent } from './pages/options/components/modal-add-currency/modal-add-currency.component';
import { ModalCurrenciesComponent } from './pages/options/components/modal-currencies/modal-currencies.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

/*FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);*/

// AoT requires an exported function for factories para translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(es);
registerLocaleData(fr);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PlansComponent,
    OptionsComponent,
    CardCurrencyFormatComponent,
    CardDateFormatComponent,
    CardLanguageComponent,
    CardTransfersComponent,
    CardThemeComponent,
    CardContactComponent,
    ModalCurrenciesComponent,
    ModalAddCurrencyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CardsModule,
    SharedModule,
    CommonComponentsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    PipesModule
  ],
  exports: [
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en-US' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
