import {Injectable} from '@angular/core';
import {ResponseApi3} from 'src/app/interfaces/responses-general.interface';
import {SyncDebt} from 'src/app/interfaces/sync.interface';
import {DebtModel} from 'src/app/models/general/debt.model';
import {DebtRecordModel} from 'src/app/models/general/debt.record.model';
import {Debt} from 'src/app/interfaces/general/debt.interface';
import {DebtRecord} from 'src/app/interfaces/general/debt.record.interface';
import {AccountModel} from 'src/app/models/general/account.model';
import {Account} from 'src/app/interfaces/general/account.interface';
import {STATUS_LIST} from 'src/app/interfaces/constants/status-list.constant';
import {Api3Service} from '../../../services/api-3.service';
import {CurrencyModel} from '../../../models/general/currency.model';
import {Currency} from '../../../interfaces/general/currency.interface';
import {MultiCurrencyCalculatorService} from '../../../services/multi-currency-calculator.service';

@Injectable({
  providedIn: 'root'
})
export class DebtsService {

  private statusFilter = STATUS_LIST;
  private accountsModel: AccountModel[] = [];
  private currencyModel: CurrencyModel[] = [];
  private debtsModel: DebtModel[] = [];

  private subscriptionId = this.api3.userData?.subscription_id;
  private userId = this.api3.userData?.user_id;
  private tableName = 'table_debts';

  constructor(
    private api3: Api3Service,
    private multiCurrencyServices: MultiCurrencyCalculatorService
  ) {
  }

  //MARK: PUBLIC METHODS ----------------------------------------------------------------
  public getAccounts(): AccountModel[] {
    return [...this.accountsModel];
  }

  public getDebts(): DebtModel[] {
    return [...this.debtsModel];
  }

  public getSync() {
    return new Promise((resolve, reject) => {
      this.api3.sync('debts').subscribe((response: ResponseApi3<SyncDebt>) => {
        if (response.status === 1) {
          this.setCurrencyModel(response.data.table_currencies);
          this.setAccountModel(response.data.table_accounts);
          this.setDebtModel(response.data.table_debts, response.data.table_debt_records);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public createDebt(value: Debt) {
    value.fk_user = this.userId!;
    const params = {data: value};
    return new Promise((resolve, reject) => {
      this.api3.insert(params, this.tableName).subscribe((response: ResponseApi3<Debt[]>) => {
        if (response.status === 1) {
          const model = new DebtModel(response.data[0]);
          this.setRelationsDebt(model);
          this.debtsModel.push(model);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public updateDebt(values: Debt, debt: DebtModel) {
    values.fk_user = this.userId!;
    const params = {data: values};
    return new Promise((resolve, reject) => {
      this.api3.update(params, this.tableName).subscribe((response: ResponseApi3<Debt[]>) => {
        if (response.status == 1) {
          const data = response.data[0];
          debt.updateValues(data);
          debt.debtRecords = [];
          this.setRelationsDebt(debt);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public deleteDebt(debt: Debt) {
    const params = {
      pk_debt: debt.pk_debt,
      fk_subscription: this.subscriptionId,
      fk_user: this.userId
    };
    return new Promise((resolve, reject) => {
      this.api3.delete(params, this.tableName).subscribe((response: ResponseApi3<any>) => {
        if (response.status == 1) {
          this.debtsModel = this.debtsModel.filter(({pkDebt}) => pkDebt != debt.pk_debt);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public addRecordToDebt(model: DebtModel, record?: DebtRecord) {
    if (record) {
      const recordModel = new DebtRecordModel(record);
      recordModel.account = this.accountsModel.find(row => row.pkAccount === recordModel.fkAccount);
      model.debtRecords.push(recordModel);
    }
    model.isMultiCurrency = (new Set(model.debtRecords.map(row => row.fkAccount)).size > 1);
  }

  public calculateDebtBalance(model: DebtModel) {
    this.multiCurrencyServices.isMultiCurrency = model.isMultiCurrency;
    model.amount = model.isMultiCurrency ? this.multiCurrencyServices.getRateAmount(model.amount, model.account!) : model.amountOriginal;
    let total = 0;
    model.debtRecords.forEach(record => {
      if (!record.isEditable) { return }
      record.amount = model.isMultiCurrency ? this.multiCurrencyServices.getRateAmount(record.amount, record.account!) : record.amountOriginal;
      total += (record.sign === '+') ? record.amount : -record.amount;
    });
    model.setPercentage(total);
  }

  public setTotalDebt(model: DebtModel) {
    let total = 0;
    model.debtRecords.map((row) => {
      if (!row.isEditable) { return }
      total += (row.sign === '+') ? row.amount : -row.amount;
    });
    model.setPercentage(total);
  }

  //MARK: PRIVATE METHODS ---------------------------------------------------------------
  private setCurrencyModel(currencies: Currency[]) {
    this.currencyModel = [];
    for (const currency of currencies) {
      this.currencyModel.push(new CurrencyModel(currency));
    }
  }

  private setAccountModel(accounts: Account[]) {
    this.accountsModel = [];
    for (const account of accounts) {
      const model = new AccountModel(account);
      model.currency = this.currencyModel.find((row) => row.pkCurrency === model.fkCurrency) ?? null;
      this.accountsModel.push(model);
    }
  }

  private setDebtModel(debts: Debt[], debtRecords: DebtRecord[]) {
    this.debtsModel = [];
    for (const debt of debts) {
      const model = new DebtModel(debt);
      const records = debtRecords.filter(({fk_debt}) => fk_debt == model.pkDebt)
      model.isMultiCurrency = (new Set(records.map(row => row.fk_account)).size > 1);
      model.debtRecords = this.getRecordsByDebt(records, model.isMultiCurrency);
      this.setRelationsDebt(model);
      this.debtsModel.push(model);
    }
  }

  private setRelationsDebt(model: DebtModel) {
    model.account = this.accountsModel.find((row) => row.pkAccount == model.fkAccount);
    model.statusData = this.statusFilter[model.status];
    this.multiCurrencyServices.isMultiCurrency = model.isMultiCurrency;
    model.amount = model.isMultiCurrency ? this.multiCurrencyServices.getRateAmount(model.amount, model.account!) : model.amountOriginal;
    this.setTotalDebt(model);
  }

  private getRecordsByDebt(records: DebtRecord[], isMultiCurrency: boolean): DebtRecordModel[] {
    this.multiCurrencyServices.isMultiCurrency = isMultiCurrency;
    return records.map(row => {
      const model = new DebtRecordModel(row);
      model.account = this.accountsModel.find(row => row.pkAccount == model.fkAccount);
      model.amount = isMultiCurrency ? this.multiCurrencyServices.getRateAmount(model.amount, model.account!) : model.amountOriginal;
      return model;
    });
  }

}
