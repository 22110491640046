import { Injectable } from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceServicesService {

  constructor(private api: ApiService) { }

  //TODO: DO THIS REQUEST
  public getMaintenance() {
    return new Promise((resolve, reject) => {
      this.api.statusServices().subscribe((response) => {
        if (response.status === 1) {
          reject(response.message);
        } else {
          resolve(true)
        }
      });
    });
  }
}
