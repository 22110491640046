import {Component, OnInit} from '@angular/core';
import {PeriodType} from '../../../common-components/selector-period/interfaces/period.interface';
import {LocalStorageManager} from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {TranslateService} from '@ngx-translate/core';
import {PreferencesModel} from '../../../../models/preferences.model';
import {Router} from '@angular/router';
import {FilterService} from '../../../../services/filter.service';
import {MovementModel} from '../../../../models/general/movement.model';
import {ReportCategoryModel} from '../../../../models/general/report.category.model';
import {CategoryModel} from '../../../../models/general/category.model';

@Component({
  selector: 'app-summary-by-categories',
  templateUrl: './summary-by-categories.component.html',
  styleUrls: ['./summary-by-categories.component.css']
})
export class SummaryByCategoriesComponent implements OnInit {
  isDarkmode = LocalStorageManager.isDarkmode;
  noData = false;
  preferences: PreferencesModel = PreferencesModel.shared;
  selectedPeriodText: string = '';
  categoryReportModel: ReportCategoryModel[] = [];
  dataChart: { name: string, value: number }[] = [];
  customColors: { name: string, value: string }[] = [];
  totalBalance: number = 0;

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.selectedPeriodText = this.getPeriodName();
    this.setModel(this.filters.movementsPeriod);
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  changePage() {
    this.route.navigate(['Reports-by-category/reports']);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getPeriodName(): string {
    switch (this.filters.period) {
      case PeriodType.daily:
        return 'text_cardviews.card_summary_by_category_by_day';
      case PeriodType.weekly:
        return 'text_cardviews.card_summary_by_category_weekly';
      case PeriodType.fortnightly:
        return 'text_cardviews.card_summary_by_category_biweekly';
      case PeriodType.monthly:
        return 'text_cardviews.card_summary_by_category_monthly';
      case PeriodType.annually:
        return 'text_cardviews.card_summary_by_category_yearly';
      case PeriodType.byDates:
        return 'text_cardviews.card_summary_by_category_date_range';
    }
  }

  private setModel(movement: MovementModel[]) {
    const movementsFilter = movement.filter(row => row.category);
    const categories = Array.from<CategoryModel>(new Set(movementsFilter.map(row => row.category!))).filter(row => row.sign == '-');
    this.categoryReportModel = [];
    categories.forEach(category => {
      const movements = movementsFilter.filter(movement => movement.category == category);
      this.categoryReportModel.push(new ReportCategoryModel(category, movements));
    });
    this.categoryReportModel.sort((a, b) => a.totalAmount - b.totalAmount);
    this.categoryReportModel.forEach(row => row.totalAmount = Math.abs(row.totalAmount));
    this.setDataChart();
    this.noData = (this.categoryReportModel.length == 0);
  }

  private setDataChart() {
    this.customColors = [];
    this.dataChart = [];
    this.totalBalance = 0;
    this.categoryReportModel.forEach(row => {
      this.customColors.push({
        name: row.category.name,
        value: `#${row.category.colorHex}`
      });
      this.dataChart.push({
        name: row.category.name,
        value: row.totalAmount
      });
      this.totalBalance += row.totalAmount;
    });
  }
}
