import {Component, Input} from '@angular/core';
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-loading-change-view',
  templateUrl: './loading-change-view.component.html',
  styleUrls: ['./loading-change-view.component.css']
})
export class LoadingChangeViewComponent {
  @Input() loading: boolean = false;
  isDarkMode = LocalStorageManager.isDarkmode;
}
