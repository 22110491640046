import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoryModel } from 'src/app/models/general/category.model';
import { LocalStorageManager } from 'src/app/utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-selector-multi-categories',
  templateUrl: './selector-multi-categories.component.html',
  styleUrls: ['./selector-multi-categories.component.css']
})
export class SelectorMultiCategoriesComponent implements OnInit, OnChanges {
  @Input() categories : CategoryModel[] = [];
  @Input() selectedCategoriesPks : number[] = [];
  @Input() showSelectAll : boolean = false;
  @Input() showShared : boolean = true;
  @Input() limit : number = 0;
  @Output() emitter = new EventEmitter<CategoryModel[]>();
  selectedCategory: CategoryModel[] = [];
  ls = LocalStorageManager;
  selectAll : boolean = false;

  constructor( private translate: TranslateService) {
    this.translate.use(this.ls.lang!);
  }

  ngOnInit(): void {
    this.categories.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    if(this.limit == 0) {
      this.setSelectAll();
    } else {
      this.setSelectList();
    }
  }

  ngOnChanges(): void {
    this.categories.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  //MARK: PUBLIC METHODS ----------------------------------------------------------------
  public check() {
    this.categories.forEach(row => row.selected = (this.selectedCategory.includes(row)));
    this.selectAll = (this.categories.length == this.selectedCategory.length);
    this.emitter.emit(this.selectedCategory);
  }

  public emitSelectAll() {
    this.selectAll = !this.selectAll;
    this.selectedCategory = (this.selectAll) ? this.categories : [];
    this.check();
  }

  //MARK: PRIVATE METHODS ---------------------------------------------------------------
  private setSelectList() {
    this.selectedCategory = this.categories.filter(row => this.selectedCategoriesPks.includes(row.pkCategory));
  }

  private setSelectAll() {
    this.selectedCategory = this.categories;
    this.check();
  }
}
