import { Injectable } from '@angular/core';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { CardControl } from '../interfaces/CardsControl.interface';
import {ApiService} from '../../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  private _preferences: PreferencesModel = PreferencesModel.shared;

  constructor(
    private api: ApiService
  ) { }

  public updateCards(cards: CardControl[]) {
    let params = {
      get : "user_cards",
      cards: this.getCardsParams(cards),
    }
    return new Promise((resolve, _) => {
      this.api.update(params).subscribe(_ => {
        resolve(true);
      });
    });
  }

  public getCardsParams(cards: CardControl[]) {
    let params : any = [];
      cards.forEach(card => {
        let cardParams = {
          pk_user_card : card.id,
          shown : card.hidden ? 0 : 1,
          order_card : card.order,
          fk_preference: this._preferences.pkPreference,
          server_date : ""
        }
        params.push(cardParams);
      });
    return params;
  }

}
