<div
    (click)="changePage()"
    class="card shadow semi-rounded"
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5
            class="card-title"
            [ngClass]="{'title-dark': !isDarkmode, 'title-light': isDarkmode}">
            {{ "title_cardviews.card_balance_budgets" | translate }}
        </h5>
        <ng-container *ngIf="noData">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <img
                            class="img-fluid"
                            src="assets/images/empty_cards/card-budgets.png"
                            >
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!noData">
          <div
            *ngFor="let budget of budgets"
            class="container no-padding mb-2"
            >
            <div class="row mb-1">
              <div class="col-8 p-0">
                <div class="row p-0">
                  <div class="col-2 p-0 text-center ms-3">
                    <img
                      src="assets/images/icon-cat/{{budget.category?.iconName ?? 'icon_all_categories'}}.svg"
                      alt=""
                      style="filter: {{ budget.filter }}"
                      class="w-50">
                  </div>
                  <p class="gray text-truncate col p-0">
                    {{budget?.category?.name ?? ('selecteds.all_categories' | translate)}}
                    <ng-container *ngIf="budget.subcategory">
                      - {{ budget.subcategory.name }}
                    </ng-container>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="progress"
              [ngClass]="{'bg-primary-dark' : isDarkmode }"
              >
              <div
                class="progress-bar"
                role="progressbar"
                style="border-radius: 8px"
                [ngStyle]="{
                  'width' : budget.percentage+'%',
                  'background-color': budget.percentage < 100 ? '#186B5A' : '#6A1C4A'
                  }"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >{{budget.percentage | currency: 'USD':'symbol':'1.2-2'}}%
              </div>
            </div>
            <div class="col-12 text-center">
              <small class="gray">
                {{ budget.total | currencyFormat }}/{{ budget.amount | currencyFormat }}
              </small>
            </div>
          </div>
        </ng-container>
    </div>
</div>
