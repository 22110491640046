import { Component, OnInit } from '@angular/core';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-contact',
  templateUrl: './card-contact.component.html',
  styleUrls: ['./card-contact.component.css'],
})
export class CardContactComponent implements OnInit {
  ls = LocalStorageManager;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {}

  public redirectTo(url: string) {
    window.open(url, '_blank');
  }

  public get mailLink(): string {
    return `mailto:support@encodemx.com`;
  }
}
