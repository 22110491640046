<div
  *ngIf="loading"
  class="container-animation center">
  <div
    class="center container-circles"
    [ngStyle]="{
        'background-color': isDarkMode ? '#212429' : '#FFF'
    }">
    <span class="circle yellow"></span>
    <span class="circle green ms-5"></span>
    <span class="circle blue ms-5"></span>
    <span class="circle pink ms-5"></span>
    <span class="circle purple ms-5"></span>
    <span class="circle yellow end-circle ms-5"></span>
  </div>
</div>
