<div
    class="card rounded card-shadow"
    [ngClass]="{'bg-dark white-text': localStorage.isDarkmode, 'card-shadow': !localStorage.isDarkmode}"
    >
    <div class="card-body">
        <h4
            class="card-title title-secondary"
            [ngClass]="{'white-text': localStorage.isDarkmode}">{{ "words.transfers" | translate }}</h4>
        <div class="container font-secondary" [ngClass]="{'font-secondary-dark': localStorage.isDarkmode}">
            <div class="row">
                <div class="col-12 no-padding">
                    <p class="no-margin text-left">{{ "settions_section.settings_transfers_body" | translate }}</p>
                    <!-- TRANSFERS HOME -->
                    <p class="text-left mt-2 m-0">
                      {{ "main_menu.menu_home" | translate }}
                    </p>
                    <div
                      class="col-12 row btn-container ms-1"
                      [class.dark]="localStorage.isDarkmode">
                      <button
                        class="col-6 btn-section"
                        [class.btn-active]="transfers.TRANSFERS_HOME==1"
                        (click)="setValue('TRANSFERS_HOME', 1)">
                        {{ "category_section.show" | translate }}
                      </button>
                      <button
                        class="col-6 btn-section"
                        [class.btn-active]="transfers.TRANSFERS_HOME==0"
                        (click)="setValue('TRANSFERS_HOME', 0)">
                        {{ "currency_section.currency_hide" | translate }}
                      </button>
                    </div>
                    <!-- TRANSFERS AGENDA -->
                    <p class="text-left mt-2 m-0">
                      {{ "main_menu.menu_agenda" | translate }}
                    </p>
                    <div
                      class="col-12 row btn-container ms-1"
                      [class.dark]="localStorage.isDarkmode">
                      <button
                        class="col-6 btn-section"
                        [class.btn-active]="transfers.TRANSFERS_AGENDA==1"
                        (click)="setValue('TRANSFERS_AGENDA', 1)">
                        {{ "category_section.show" | translate }}
                      </button>
                      <button
                        class="col-6 btn-section"
                        [class.btn-active]="transfers.TRANSFERS_AGENDA==0"
                        (click)="setValue('TRANSFERS_AGENDA', 0)">
                        {{ "currency_section.currency_hide" | translate }}
                      </button>
                    </div>
                    <!-- TRANSFERS REPORTS DATE -->
                    <p class="text-left mt-2 m-0">
                      {{ "main_menu.menu_reports_date" | translate }}
                    </p>
                    <div
                      class="col-12 row btn-container ms-1"
                      [class.dark]="localStorage.isDarkmode">
                      <button
                        class="col-6 btn-section"
                        [class.btn-active]="transfers.TRANSFERS_REPORTS_DATE==1"
                        (click)="setValue('TRANSFERS_REPORTS_DATE', 1)">
                        {{ "category_section.show" | translate }}
                      </button>
                      <button
                        class="col-6 btn-section"
                        [class.btn-active]="transfers.TRANSFERS_REPORTS_DATE==0"
                        (click)="setValue('TRANSFERS_REPORTS_DATE', 0)">
                        {{ "currency_section.currency_hide" | translate }}
                      </button>
                    </div>
                    <!-- TRANSFERS REPORT CATEGORY -->
                    <p class="text-left mt-2 m-0">
                      {{ "main_menu.menu_reports_category" | translate }}
                    </p>
                    <div
                      class="col-12 row btn-container ms-1"
                      [class.dark]="localStorage.isDarkmode">
                      <button
                        class="col-6 btn-section"
                        [class.btn-active]="transfers.TRANSFERS_REPORTS_CATEGORY==1"
                        (click)="setValue('TRANSFERS_REPORTS_CATEGORY', 1)">
                        {{ "category_section.show" | translate }}
                      </button>
                      <button
                        class="col-6 btn-section"
                        [class.btn-active]="transfers.TRANSFERS_REPORTS_CATEGORY==0"
                        (click)="setValue('TRANSFERS_REPORTS_CATEGORY', 0)">
                        {{ "currency_section.currency_hide" | translate }}
                      </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
