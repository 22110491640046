import moment, {Moment} from 'moment';
import {CategoryModel} from './category.model';
import {MovementModel} from './movement.model';

export class TrendsCategoryModel {
  date: Moment;
  categoryData: CategoryMovement[];
  dateView: string = '';
  show: boolean = false;

  constructor(date: Moment, categoryData: CategoryMovement[]) {
    this.date = date;
    this.categoryData = categoryData;
  }

  public setDateView(format: string, dateEnd: Moment | null) {
    if (dateEnd) {
      this.dateView = `${this.date.format(format)} al ${dateEnd.format(format)}`
    } else {
      this.dateView = this.date.format(format);
    }
  }
}

export class CategoryMovement {
  category: CategoryModel;
  movements: MovementModel[];
  totalAmount: number = 0;

  constructor(category: CategoryModel, movements: MovementModel[]) {
    this.category = category;
    this.movements = movements;
  }
}
