<!-- BUTTON SHOW LIST -->
<button
    class="btn btn-secondary w-100 p-0 text-start ps-4 pe-4 btn-style"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    [ngStyle]="{
      'background-color': buttonColor,
      'color': nameSelected ? 'white' : textColor
    }">
  <!-- CURRENT CATEGORY AND SUBCATEGORY -->
  <img
    ngSrc="./assets/images/icon-cat/{{iconName}}.svg"
    alt=""
    [class.filter-white]="nameSelected || isDarkMode"
    class="me-4"
    width="40"
    height="40">
  <i
    *ngIf="currentCategory?.isShared"
    class="fa fa-user-friends align-self-center col-1 pe-0 gray"
    aria-hidden="true"
  ></i>
  {{ nameSelected ?? ('web_messages.select_category' | translate) }}
</button>
<!-- DROPDOWN LIST -->
<div
    class="dropdown-menu size-menu"
    [ngStyle]="{
      'background-color': menuColor,
      'width': sizeWidth
    }">
  <!-- LIST CATEGORIES -->
  <ng-container *ngFor="let category of categories">
    <div
      class="item-list ms-1 me-1 pointer relative"
      (click)="setCategory(category)"
      [ngStyle]="{'background-color': '#'+category.colorHex}">
      <img
        ngSrc="./assets/images/icon-cat/{{category.iconName}}.svg"
        alt=""
        class="me-4 filter-white"
        width="40"
        height="40">
      <i
        *ngIf="category.isShared"
        class="fa fa-user-friends align-self-center col-1 pe-0 gray"
        aria-hidden="true"
      ></i>
      {{ category.name }}
      <button
          *ngIf="category.subcategories.length > 0 && showSubcategory"
          class="btn-end btn-white"
          [style.color]="textColor"
          (click)="showListSubcategory(category); $event.stopPropagation()">
        <img
            src="./assets/images/down-icon.svg"
            class="filter-white"
            [ngClass]="{
              'rotate': category.expandedList,
              'no-rotate': !category.expandedList,
            }"
            alt=""
            height="25px"
            width="25px">
      </button>
    </div>
    <!-- LIST SUBCATEGORIES -->
    <ng-container *ngIf="category.expandedList && showSubcategory">
      <div
        *ngFor="let subcategory of category.subcategories"
        class="item-list ps-5 pointer"
        [style.color]="textColor"
        (click)="selectSubcategory(category, subcategory)">
        <div
          class="size-container-img me-4"
          [style.background-color]="'#'+category.colorHex">
          <img
            src="./assets/images/icon-cat/{{subcategory.iconName}}.svg"
            class="filter-white"
            alt=""
            height="30x"
            width="30px">
        </div>
        {{ subcategory.name }}
      </div>
    </ng-container>
  </ng-container>
</div>
